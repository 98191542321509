import {
  AspectRatio,
  Box,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { data } from '../../Components/Sidebar/DataMenu';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/zustand/Store';
import { getSingleDocumentFirebase } from '../../Apis/firebaseApi';
  
const ConfigurationIndex = () => {
  const navigate = useNavigate();
  
  const [dataConfig, setDataConfig] = useState({})
  
  const [videoLoaded, setVideoLoaded] = useState(false)
  
  const globalState = useUserStore();
  
  
  const handleNavigate = (value) => {
    if (!value.submenu || value?.submenu?.length === 0) {
      navigate(`${value.link}`);
    }
  };
  
  const getDataManagement = async () => {
    try {
      const result = await getSingleDocumentFirebase('config', 'videoFront')
  
      const dataFind = result?.data.find((x) => x.title === 'scoreboard')
      setDataConfig(dataFind)
  
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } 
  }
  
  useEffect(() => {
    getDataManagement()
  
    return () => {
      setDataConfig({})
  
    }
  }, [])
  
  
  return (
    <Box p={[1, 1, 5]}>
      <Box bg={'white'} my={7} p={4} shadow={'md'}>
        <Text color={'gray.500'} fontWeight={500} mb={5}>
                      Company Menu
        </Text>
        <SimpleGrid columns={[1, 1, 4]} spacing={5}>
          {data
            .find((menu) => menu.name === 'Configuration')
            ?.submenu?.map((x, i) => (
              <Stack key={i}>
                <Popover
                  placement="bottom"
                  isLazy
                  closeOnBlur={true}
                  arrowSize={0}
                >
                  <PopoverTrigger>
                    <Stack
                      p={3}
                      key={i}
                      border={'1px'}
                      shadow={'base'}
                      align={'center'}
                      cursor={'pointer'}
                      borderRadius={'md'}
                      borderColor={'gray.300'}
                      onClick={() => handleNavigate(x)}
                      _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
                    >
                      <Icon as={x.icon} boxSize={12} />
                      <Text fontWeight={500}>{x.name}</Text>
                    </Stack>
                  </PopoverTrigger>
                  {!x.submenu ? null : (
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverBody>
                          <SimpleGrid
                            columns={x.submenu?.length > 4 ? 2 : 1}
                            spacing={3}
                          >
                            {x.submenu?.map((subitem, i) => (
                              <Box
                                p={3}
                                shadow={'base'}
                                borderRadius={'md'}
                                cursor={'pointer'}
                                onClick={() => navigate(`${subitem.link}`)}
                                key={i}
                                _hover={{
                                  transform: 'scale(1.03)',
                                  transition: '0.3s',
                                }}
                              >
                                <Stack spacing="3" align={'center'}>
                                  <Icon as={subitem.icon} boxSize={6} />
                                  <Text
                                    pl={3}
                                    fontWeight={'500'}
                                    fontSize="sm"
                                    noOfLines={1}
                                  >
                                    {subitem.name}
                                  </Text>
                                </Stack>
                              </Box>
                            ))}
                          </SimpleGrid>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  )}
                </Popover>
              </Stack>
            ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
  
export default ConfigurationIndex;
  