/* eslint-disable no-unsafe-optional-chaining */
import { Box, Grid, HStack, Heading, Icon, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import { MdCancel } from 'react-icons/md'
import { FaTag } from 'react-icons/fa'
import { useLocation } from 'react-router-dom';
// import useUserStore from '../../Hooks/Zustand/Store';
// import AlertDuplicateDialog from '../../Components/AlertDialog/AlertDuplicateDialog';
import { addDocumentFirebase } from '../../../Apis/firebaseApi';
import useUserStore from '../../../Hooks/zustand/Store';
import AlertDuplicateDialog from '../../../Components/AlertDialog/AlertDuplicateDialog';

function SopTemplateViewPage() {

  const location = useLocation()
  const [data, setData] = useState(location.state || {})
  const [loading, setLoading] = useState(false)

  const toast = useToast()
  const globalState = useUserStore();

  const [alertDuplicate, setAlertDuplicate] = useState(false)

  const handleOpenAlert = () => {
    setAlertDuplicate(true)
  }

  const handleCloseAlert = () => {
    setAlertDuplicate(false)  
  }

  const handleDuplicate = async () => {
    setLoading(true)

    const updateData = data
    updateData.companyId = globalState.currentCompany
    updateData.projectId = globalState.currentProject
    delete updateData.embedding

    try {
      await addDocumentFirebase(
        'sop',
        updateData,
        globalState?.currentCompany
      );
      toast({
        status: 'success',
        duration: 2000,
        title: 'Success duplicate this template to your company',
        isClosable: true,
      });
    } catch (error) {
      toast({
        status: 'error',
        duration: 2000,
        title: error.message,
        isClosable: true,
      });
    }finally{
      setLoading(false)
      handleCloseAlert()
    }
  }

  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack>
        <Stack>
          <BackButtons />
        </Stack>
        <Stack>
          <Heading size={'md'}>Sop View</Heading>
        </Stack>
      </HStack>

      <Stack spacing={1}>

        <Heading
          as="h1"
          size="xl"
          p={1}
          contentEditable
          placeholder="Masukkan judul dokumen"
          onBlur={(e) => setData({ ...data, title: e.target.textContent })}
        >
          {data?.title || 'Untitled'}
        </Heading>
        <Text
          p={1}
          contentEditable
          placeholder="Masukkan deskripsi dokumen"
          onBlur={(e) => setData({ ...data, description: e.target.textContent })}
        >
          {data?.description ? data.description : 'Page Description (Optional)'}
        </Text>

        <Grid templateColumns={{ base: '1fr', md: '3fr 1fr' }} gap={3}>
          <Box width='full' m='2'>
            <div
              dangerouslySetInnerHTML={{ __html: data?.dataHtml || data?.data }}
              style={{ fontSize: '13px', width: '100%', overflow: 'hidden' }}
            />
          </Box>

          <Stack w='full' m='2' shadow={'md'} p='3' borderRadius='md' alignSelf='flex-start'>

            <Stack>
              <Text fontWeight="bold">Tags</Text>
              <Box
                shadow="base"
                // bg="white"
                padding={3}
                border={'0.5px'}
                borderColor={'gray.300'}
                borderRadius={'sm'}
              >
                <HStack
                  spacing={2}
                  p={2}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  w="100%"
                >
                  <HStack>
                    <Stack
                      borderRadius={'sm'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                    >
                    </Stack>
                    {data?.tags?.length > 0 ? (

                      <HStack w={'200px'} overflowX={'scroll'} p="2" gap={3}>

                        {data?.tags?.map((x, i) => (
                          <Stack
                            key={i}
                            p={2}
                            border={'1px'}
                            borderRadius={'sm'}
                            shadow={'base'}
                            pos={'relative'}
                            borderColor={'gray.300'}
                            alignItems="center"
                            minW={'80px'}
                          >
                            <Box
                              cursor={'pointer'}
                              size="xs"
                              borderRadius={'full'}
                              color={'blue.600'}
                              pos={'absolute'}
                              bottom={-2}
                              right={-1}
                            >
                              <FaTag />
                            </Box>
                            <HStack >
                              <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} >
                                {x}
                              </Text>
                              <Icon
                                cursor="pointer"
                                pos={'absolute'}
                                color={'red.300'}
                                top={-1}
                                right={-1}
                                as={MdCancel}
                                boxSize={4}
                              />
                            </HStack>
                          </Stack>
                        ))}
                      </HStack>
                    ) : (
                      <Text fontSize={'sm'}>No Tags data</Text>
                    )}
                  </HStack>
                </HStack>

              </Box>
              <DynamicButton action={'create'} variant={'solid'} title={'Use this template'} onClick={handleOpenAlert}/>

            </Stack>




          </Stack>
        </Grid>
      </Stack>

      <AlertDuplicateDialog
        isLoading={loading}
        isOpen={alertDuplicate}
        onClose={handleCloseAlert}
        title={'Do you want to duplicate this file to your company'}
        onAction={handleDuplicate}
      />



    </Stack>
  )
}

export default SopTemplateViewPage
