import {
  Box,
  HStack,
  Heading,
  Image,
  Select,
  Input,
  SimpleGrid,
  Spacer,
  useToast,
  Text,
  Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import { useEffect } from 'react';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function LandingPagePricingV2() {
  const [data, setData] = useState();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const category = ['consultation', 'system', 'agency'];
  const subCategory = ['management', 'marketing', 'crm', 'social media', 'hrd'];

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('landing_page', 'pricingV2');
      setData(res.pricing);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleEdit = (index, key, value) => {
    const newArr = data;
    console.log(index, key, value);
    newArr[index][key] = value;
    setData(newArr);
    setIsLoading(!isLoading);
  };

  const handleEditPricing = (index, pIndex, key, value) => {
    const newArr = data;
    newArr[index].pricing[pIndex][key] = value;
    setIsLoading(!isLoading);
    setData(newArr);
  };

  const handleAddPricing = (index) => {
    const newArr = data;
    newArr[index].pricing.push({
      period: '1 month',
      price: '2000000',
      feature: ['feature'],
    });
    console.log(newArr);
    setIsLoading(!isLoading);
    setData(newArr);
  };

  const handleDeletePricing = (index, pIndex) => {
    const newArr = data[index];
    const secArr = newArr.pricing;
    secArr.splice(pIndex, 1); // 2nd parameter means remove one item only
    const updateArr = data;
    updateArr[index].pricing = secArr;
    setIsLoading(!isLoading);
    setData(updateArr);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const dataSend = {
        pricing: data,
      };
      await setDocumentFirebase('landing_page', 'pricingV2', dataSend);
      setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [isLoading]);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Integration</Heading>
        <Spacer />
      </HStack>

      <SimpleGrid columns={3} gap="3">
        {data?.map((item, index) => (
          <Box key={index} p={5} shadow="md" borderWidth="1px">
            <Input
              defaultValue={item.title}
              onChange={(e) => handleEdit(index, 'title', e.target.value)}
            />
            <Select
              defaultValue={item.category}
              onChange={(e) => handleEdit(index, 'category', e.target.value)}
            >
              {category.map((item, index) => (
                <option key={index + 1000}>{item}</option>
              ))}
            </Select>

            {item.category === 'system' && (
              <Select
                defaultValue={item.category}
                onChange={(e) =>
                  handleEdit(index, 'subCategory', e.target.value)
                }
              >
                {subCategory.map((item, index) => (
                  <option key={index + 100000}>{item}</option>
                ))}
              </Select>
            )}

            <Input
              placeholder="feature1,feature2,feature3"
              defaultValue={item?.feature?.toString()}
              onChange={(e) =>
                handleEdit(index, 'feature', e.target.value.split(','))
              }
            />

            <HStack>
              <Text>Pricing</Text>
              <Spacer />
              <Button
                onClick={() => handleAddPricing(index)}
                colorScheme="green"
              >
                +
              </Button>
            </HStack>
            {item?.pricing?.map((pricing, i) => (
              <Box key={index + i + 100} my={1}>
                <Input
                  placeholder="period"
                  defaultValue={pricing.period}
                  onChange={(e) =>
                    handleEditPricing(index, i, 'period', e.target.value)
                  }
                />
                <Input
                  placeholder="pricing IDR"
                  defaultValue={pricing.price}
                  type="number"
                  onChange={(e) =>
                    handleEditPricing(index, i, 'price', e.target.value)
                  }
                />
                <Input
                  placeholder="Additional Feature"
                  defaultValue={pricing?.feature?.toString()}
                  onChange={(e) =>
                    handleEditPricing(
                      index,
                      i,
                      'feature',
                      e.target.value.split(',')
                    )
                  }
                />
                <Button
                  colorScheme="red"
                  onClick={() => handleDeletePricing(index, i)}
                >
                  Delete
                </Button>
              </Box>
            ))}
          </Box>
        ))}
      </SimpleGrid>
      {/* <DynamicButton
            color="green"
            // isLoading={isLoading}
            size="sm"
            action="custom"
            title="CONSOLE"
            onClick={()=>console.log(data)}
          /> */}

      <DynamicButton
        color="green"
        // isLoading={isLoading}
        size="sm"
        action="custom"
        title="Add New Section"
        onClick={() => setData([...data, { pricing: [] }])}
      />

      <DynamicButton
        color="green"
        // isLoading={isLoading}
        size="sm"
        action="custom"
        title="Submit"
        onClick={() => handleSave()}
      />
    </Box>
  );
}

export default LandingPagePricingV2;
