/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Stack,
  Image,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { IoCloseSharp } from 'react-icons/io5';
import ImageComponent from '../../Components/Image/ImageComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  addDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';
import { uploadImage } from '../../Apis/firebaseFunctions';

const TemplateCreate = ({ isOpen, onClose, editData, resSave }) => {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const dataOptions = ['Tech', 'Beauty', 'Health', 'Business'];
  const [formData, setFormData] = useState({
    html: '',
    image: '',
    title: '',
    category: 'Tech',
    type: 'landingPage',
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        html: editData.html || '',
        image: editData.image || '',
        title: editData.title || '',
        category: editData.category || 'Tech',
        type: editData.type || 'landingPage',
      });
    } else {
      setFormData({
        html: '',
        image: '',
        title: '',
        category: 'Tech',
        type: 'landingPage',
      });
    }
  }, [editData]);

  const submitImage = async (file) => {
    try {
      const res = (await uploadImage(file[0])).data;

      if (res.status) {
        setFormData((prevData) => ({ ...prevData, image: res.data }));
        toast({
          title: 'Upload Image',
          description: 'Image saved',
          status: 'success',
          duration: 2000,
        });
      }
    } catch (error) {
      toast({
        title: 'Image Size Error',
        description: error.message,
        status: 'error',
      });
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({ ...prevData, image: '' }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async () => {
    try {
      if (
        formData.title === '' ||
        formData.image === '' ||
        formData.category === '' ||
        formData.html === ''
      ) {
        return toast({
          title: 'Input Validation',
          description: 'Please fill in all fields',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      setIsLoading(true);
      globalState.setIsLoading(true);

      if (editData) {
        await updateDocumentFirebase('templates', editData.id, formData);
      } else {
        await addDocumentFirebase(
          'templates',
          formData,
          globalState.currentCompany
        );
      }
      globalState.setIsLoading(false);

      setIsLoading(false);
      setFormData({
        html: '',
        image: '',
        name: '',
        category: 'Tech',
        type: 'landingPage',
      });
      resSave();
      onClose();
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Template Landing Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w={'100%'} mb={3} gap={5} direction={['column', 'row', 'row']}>
            {/* Box for image upload */}
            <Box
              w={['100%', '50%', '50%']}
              p={5}
              borderRadius="md"
              shadow={'base'}
            >
              <Stack spacing={3} align={'center'}>
                <ImageComponent
                  image={formData.image}
                  name={formData.name}
                  width="200px"
                />

                {formData.image ? (
                  <Button
                    size={'xs'}
                    colorScheme="red"
                    onClick={handleDeleteImage}
                  >
                    Clear Image
                  </Button>
                ) : (
                  <Box>
                    <Input
                      type="file"
                      mb={4}
                      onChange={(e) => submitImage(e.target.files)}
                    />
                  </Box>
                )}
              </Stack>
            </Box>

            <Box p={5} w={['100%', '50%', '50%']} shadow={'base'}>
              <FormControl mb="4">
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Section</FormLabel>
                <Select
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                  required
                >
                  {dataOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Flex>

          <FormControl mt="5">
            <FormLabel ml={2}>HTML</FormLabel>
            <Textarea
              height={400}
              name="html"
              value={formData.html}
              onChange={handleChange}
              required
            />
          </FormControl>

          {/* Buttons for cancel and submit */}
          <HStack
            mt="10"
            mb="5"
            display="flex"
            justifyContent="end"
            spacing={2}
          >
            <DynamicButton
              size={'sm'}
              action="custom"
              color={'red'}
              icon={IoCloseSharp}
              title={'Cancel'}
              onClick={onClose}
            />
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={onSubmit}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TemplateCreate;
