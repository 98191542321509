/* eslint-disable react/prop-types */
import { Badge, Box, Card, CardBody, HStack, Heading, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { getUserByUid } from '../../../Utils/getUser';
import useUserStore from '../../../Hooks/zustand/Store';
import { getCollectionFirebase } from '../../../Apis/firebaseApi';
import { db } from '../../../Config/firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { formatFrice } from '../../../Utils/Helper';
import moment from 'moment';
import CompanyModalBilling from './CompanyModalBilling';
import ModalBillingDetail from './ModalBillingDetail';
import CompanyModalPayment from './CompanyModalPayment';

function CompanyBilling({ companyId, ownersCompany, detailCompany, projectCompany }) {

  const globalState = useUserStore()

  const itemsPerPage = 10

  const [dataBilling, setDataBilling] = useState([])
  const [billingCount, setBillingCount] = useState(0)

  const [modalBilling, setModalBilling] = useState(false)
  const [modalBillingDetail, setModalBillingDetail] = useState(false)
  const [dataResult, setDataResult] = useState(null)
  const [modalPayment, setModalPayment] = useState(false)



  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterData, setFilterData] = useState('')


  const toast = useToast()


  const getData = async () => {

    const startIndex = (currentPage - 1) * itemsPerPage;

    let conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: companyId,
      }
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    globalState.setIsLoading(true);

    if (filterData === 'settlement') {
      conditions = [
        { field: 'transaction_status', operator: '==', value: 'settlement' },
        {
          field: 'companyId',
          operator: '==',
          value: companyId,
        },
      ];
    }

    try {
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase(
        'billings',
        conditions,
        sortBy,
        limitValue
      );
      setDataBilling(res);


      const collectionRef = collection(db, 'billings');

      // Membuat kondisi filter tambahan untuk pemilik (owner) jika filterOwner aktif
      let countQuery = query(
        collectionRef,
        where('companyId', '==', companyId),
      );
      if (filterData === 'settlement') {
        countQuery = query(
          collectionRef,
          where('companyId', '==', companyId),
          where('transaction_status', '==', 'settlement')
        );
      }

      const snapshot = await getCountFromServer(countQuery);
      const assessmentLength = snapshot.data().count;
      setBillingCount(assessmentLength)


      setTotalPages(Math.ceil(assessmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  useEffect(() => {
    getData()

    return () => {
    }
  }, [])

  const handleOpenModal = () => {
    setModalBilling(true)
  }

  const handleCloseModal = () => {
    setModalBilling(false)
  }

  const handleOpenModalDetail = () => {
    setModalBillingDetail(true)
  }

  const handleCloseModalDetail = () => {
    setModalBillingDetail(false)
  }

  const handleDetail = (item) => {
    setDataResult(item)
    handleOpenModalDetail()
  }

  const handleAction = (item) => {
    setDataResult(item)
    handleOpenModalAction()
  }

  const handleOpenModalAction = () => {
    setModalPayment(true)
  }

  const handleCloseModalAction = () => {
    setModalPayment(false)
    setDataResult('')
  }

  return (
    <Card borderRadius={'md'}>
      <CardBody>
        <HStack mb={3}>
          <Box>
            <Heading
              as='h2' size='md'
            >
              Billing
            </Heading>
            <Badge colorScheme='yellow'>
              Total billing {billingCount || 0}
            </Badge>
          </Box>
          <Spacer />
          <DynamicButton
            size={'sm'}
            action="create"
            title={'Add Billings'}
            variant={'solid'}
            onClick={() => handleOpenModal()}
          />
        </HStack>
        <TableContainer
          mt={5}
          boxShadow={'md'}
          maxHeight="400px"
          overflowY="auto"
        >
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Billing Name</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>User</Th>
                <Th>Bank</Th>
                <Th>Method</Th>
                <Th>Amount</Th>
                <Th>Last Activity</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataBilling?.map((x, i) => (
                <Tr key={i} fontSize={'sm'} fontWeight={500}>
                  <Td>
                    <Stack spacing={0}>
                      <Text>{x?.name}</Text>
                      <Text fontSize="3xs">ID: {x?.order_id}</Text>
                      <Text fontSize="3xs">App ID: {x?.appId}</Text>
                    </Stack>
                  </Td>
                  <Td>
                    {x?.type}
                  </Td>
                  <Td>
                    {x?.transaction_status}
                  </Td>
                  <Td>
                    {x?.contact_information?.name}
                  </Td>
                  <Td>
                    {x?.bank}
                  </Td>
                  <Td>
                    {x?.payment_type}
                  </Td>
                  <Td>
                    {x?.gross_amount
                      ? `${x?.currency}${formatFrice(Number(x?.gross_amount || 0))}`
                      : `${x?.currency || 0}`}
                  </Td>
                  <Td>
                    {moment(x?.createdAt?.seconds * 1000).format('DD-MM-YYYY hh:mm')}
                  </Td>
                  <Td>
                    <HStack>
                      <DynamicButton action={'read'} variant={'solid'} size='sm' onClick={() => handleDetail(x)} />
                      <DynamicButton action={'create'} variant={'solid'} size='sm' onClick={() => handleAction(x)}/>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>

      <CompanyModalBilling isOpen={modalBilling} onClose={handleCloseModal} companyId={companyId} ownersCompany={ownersCompany} detailCompany={detailCompany} projectCompany={projectCompany} />

      <ModalBillingDetail
        isOpen={modalBillingDetail}
        onClose={handleCloseModalDetail}
        dataResult={dataResult}
      />

      <CompanyModalPayment
        isOpen={modalPayment}
        onClose={handleCloseModalAction}
        dataResult={dataResult}
        setDataResult={setDataResult}
      />
    </Card>
  )
}

export default CompanyBilling