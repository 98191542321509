import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Switch,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/zustand/Store';
import { clientTypesense } from '../../Apis/typeSense';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { MediaCard } from './MediaCard';
import SearchBar from './SearchBar';
import UploadDropboxCard from '../../Components/DropBox/UploadDropboxCard';
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import { decryptToken } from '../../Utils/encrypToken';

function ContentPage() {
  const [data, setData] = useState();
  const [tokenDropBox, setTokenDropBox] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [shareLink, setShareLink] = useState('');
  const itemsPerPage = 30;
  const toast = useToast();
  const [startIndex, setStartIndex] = useState(0);
  const [inputSearch, setInputSearch] = useState('');
  const [sortingBy, setSortingBy] = useState('createdAt');
  const globalState = useUserStore();
  const [modalPublish, setModalPublish] = useState(false);
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [formData, setFormData] = useState({
    description: '',
    video: '',
  });

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      setTokenDropBox(resultData);
    } catch (error) {
      return error;
    }
  };

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img width="100px" src="${link}" alt="Image" /></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setHtmlContent(htmlContent);
    }
  };

  const onSubmitModal = async () => {
    if (!shareLink || !shareLink.link) {
      return toast({
        title: 'Admin',
        description: 'Template is required',
        status: 'error',
      });
    }

    if (formData.description === '') {
      return toast({
        title: 'Admin',
        description: 'Please Complete Form',
        status: 'error',
      });
    }

    const dataSave = {
      description: formData.description,
      video: shareLink.link,
    };
    await addDocumentFirebase(
      'content_strategies',
      dataSave,
      globalState.currentCompany
    );

    getDataFyp();
    setFormData({
      description: '',
      video: '',
    });
    setIsOpen(false);
    setShareLink('');
    setHtmlContent('');
  };

  function openModal() {
    setModalOpen(true);
  }

  const handleSwitchChange = () => {
    setFormData({ ...formData, publish: !formData.publish });
  };
  //   console.log(globalState, 'ini global state');

  const usernameUrl = (link) => {
    const regex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@([^/]+)/i;
    const match = link.match(regex);

    if (match && match[3]) {
      return 'https://www.tiktok.com/@${match[3]}';
    } else {
      return null;
    }
  };

  function closeModal() {
    setModalOpen(false);
  }

  const sortingBySearch = (e) => {
    setSortingBy(e.target.value);
  };

  const getDataFyp = async () => {
    globalState.setIsLoading(true);
    try {
      const conditions = [];
      const sortBy = { field: sortingBy, direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      let res = [];

      res = await getCollectionFirebase(
        'content_strategies',
        conditions,
        sortBy,
        limitValue,
        startIndex
      );

      setData(res);
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      throw new Error(error, 'An error occured');
    }
  };

  const handleTypesenseSearch = (q) => {
    try {
      const searchParameters = {
        q: q,
        query_by: 'description',
        filter_by: '',
        per_page: 30,
      };

      if (q) {
        globalState.setIsLoading(true);
        clientTypesense
          .collections('content_strategies')
          .documents()
          .search(searchParameters)
          .then((x) => {
            console.log(x, 'semua data ');
            const newData = x.hits.map((y) => {
              return { ...y.document };
            });
            setData(newData);
          })
          .catch((error) => {
            console.log(error, 'errorrr');
          });
      } else {
        setInputSearch(q);
        getDataFyp();
      }
    } catch (error) {
      console.log(error.message);
    }
    globalState.setIsLoading(false);
  };

  const handleOpenLink = (value) => {
    window.open(value?.shareUrl, '_blank');
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
    // setModalPublish(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setShareLink('');
    setHtmlContent('');
    setFiles([]);
  };

  const handlePublish = async () => {
    await addDocumentFirebase(
      'content_strategies',
      formData,
      globalState.currentCompany
    );
    console.log('masuk', formData, files);
  };
  //   console.log(inputSearch, 'ini input search');

  useEffect(() => {
    getAccessToken();
    if (inputSearch === '') {
      getDataFyp();
    }
  }, [startIndex, inputSearch, sortingBy]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading ml={3}>Content Strategis</Heading>
          <Spacer />
          {/* {isCorrectCompany && ( */}
          <DynamicButton
            variant={'solid'}
            action={'create'}
            title="Post Video"
            size={'sm'}
            onClick={handleOpenModal}
          />
          {/* )} */}

          <SearchBar
            data={data}
            handleSearch={handleTypesenseSearch}
            sortBySearch={sortingBySearch}
            sortBy={sortingBy}
          />
        </HStack>
        {data ? (
          <MediaCard
            dataHasil={data}
            handleOpenLink={handleOpenLink}
            handleLoadMore={handleLoadMore}
            usernameUrl={usernameUrl}
          />
        ) : (
          <Text>Loading...</Text>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Content</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w={'100%'} gap={5} direction={['column', 'row', 'row']}>
              <Box
                w={['100%', '50%', '50%']}
                p={5}
                borderRadius="md"
                shadow={'base'}
              >
                <FormControl mb="4">
                  <FormLabel>Content</FormLabel>
                  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>FIle Link</FormLabel>
                  <Text>
                    <a
                      href={shareLink?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text>{shareLink?.link}</Text>
                    </a>
                  </Text>
                </FormControl>

                <Button
                  onClick={openModal}
                  colorScheme={'green'}
                  variant="outline"
                >
                  Upload
                </Button>

                {shareLink && (
                  <Button
                    onClick={() => {
                      setShareLink('');
                      setHtmlContent('');
                    }}
                    colorScheme={'red'}
                    ml={3}
                    variant="outline"
                  >
                    Delete
                  </Button>
                )}
              </Box>

              <Box p={5} w={['100%', '50%', '50%']} shadow={'base'}>
                <Stack>
                  <Text fontSize={'sm'} color="gray.500">
                    Type Your Description Video
                  </Text>
                  <Textarea
                    placeholder="Type Here ..."
                    height={'100%'}
                    fontSize={'sm'}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                  />
                </Stack>
              </Box>
            </Flex>

            <HStack
              mt="10"
              mb="5"
              display="flex"
              justifyContent="end"
              spacing={2}
            >
              <DynamicButton
                size={'md'}
                action="custom"
                color="red"
                title={'Cancel'}
                onClick={handleCloseModal}
              />
              <DynamicButton
                variant={'solid'}
                size={'md'}
                action="create"
                title={'Submit'}
                onClick={() => onSubmitModal()}
              />
            </HStack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${globalState?.currentCompany}/${globalState?.currentProject}/template-marketer`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
        accessTokenDb={tokenDropBox}
        rasio={formData.rasio}
      />
    </>
  );
}

export default ContentPage;
