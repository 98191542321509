/* eslint-disable no-undef */
const Typesense = require('typesense',);
const API = process.env.REACT_APP_TYPESENSE_API_KEY;

export const clientTypesense = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      port: '443', // For Typesense Cloud use 443
      protocol: 'https', // For Typesense Cloud use https
    },
  ],
  apiKey: API,
  connectionTimeoutSeconds: 15,
},);


export const appsAvailable = async(q) => {
  try {
    const searchParameters = {
      q: q ? q : '*',
      query_by: 'title',
      limit: 250,
    };

    return clientTypesense
      .collections('apps')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => { return { ...y.document } })
        return newData
      });

  } catch (error) {
    throw new Error(error.message)
  }
}

