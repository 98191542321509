import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../Pages/Authentication/LoginPage';
import PaymentLpPage from '../Pages/Payment/PaymentLpPage';


function AuthRouter() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/payment-lp" element={<PaymentLpPage />} />
    </Routes>  
  );
}

export default AuthRouter;
