/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import { Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import useUserStore from '../../../Hooks/zustand/Store';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import CompanyPaymentApi from './CompanyPaymentApi';
import { useParams } from 'react-router-dom';

function CompanyModalBilling({ isOpen, onClose, ownersCompany, companyId, detailCompany, projectCompany }) {
  const globalState = useUserStore();
  const params = useParams()

  const [formData, setFormData] = useState({
    companyId: companyId || params.id,
    apps: null,
    owners: null,
    project: null,
    grossAmount: null,
    duration: null,
    affiliateId: null
  });

  const [modalBilling, setModalBilling] = useState(false)

  const handleSelectChange = (e) => {
    const dataFind = globalState.apps.find((x) => x.id === e.target.value);
    setFormData({ ...formData, apps: dataFind });
  };

  const handleSelectOwner = (e) => {
    const ownerFind = ownersCompany.find((x) => x.id === e.target.value);
    setFormData({ ...formData, owners: ownerFind });
  };

  const handleSelectProject = (e) => {
    const projectFind = projectCompany.find((x) => x.id === e.target.value);
    setFormData({ ...formData, project: projectFind });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreate = () => {
    setModalBilling(true)
  }

  const handleCloseModal = () => {
    setModalBilling(false)
  }

  return (
    <>
      <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent transform="translateY(-50%)" top="5%" shadow="xl">
          <DrawerHeader>
            <Center>New Billing</Center>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody maxH="70vh" overflowY="scroll">
            <FormLabel mt={3} mb={2}>Select App</FormLabel>
            <Select m="1" placeholder="Select App" onChange={handleSelectChange}>
              {globalState.apps?.map((x, i) => (
                <option key={i} value={x.id}>
                  {x.title}
                </option>
              ))}
            </Select>

            <FormLabel mt={3} mb={2}>Select Owner Company</FormLabel>
            <Select m="1" placeholder="Select Owner Company" onChange={handleSelectOwner}>
              {ownersCompany?.map((x, i) => (
                <option key={i} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Select>

            <FormLabel mt={3} mb={2}>Select Project Company</FormLabel>
            <Select m="1" placeholder="Select Project Company" onChange={handleSelectProject}>
              {projectCompany?.map((x, i) => (
                <option key={i} value={x.id}>
                  {x.name}
                </option>
              ))}
            </Select>

            <Stack>
              <FormLabel mt={3} mb={2}>Gross Amount</FormLabel>
              <Input type="number" name="grossAmount" onChange={handleInputChange} />

              <FormLabel mt={3} mb={2}>Duration</FormLabel>
              <Input type="number" name="duration" onChange={handleInputChange} />

              <FormLabel mt={3} mb={2}>Affiliate ID</FormLabel>
              <Input type="text" name="affiliateId" onChange={handleInputChange} />

              <Stack>
                <DynamicButton action="create" title="Create Billing" variant="solid" onClick={handleCreate}/>
              </Stack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <CompanyPaymentApi dataBilling={formData} isOpen={modalBilling} detailCompany={detailCompany} onClose={handleCloseModal}/>
    </>
  );
}

export default CompanyModalBilling;
