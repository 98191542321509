
const themeConfig = {
  logo: 'https://deoapp-web.web.app/static/media/deoapp%20colors%20(4).20631dfbbabe92c4cafe.png',
  layout: {
    type: 'vertical', // vertical, horizontal, vertical-horizontal, blank
    navbar: '',
    sidebar: '',
    footer: '',
    userProfile: 'sidebar', // sidebar, navbar **choose when using vertical-horizontal type**
  },
  contentWidth: 'full', // full, boxed
};

export default themeConfig;
