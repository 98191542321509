/* eslint-disable react/prop-types */
import { Box, Card, CardBody, HStack, Heading, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { useParams } from 'react-router-dom'
import AppDrawerList from './AppDrawerList'
import { arrayRemoveFirebase, arrayUnionFirebase, getSingleDocumentFirebase } from '../../../Apis/firebaseApi'
import useUserStore from '../../../Hooks/zustand/Store'

function AppProjectView() {

  const param = useParams()
  const toast = useToast()
  const globalState = useUserStore()

  const [drawerList, setDrawerList] = useState(false)

  const [listApps, setListApps] = useState([])
  

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('projects', param?.projectId)
      setListApps(res?.apps || [])


    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  useEffect(() => {
    getData()

    return () => {
    }
  }, [])
  

  const handleOpen = () => {
    setDrawerList(true)
  }

  const handleClose = () => {
    setDrawerList(false)
  }

  const handleDelete = async (x) => {


    const collectionName = 'projects';
    const docName = param?.projectId;
    const field = 'apps';
    const values = [x];

    try {
      await arrayRemoveFirebase(collectionName, docName, field, values);
      toast({
        title: 'Success',
        description: 'success add apps for this project.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      //   console.log(result); // Pesan toast yang berhasil
      getData()
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  return (
    <Stack>
      <Card borderRadius={'md'}>
        <CardBody>
          <HStack mb={3}>
            <Box>
              <Heading
                as='h2' size='md'
              >
                                App Used
              </Heading>
            </Box>
            <Spacer />
            <DynamicButton
              size={'sm'}
              action="create"
              title={'Add Apps'}
              variant={'solid'}
              onClick={() => handleOpen()}
            />
          </HStack>
          <TableContainer
            mt={5}
            borderRadius={'sm'}
            boxShadow={'base'}
            fontSize={'sm'}
            fontWeight={500}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>module</Th>
                  <Th>redirectUrl</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listApps?.length > 0 && (
                  listApps.map((x, i) => {
                    const newData = globalState?.apps?.find((z) => z.id === x)



                    return(
                      <Tr key={i}>
                        <Td>
                          {newData?.title}
                          <Text fontSize="2xs">ID: {newData?.id}</Text>
                        </Td>
                        <Td>
                          {newData?.module}
                        </Td>
                        <Td>
                          {newData?.redirectUrl}
                        </Td>
                        <Td>
                          <DynamicButton
                            size={'xs'}
                            variant={'solid'}
                            action="delete"
                            onClick={() => handleDelete(x)}
                          />
                        </Td>
                      </Tr>
                    )
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      <AppDrawerList companyId={param?.companyId} projectId={param?.projectId} isOpen={drawerList} onClose={() => handleClose()} refreshData={() => getData()}/>
    </Stack>
  )
}

export default AppProjectView