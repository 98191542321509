/* eslint-disable no-alert */
import {
  Box, Card, CardBody, Flex, FormControl, FormLabel, Grid, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import KnowladgeTable from './KnowladgeTable';
import BackButtons from '../../Components/Buttons/BackButtons';
import { arrayRemoveFirebase, arrayUnionFirebase, deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, getUsersByIds, setDocumentFirebase } from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { IoCloseSharp } from 'react-icons/io5';
import useUserStore from '../../Hooks/zustand/Store';
import { moduleOption } from '../../Utils/dataUtils';
import AppProjectView from './Components/AppProjectView';

function ProjectViewsV2() {
  const params = useParams();
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [manager, setManager] = useState([]);
  const [owner, setOwner] = useState([]);
  const [addOwner, setAddOwner] = useState('management');
  const [projects, setProjects] = useState([]);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [openModalAddModule, setOpenModalAddModule] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  const [userData, setUserData] = useState('');
  const [detailCompany, setDetailCompany] = useState('');
  const [detailProject, setDetailProject] = useState('');
  const [dataCreate, seDataCreate] = useState('');
  const globalState = useUserStore();

  const getData = async () => {
    try {
      globalState.setIsLoading(true);
      const result = await getSingleDocumentFirebase('companies', params?.companyId);
      setDetailCompany(result)
      
      const projects = await getSingleDocumentFirebase('projects', params?.projectId);
      globalState.setIsLoading(false);
      setDetailProject(projects)
      const userIds = projects?.users;
      const ownerIds = projects?.owner;
      const managerIds = projects?.managers;
      const usersData = await getUsersByIds(userIds || []);
      const ownerData = await getUsersByIds(ownerIds || []);
      const managerData = await getUsersByIds(managerIds || []);
      setManager(managerData)
      setOwner(ownerData)
      setUsers(usersData)
      setProjects(projects)
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const findUser = async () => {
    if(emailUser?.length === 0) return  

    console.log(emailUser, 'ini email user')

    try {
      const conditions = [
        { field: 'email', operator: '==', value: emailUser },
      ];
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase('users', conditions);
      globalState.setIsLoading(false);

      console.log(res, 'ini res')

      if (res?.length > 0) {
        setUserData(res[0]);
      } else {
        return toast({
          title: 'User Not Found',
          description: 'The user with the provided email was not found.',
          status: 'warning',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while searching for the user.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleSave = async () => {
    if (userData === '') {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    try {
      globalState.setIsLoading(true);
      const fieldMap = {
        user: 'users',
        manager: 'managers',
        owner: 'owner',
        module: 'modules'
      };
    
      const fieldSet = fieldMap[dataCreate];
      if (fieldSet) {
        if (dataCreate === 'user') {
          await setDocumentFirebase(`projects/${params?.projectId}/users`, userData.id, userData);
          await arrayUnionFirebase('projects', params?.projectId, fieldSet, [userData.id]);
        } else {
          await arrayUnionFirebase('projects', params?.projectId, fieldSet, [userData.id]);
        }
      } else {
        console.error('Invalid dataCreate value:', dataCreate);
      }
      globalState.setIsLoading(false);
      setUserData('')
      setEmailUser('')
      getData()

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      setOpenModalAddUser(false);
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error saving data:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const updateModule = async (arg, data) => {
    // return
    if (arg === 'delete') {
      const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
      if (!confirmDelete) {
        return
      }
    }

    if (arg === 'create') {
      if (detailProject?.modules?.includes(addOwner)) {
        return toast({
          title: 'Error',
          description: `${addOwner} already exists`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }

    try {
      globalState.setIsLoading(true);
      if (arg === 'create') {
        console.log(addOwner, 'ini add owner')
        await arrayUnionFirebase('projects', params?.projectId, 'modules', [addOwner]);
      } else {
        await arrayRemoveFirebase('projects', params?.projectId, 'modules', [data]);
      }
      globalState.setIsLoading(false);
      getData()

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      setOpenModalAddModule(false)
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error saving data:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const setOpenModal = (arg) => {
    setOpenModalAddUser(true)
    seDataCreate(arg)
  }

  const closeModal = () => {
    setOpenModalAddUser(false);
    setUserData('')
    setEmailUser('')
  };


  const deleteUser = async (id, type) => {
    try {
      const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
      const fieldMap = {
        user: 'users',
        manager: 'managers',
        owner: 'owner',
        module: 'modules'
      };
    
      const fieldSet = fieldMap[type];
      if (fieldSet) {
        if (dataCreate === 'user') {
          await deleteDocumentFirebase(`projects/${params?.projectId}/users`, id);
          await arrayRemoveFirebase('projects', params?.projectId, fieldSet, [id]);
        } else {
          await arrayRemoveFirebase('projects', params?.projectId, fieldSet, [id]);
        }
      } else {
        console.error('Invalid dataCreate value:', dataCreate);
      }
      if (confirmDelete) {
        globalState.setIsLoading(true);
        globalState.setIsLoading(false);
        getData()

        toast({
          title: 'Data Saved',
          description: 'The data has been successfully saved.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error saving data:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <Stack p={[1, 1, 5]}>
      <Stack spacing={5}>
        <HStack>
          <BackButtons />
          <Box>
            <Heading size={'md'}>Detail Project {detailProject?.name}</Heading>
          </Box>
          <Spacer />
        </HStack>

        <Grid templateColumns={'repeat(2, 1fr)'} gap={5}>
          <Stack overflowX="auto">
            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Manager
                    </Heading>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    variant={'solid'}
                    title={'Add User'}
                    onClick={() => setOpenModal('manager')}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  borderRadius={'sm'}
                  borderColor={'gray.100'}
                  boxShadow={'base'}
                  maxHeight="400px"
                  overflowY="auto"
                  fontSize={'sm'}
                  fontWeight={500}
                  
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr style={{ position: 'sticky', top: 0, zIndex: 1}}>
                        <Th>Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {manager?.map((x, i) => (
                        <Tr key={i}>
                          <Td>
                            {x?.name}
                            <Text fontSize="2xs">ID: {x?.id}</Text>
                          </Td>
                          <Td>
                            {x?.phone}
                          </Td>
                          <Td>
                            {x?.email}
                          </Td>
                          <Td>
                            <DynamicButton
                              variant={'solid'}
                              size={'xs'}
                              action="delete"
                              // title={'Add User'}
                              onClick={() => deleteUser(x.id, 'manager')}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      User
                    </Heading>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    variant={'solid'}
                    title={'Add User'}
                    onClick={() => setOpenModal('user')}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  borderRadius={'sm'}
                  boxShadow={'base'}
                  maxHeight="400px"
                  overflowY="auto"
                  fontSize={'sm'}
                  fontWeight={500}
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <Th>Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {users?.map((x, i) => (
                        <Tr key={i}>
                          <Td>
                            {x?.name}
                            <Text fontSize="2xs">ID: {x?.id}</Text>
                          </Td>
                          <Td>
                            {x?.phone}
                          </Td>
                          <Td>
                            {x?.email}
                          </Td>
                          <Td>
                            <DynamicButton
                              size={'xs'}
                              action="delete"
                              variant={'solid'}
                              // title={'Add User'}
                              onClick={() => deleteUser(x.id, 'user')}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

          </Stack>
          <Stack overflowX="auto">
            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Module
                    </Heading>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    title={'Add module'}
                    variant={'solid'}
                    // onClick={() => setOpenModalAddUser(true)}
                    onClick={() => setOpenModalAddModule(true)}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  borderRadius={'sm'}
                  boxShadow={'base'}
                  fontWeight={500}
                  fontSize={'sm'}
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th textAlign="center">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {detailProject?.modules?.map((x, i) => (
                        <Tr key={i}>
                          <Td>
                            <Text style={{ textTransform: 'capitalize' }}>
                              {x}
                            </Text>
                          </Td>
                          <Td textAlign="center">
                            <DynamicButton
                              size={'xs'}
                              variant={'solid'}
                              action="delete"
                              onClick={() => updateModule('delete', x)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Owner
                    </Heading>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    title={'Add Owner'}
                    variant={'solid'}
                    onClick={() => setOpenModal('owner')}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  borderRadius={'sm'}
                  boxShadow={'base'}
                  fontSize={'sm'}
                  fontWeight={500}
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Phone</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {owner?.map((x, i) => (
                        <Tr key={i}>
                          <Td>
                            {x?.name}
                            <Text fontSize="2xs">ID: {x?.id}</Text>
                          </Td>
                          <Td>
                            {x?.phone}
                          </Td>
                          <Td>
                            {x?.email}
                          </Td>
                          <Td>
                            <DynamicButton
                              size={'xs'}
                              variant={'solid'}
                              action="delete"
                              onClick={() => deleteUser(x.id, 'owner')}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

          </Stack>

          <Stack overflowX="auto">
            <AppProjectView detailProject={detailProject}/>

          </Stack>
        </Grid>

        <Modal isOpen={openModalAddUser} onClose={() => closeModal()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New {dataCreate} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
                  <Input type="text" name="company" value={emailUser} onChange={(e) => setEmailUser(e.target.value)} />

                  <Spacer />
                  <Stack spacing={2}>
                    <DynamicButton
                      size={'md'}
                      action="custom"
                      color={'green'}
                      title={'Search'}
                      onClick={() => findUser(true)}
                    />
                  </Stack>
                </Flex>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={() => closeModal()}
                />
                {userData ? (
                  <DynamicButton
                    color={'green'}
                    size={'sm'}
                    action="custom"
                    title={'Submit'}
                    onClick={handleSave}
                  />
                ) : null}

              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={openModalAddModule} onClose={() => setOpenModalAddModule(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Module </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb="4">
                <FormLabel>Module : </FormLabel>
                <Flex gap={3} direction={['column-reverse', 'row', 'row']}>

                  <Select
                    value={addOwner}
                    onChange={(e) => setAddOwner(e.target.value)}
                    required
                  >
                    {moduleOption()?.map((option, index) => (
                      <option key={index} value={option.value}>{option.name}</option>
                    ))}
                  </Select>
                  <Spacer />
                  <Stack spacing={2}>
                    <DynamicButton
                      size={'md'}
                      action="custom"
                      color={'green'}
                      title={'Add'}
                      onClick={() => updateModule('create')}
                    />
                  </Stack>
                </Flex>
              </FormControl>
            </ModalBody>

            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </Stack>
  );
}

export default ProjectViewsV2;
