/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { FiUploadCloud } from 'react-icons/fi'
import { useState } from 'react'
export default function CardFile({onChange}){
  const [fileName, setFileName] = useState('Select file to import')

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    onChange(file)
  }
  return(
    <div className="card-file">
      <input type="file" className="file-input" onChange={onSelectFile}/>
      <FiUploadCloud size="50"/>
      <p>{fileName}</p>
    </div>
  )
}

CardFile.defaultProps = {
  onChange:() => 'oke'
}