import {
  Box,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { data } from '../../Components/Sidebar/DataMenu';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/zustand/Store';

function HomePage() {
  const navigate = useNavigate();
  const globalState = useUserStore();

  const handleNavigate = (menu) => {
    if (menu?.link?.includes('https')) {
      window.open(menu?.link, '_blank');
    } else {
      navigate(menu.link);
    }
  };
  return (
    <Box p={[1, 1, 5]}>
      <Stack align={'center'}>
        <Heading>Welcome to Deoapp Admin</Heading>
        <Text fontSize={'sm'}>
          Manage all access and grow your business
        </Text>
      </Stack>

      <Box p={[1, 1, 5]} my={5}>
        <SimpleGrid columns={[1, 1, 3]} spacing={4}>
          {data.map((menu, i) => (
            globalState?.roleCompany === 'owner' || globalState?.accessPage?.includes(menu.name) ? (
              <Flex
                cursor={'pointer'}
                _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
                onClick={() => handleNavigate(menu)}
                flexDir={'column'}
                p={4}
                key={i}
                border={'1px'}
                borderColor={'gray.300'}
                shadow={'base'}
                borderRadius={'md'}
                align={'center'}
                pos={'relative'}
              >
                <Stack align={'center'}>
                  <Icon as={menu.icon} boxSize={12} />
                  <Text
                    fontWeight={500}
                    textTransform={'uppercase'}
                  >
                    {menu.name}
                  </Text>
                  <Spacer />
                  <Text
                    align={'center'}
                    color={'gray.500'}
                    fontSize={'sm'}
                    my={3}
                  >
                    {menu.description}
                  </Text>
                </Stack>

                {menu.status ? (
                  <Box pos={'absolute'} top={3} right={3}>
                    <Tag size={'sm'} colorScheme="red">
                      <TagLabel>{menu?.status}</TagLabel>
                    </Tag>
                  </Box>
                ) : null}
              </Flex>
            ) : (null
            )
          ))}

        </SimpleGrid>
        {(globalState?.roleCompany !== 'owner' && (globalState?.accessPage === undefined || globalState?.accessPage.length === 0)) && (
          <Stack alignItems={'center'} spacing={2} justifyContent={'center'}>
            <Stack alignItems={'center'} spacing={1} justifyContent={'center'}>
              <Text fontWeight={'500'} fontStyle={'revert'} fontSize={'xs'} letterSpacing={1} textTransform={'uppercase'}>Request Page Access To The Admin</Text>
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
}

export default HomePage;
