/* eslint-disable react/prop-types */
import { Box, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, SimpleGrid, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { arrayUnionFirebase, getCollectionFirebase } from '../../../Apis/firebaseApi'
import moment from 'moment'
import useUserStore from '../../../Hooks/zustand/Store'
import CardApp from '../../../Components/Cards/CardApp'

function AppDrawerList({projectId, companyId, isOpen, onClose, refreshData}) {


  const deoappState = useUserStore()
  const [appCompany, setAppCompany] = useState([])
  const toast = useToast()


  const getDataFromBilling = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: companyId },
      {
        field: 'transaction_status',
        operator: '==',
        value: 'settlement',
      },
      {
        field: 'expiredApp',
        operator: '>=',
        value:  new Date(),
      }
    ];

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 99

    try {
      const result = await getCollectionFirebase(
        'billings',
        conditions,
        sortBy,
        limitValue
      );

      const appAll = deoappState?.apps || [];

      const appArr = result?.map((x) => x.appId) || [];

      const filteredApps = appAll
        .filter((x) => appArr.includes(x.id))
        .map((filteredApp) => {
          const companyApp = result.find((x) => x.appId === filteredApp.id);
          return { ...filteredApp, ...companyApp };
        });

      setAppCompany(filteredApps)

    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getDataFromBilling()

    return () => {
      setAppCompany([])
    }
  }, [])

  const handleUse = async (x) => {

    const isExpired = x?.expiredApp?.seconds >= moment(new Date()).valueOf()


    if(isExpired) return  toast({
      title: 'Alert!',
      description: 'This app has expired',
      status: 'warning',
      duration: 2000,
      isClosable: true,
    });

    const collectionName = 'projects';
    const docName = projectId;
    const field = 'apps';
    const values = [x.appId];

    try {
      await arrayUnionFirebase(collectionName, docName, field, values);
      toast({
        title: 'Success!',
        description: 'success add this project',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      refreshData()

    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }


  return (
    <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent transform="translateY(-50%)" top="5%" shadow="xl">
        <DrawerHeader>
          <Center>Apps this company</Center>
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody maxH="70vh" overflowY="scroll">
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }}>
            {appCompany?.map((x, i) => {
              const isExpired = x?.expiredApp?.seconds >= moment(new Date()).valueOf()
              const data = { ...x, isExpired }
              return (
                <Box key={i}>
                  <CardApp item={data} onClick={() => handleUse(x)} />
                </Box>
              )
            }

            )}
          </SimpleGrid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default AppDrawerList