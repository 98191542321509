import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import HomeRouter from './HomeRouter';
// import WarehouseRouter from './WarehouseRouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import useUserStore from '../Hooks/zustand/Store';
import Layout from '../Layouts';
import CompanyRouter from './CompanyRouter';
import MemberRouter from './MemberRouter';
import MarketingRouter from './MarketingRouter';
import ConfigurationRouter from './ConfigurationRouter';
import BusinessRouter from './BusinessRouter';
import SettingRouter from './SettingRouter';
import SopKpiRouter from './SopKpiRouter';
import { Stack, Text } from '@chakra-ui/react';
import CrmRouter from './CrmRouter';

function MainRouter() {
  const globalState = useUserStore();
  const location = useLocation();
  const navigate = useNavigate();
  
  const isOwner = globalState?.roleCompany === 'owner';
  const hasGeneralAccess = globalState?.accessPage?.includes('General');
  const hasCrmAccess = globalState?.accessPage?.includes('Crm');
  const hasMemberAccess = globalState?.accessPage?.includes('Member');
  const hasBusinessAccess = globalState?.accessPage?.includes('Business');
  const hasSopKpiAccess = globalState?.accessPage?.includes('SOPKPI');
  const hasMarketingAccess = globalState?.accessPage?.includes('Marketing');
  
  const allRouter = [
    ...AuthenticationMainRouter,
    ...HomeRouter,
    ...SettingRouter,
    ...ConfigurationRouter,
    ...(isOwner || hasGeneralAccess ? CompanyRouter : []),
    ...(isOwner || hasCrmAccess ? CrmRouter : []),
    ...(isOwner || hasMemberAccess ? MemberRouter : []),
    ...(isOwner || hasBusinessAccess ? BusinessRouter : []),
    ...(isOwner || hasSopKpiAccess ? SopKpiRouter : []),
    ...(isOwner || hasMarketingAccess ? MarketingRouter : []),
  ];
  
  useEffect(() => {
    const path = location.pathname;

    if (globalState?.roleCompany !== 'owner') {
      if (!isPathAllowed(path)) {
        return navigate('/no-access');
      }
    }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);

  const isPathAllowed = (path) => {
    const allowedRoutes = new Set(allRouter.map((route) => route.path));
    if (allowedRoutes.has(path)) {
      return true;
    }
  
    for (const route of allowedRoutes) {
      const routeRegex = new RegExp('^' + route.replace(/:[^\s/]+/g, '[^/]+') + '$');
      if (routeRegex.test(path)) {
        return true;
      }
    }
  
    return false;
  };

  return (
    <Routes>
      {allRouter.map((item, index) => (
        <Route key={index} path={item.path} element={<Layout>{item.element}</Layout>} />
      ))}

      <Route path="*" element={<Layout><NotFoundPage /></Layout>} />
    </Routes>
  );
}

function NotFoundPage() {
  return (
    <Stack alignItems={'center'} spacing={2} justifyContent={'center'} height="100vh">
      <Stack alignItems={'center'} spacing={1} justifyContent={'center'}>
        <Text fontWeight={1000} fontStyle={'revert'} textDecoration={'AppWorkspace'} fontSize={'lg'} letterSpacing={2} textTransform={'uppercase'}>deoapp</Text>
        <Text fontWeight={'500'} fontStyle={'revert'}  fontSize={'xs'} letterSpacing={1} textTransform={'uppercase'}>404 - Page Not Found</Text>
      </Stack>
      <Stack>
      </Stack>
    </Stack>
  );
}

export default MainRouter;
