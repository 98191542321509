/* eslint-disable no-undef */
import axios from 'axios'

const baseURL='https://asia-southeast2-deoapp-indonesia.cloudfunctions.net'
// const baseURL='http://localhost:5001/deoapp-indonesia/asia-southeast2'
const options={
  headers : {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_FUNCTIONS_KEY
  }}

export const createUserFunctions =async(data) => {
  try {
    const newUrl = `${baseURL}/createUser`
    const resultPost = (await axios.post(newUrl,data,options)).data
    return resultPost
  } catch(error) {
    return {
      status: false,
      message: `Err code catch FF-CUF: ${error}`
    }
  }
}	

export const createPaymentVAFunctions = async(xenditId,orderId,amount,bankCode,companyId,module,outletId='ONLINE',projectId,name) => {

  const url = `${baseURL}/paymentCreateVA`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  const newData = {
    xenditId: xenditId,
    orderId:orderId,
    amount:parseInt(amount),
    bankCode:bankCode,
    name:name,
    companyId:companyId,
    projectId:projectId,
    outletId:outletId,
    module:module
  }
  return axios.post(url,newData,configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const createBillingVAFunctions = async(xenditId, orderId, amount, bankCode, companyId, module, outletId='ONLINE', projectId) => {
  const url = `${baseURL}/billingCreateVA`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  const newData = {
    xenditId: xenditId,
    orderId:orderId,
    amount:parseInt(amount),
    bankCode:bankCode,
    name: 'imam syafii',
    companyId:companyId,
    projectId:projectId,
    outletId:outletId,
    module:module
  }
  return axios.post(url, newData, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const indexData = async(companyId, collectionName) => {
  const url = `${baseURL}/billingGetDocsFromMongo`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  const newData = {
    company_id: companyId,
    collection_name: collectionName
  }
  return axios.post(url, newData, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const getListBank = async() => {
  const url = 'https://old.importir.com/api/list-bank-inquiry?token=syigdfjhagsjdf766et4wff6'
  return axios.get(url)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const createWithdrawRequest = async(companyId, projectId, outletId, xenditId, data) => {
  const url = `${baseURL}/withdrawCreateRequest`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  const newData = {
    company_id:companyId,
    project_id:projectId,
    outlet_id:outletId,
    xendit_id: xenditId,
    bank_code:data.bank_code,
    account_number: data.account_number,
    amount:parseInt(data.amount),
  }
  return axios.post(url, newData, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const createDomainCustom = async(data) => {
  const url = `${baseURL}/vercelCreateDomain`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_PAYMENT_KEY
  }}
  const newData = {
    domain_name: data.name,
    projectName: data.projectVercel
  }
  return axios.post(url, newData, configtest)
    .then((x) => x)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const deleteDomainCustom = async(domainName, projectVercel = 'domainview-react') => {
  const url = `${baseURL}/vercelDeleteDomain`
  const configtest = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': process.env.REACT_APP_PAYMENT_KEY
    },
    methods: 'DELETE'
  }
  const newData = {
    domain_name: domainName,
    projectName: projectVercel
  }
  return axios.post(url, newData, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const checkDomainCustom = async(domainName, projectVercel = 'domainview-react') => {
  const url = `${baseURL}/vercelCheckDomain`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': process.env.REACT_APP_PAYMENT_KEY
  }}
  const newData = {
    domain_name: domainName,
    projectName:projectVercel
  }
  return axios.post(url, newData, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const uploadImage = async(file) => {
  const configTest = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }

  const bodyParam = {
    'file': file,
    'email' : 'deoadmin@deoapp.com'
  }

  const uploadImageRes = await axios.post('https://new-admin.importir.com/api/general/upload-image', bodyParam, configTest);
  return uploadImageRes;
}

export const createSource = async(data) => {
  const url = `${baseURL}/analyticCreateSource`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  return axios.post(url, data, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const deleteSource = async(sourceId, name) => {
  const url = `${baseURL}/analyticDeleteSource`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1',
    methods: 'DELETE'
  }}
  return axios.post(url, {
    'source_id': sourceId,
    'source_name': name
  }, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const createWorkspace = async(name) => {
  const url = `${baseURL}/analyticCreateWorkspace`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  return axios.post(url, {
    name: name
  }, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const initOauth = async(data) => {
  const url = `${baseURL}/analyticInitOauth`
  const configtest = {'headers' : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  return axios.post(url, data, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const createConnection = async(data) => {
  const url = `${baseURL}/analyticCreateConnection`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1'}}
  return axios.post(url, data, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const deleteConnection = async(connectionId) => {
  const url = `${baseURL}/analyticDeleteConnection`
  const configtest = {headers : {
    'Content-Type': 'application/json',
    'Authorization': 'pFa08EJkVRoT7GDiqk1',
    methods: 'DELETE'
  }}
  return axios.post(url, {'connectionId':connectionId}, configtest)
    .then((x) => x.data)
    .catch((err) => {
      throw new Error(err.message)
    })
}
