/* eslint-disable no-alert */
import {
  Box, Button, Center, Heading, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, HStack, Input, Spacer, Text, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, FormControl, FormLabel, useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { IoCloseSharp } from 'react-icons/io5';
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/zustand/Store'

function CouponPage() {
  const globalState = useUserStore()
  const [data, setData] = useState([])
  const [search, setSearch] = useState({})
  const [modalCompany, setModalCompany] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rateDiscount, setRateDiscount] = useState('');
  const [title, setTitle] = useState('');
  const toast = useToast()
  const [loadingStates, setLoadingStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [companyUseCoupon, setCompanyUseCoupon] = useState([]);
  const itemsPerPage = 25;
  const [startIndex, setStartIndex] = useState(0);

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleSearch = async (q, p) => {
    try {
      const limitValue = startIndex + itemsPerPage;
      const coupons = await getCollectionFirebase(
        'coupons',
        [],
        { field: 'createdAt', direction: 'desc' },
        limitValue
      );
      setData(coupons)
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const onOpen = (data) => {
    if (data) {
      setRateDiscount(data.discountRate)
      setTitle(data.title)
    }
    setEditData(data); // Set edit data when opening the modal for editing
    setIsOpen(true);
  };

  const onClose = () => {
    setEditData(null); // Clear edit data when closing the modal
    setIsOpen(false);
    setRateDiscount('')
    setTitle('')
  };

  const isValidDiscountRate = (discountRate) => {
    const rate = parseFloat(discountRate);
    return !isNaN(rate) && rate >= 1 && rate <= 100;
  };

  const isValidTitle = (title) => {
    return !/\s/.test(title);
  };

  const onSubmitModal = async () => {
    if (!isValidDiscountRate(rateDiscount)) {
      return toast({
        title: 'Admin',
        description: 'Coupon rate discount must be between 1 and 100',
        status: 'error',
      })
    }

    if (!isValidTitle(title)) {
      return toast({
        title: 'Admin',
        description: 'Title cannot contain spaces',
        status: 'error',
      })
    }

    setIsLoading(true)
    const dataSave = {
      'discountRate': rateDiscount,
      'title': title,
      'companyUid': globalState.currentCompanies
    }

    if (editData) {
      await updateDocumentFirebase('coupons', editData.id, dataSave);
    } else {
      const isTitleExists = data.some((item) => item.title === title);

      if (isTitleExists) {
        setIsLoading(false)
        return toast({
          title: 'Admin',
          description: 'Title coupon already exists',
          status: 'error',
        });
      }
      await addDocumentFirebase('coupons', dataSave, globalState.currentCompanies);
    }

    handleSearch()
    setIsLoading(false)
    setRateDiscount('')
    setTitle('')
    onClose();
  };

  const deleteCoupon = async (id, index) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });

    const confirmDelete = window.confirm('If you delete this Coupon, it cannot be restored. ');
    if (confirmDelete) {
      await deleteDocumentFirebase('coupons', id)
      handleSearch()
      toast({
        title: 'Admin',
        description: 'Success delete Coupon',
        status: 'success',
      })
    }
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  }

  const companyList = async (x) => {
    const conditions = [
      { field: 'coupon', operator: '==', value: x.title },
    ];
    const company = await getCollectionFirebase('companies', conditions);

    setCompanyUseCoupon(company)
    setModalCompany(true)
  }

  useEffect(() => {
    handleSearch('*', 1)
    if (editData) {
      setRateDiscount(editData.discountRate);
      setTitle(editData.title);
    }
    return () => {
      setData()
    }
  }, [globalState?.currentCompanies, startIndex])

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Coupon</Heading>
          <Spacer />

        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <HStack mb={5}>
            <Spacer />
            <DynamicButton
              variant={'solid'}
              size={'sm'}
              action="create"
              title={'Create New'}
              onClick={() => onOpen(null)}
            />
          </HStack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Coupon </Th>
                  <Th>Discount (1% - 100%) </Th>
                  <Th>Company </Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      <HStack>
                        <Stack spacing={0}>
                          <Text
                            fontWeight={'bold'}
                            fontSize="sm"
                          >
                            {x?.title}
                          </Text>
                          <Text fontSize="3xs">{x?.id}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td> {x?.discountRate} %</Td>
                    <Td> {x?.companyId}</Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <DynamicButton
                          size="sm"
                          action="read"
                          onClick={() => companyList(x)}
                        />
                        <DynamicButton
                          size="sm"
                          action="update"
                          onClick={() => onOpen(x)}
                        />
                        <DynamicButton
                          size="sm"
                          action="delete"
                          isLoading={loadingStates[i]}
                          onClick={() => deleteCoupon(x.id, i)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Center py={5}>
            {data?.length > startIndex && (
              <Box textAlign='center'>
                <Button colorScheme='green' onClick={handleLoadMore}>Load More</Button>
              </Box>
            )}
          </Center>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Coupon</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Rate Discount (1% - 100%):</FormLabel>
              <Input
                type="number"
                min={1}
                max={100}
                value={rateDiscount}
                onChange={(e) => setRateDiscount(e.target.value)}
                required
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </FormControl>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                onClick={onClose}
              />
              <DynamicButton
                color={'green'}
                isLoading={isLoading}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={() => onSubmitModal()}
              />
            </HStack>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalCompany} onClose={() => setModalCompany(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>The company uses coupons</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {companyUseCoupon && companyUseCoupon.length > 0 ? (
              <ul style={{ marginLeft: '10%' }}>
                {companyUseCoupon.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </ul>
            ) : (
              <Text color='tomato' textAlign="center" fontWeight={'bold'}>Coupon has not been used yet!</Text>
            )}
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CouponPage