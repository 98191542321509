export const banks = [
  {
    name: 'bni',
    uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
  },
  {
    name: 'bca',
    uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png',
  },
  {
    name: 'mandiri',
    uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
  },
  {
    name: 'permata',
    uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
  },
  {
    name: 'bri',
    uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
  },
];