/* eslint-disable no-unsafe-optional-chaining */
import { Box, Button, Center, Checkbox, Flex, FormControl, FormLabel, Grid, HStack, Heading, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Select, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons'
import { deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../../Apis/firebaseApi'
import { useNavigate, useParams } from 'react-router-dom'
import DropboxUploader from '../../../Components/DropBox/DropboxUploader';
// import useUserStore from '../../Hooks/Zustand/Store';
// import RichTextEditor from '../../Components/Quill/RichTextEditor';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
// import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { MdCancel } from 'react-icons/md'
import { FaTag } from 'react-icons/fa'
import useUserStore from '../../../Hooks/zustand/Store'
import RichTextEditor from '../../../Components/Quill/RichTextEditor'
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2'
import { IoCloseCircle } from 'react-icons/io5'

function SopViewPage() {
  const [data, setData] = useState({
    title: '',
    dataHtml: '',
    categoryId: []
  })
  const [categories, setCategories] = useState([]);

  const param = useParams()
  const globalState = useUserStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const navigate = useNavigate()

  const [alertDelete, setAlertDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)

  const modalAddFeatures = useDisclosure();

  const [newTags, setNewTags] = useState('');


  const handleNewCategory = (e) => {
    setNewTags((e.target.value).toLowerCase());
  };




  function openModal() {
    setModalOpen(true);
  }


  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img src="${link}" alt="Image" width="500px" /></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p><br/> <p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setData({ ...data, dataHtml: data.dataHtml + `${htmlContent}` })
    }
  };

  const handleAddFeature = async () => {
    const existingFeatures = Array.isArray(data.tags) ? data.tags : [];

    const newFeatureList = [...existingFeatures, newTags];
    setData({ ...data, tags: newFeatureList });
    setNewTags('')

  };



  const toast = useToast()
  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('sop', param.id)
      setData(res)
      const resCategories = await getCollectionFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: globalState?.currentCompany }], null, null, null)
      setCategories(resCategories)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }


  function closeModal() {
    setModalOpen(false);
  }


  useEffect(() => {
    getData()

    return () => {
      setData({})
    }
  }, [param.id])



  const htmltoText = (html) => {
    let text = html;
    text = text.replace(/\n/gi, '');
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, '');
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, '');
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, ' $2 $1 ');
    text = text.replace(/<\/div>/gi, '\\n\\n');
    text = text.replace(/<\/li>/gi, '\\n');
    text = text.replace(/<li.*?>/gi, '  *  ');
    text = text.replace(/<\/ul>/gi, '\\n\\n');
    text = text.replace(/<\/p>/gi, '\\n\\n');
    // eslint-disable-next-line no-useless-escape
    text = text.replace(/<br\s*[\/]?>/gi, '\\n');
    text = text.replace(/<[^>]+>/gi, '');
    text = text.replace(/^\s*/gim, '');
    text = text.replace(/ ,/gi, ',');
    text = text.replace(/ +/gi, ' ');
    text = text.replace(/\n+/gi, '\\n\\n');
    return text;
  };

  const handleSave = async () => {
    if (!data?.title || !data?.status || !data?.dataHtml) {
      return toast({
        title: 'Error!',
        description: 'Please fill in the data',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setLoadingAdd(true)


    const newData = data
    newData.data = String(htmltoText(data.dataHtml))

    try {
      await setDocumentFirebase('sop', param.id, newData)
      toast({
        title: 'Success',
        description: 'Data updated',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }finally{
      setLoadingAdd(false)
    }
  }

  const handleDeleteFile = async () => {
    setLoading(true);
    try {
      await deleteDocumentFirebase('sop', param?.id);

      toast({
        title: 'Success',
        description: 'Sop file deleted',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete sop file' + error,
        duration: 3000,
        status: 'error',
      });
    } finally {
      setLoading(false);
      setAlertDelete(false)
      navigate(-1)
    }
  }

  const handleAlertDelete = () => {
    setAlertDelete(true)
  }

  const handleRemoveFeature = (index) => {
    const updatedFeatures = [...data.tags];
    updatedFeatures.splice(index, 1);
    setData({ ...data, tags: updatedFeatures });
  };

  const handleAddCategory = (value) => {
    if(data?.categoryId?.find((x) => x === value) !== undefined) return   toast({
      title: 'Pemberitahuan',
      description: 'Kategori sudah ditambahkan',
      position: 'top-right',
      duration: 3000,
      status: 'info',
      isClosable: true,
    });

    const existingFeatures = Array.isArray(data?.categoryId) ? data?.categoryId : [];
    const newFeatureList = [...existingFeatures, value];
    setData({...data, categoryId: newFeatureList})

  };

  const handleDeleteCategory = (index) => {
    const existingFeatures = Array.isArray(data?.categoryId) ? data?.categoryId : [];
    const updatedFeatures = [...existingFeatures];
    updatedFeatures.splice(index, 1);
    setData({...data, categoryId: updatedFeatures})
  }


  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <HStack>
        <Stack>
          <BackButtons />
        </Stack>
        <Stack>
          <Heading size={'md'}>Sop View</Heading>
        </Stack>
      </HStack>

      <Stack spacing={1}>

        <Heading
          as="h1"
          size="xl"
          p={1}
          contentEditable
          placeholder="Masukkan judul dokumen"
          onBlur={(e) => setData({ ...data, title: e.target.textContent })}
        >
          {data?.title || 'Untitled'}
        </Heading>
        <Text
          p={1}
          contentEditable
          placeholder="Masukkan deskripsi dokumen"
          onBlur={(e) => setData({ ...data, description: e.target.textContent })}
        >
          {data?.description ? data.description : 'Page Description (Optional)'}
        </Text>

        <Grid templateColumns={{ base: '1fr', md: '3fr 1fr' }} gap={3}>
          <Box width='full' m='2'>
            <RichTextEditor
              value={data?.dataHtml || data?.data}
              onChange={(e) => setData({ ...data, dataHtml: e })} m='1' />
          </Box>

          <Stack w='full' m='2' shadow={'md'} p='3' borderRadius='md' alignSelf='flex-start'>

            <Stack>
              <Text fontWeight="bold">Tags</Text>
              <Box
                shadow="base"
                // bg="white"
                padding={3}
                border={'0.5px'}
                borderColor={'gray.300'}
                borderRadius={'sm'}
              >
                <HStack
                  spacing={2}
                  p={2}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  w="100%"
                >
                  <HStack>
                    <Stack
                      borderRadius={'sm'}
                      pos={'relative'}
                      borderColor={'gray.300'}
                    >
                      <DynamicButton
                        action={'create'}
                        variant='solid'
                        size={'sm'}
                        onClick={modalAddFeatures.onOpen}
                      />
                    </Stack>
                    {data?.tags?.length > 0 ? (

                      <HStack w={'200px'} overflow={'auto'}
                        sx={{
                          '&::-webkit-scrollbar': {
                            w: '2',
                            h: '2',
                          },
                          '&::-webkit-scrollbar-track': {
                            w: '6',
                            h: '5',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: '10',
                            bg: 'gray.200',
                          },
                        }} p="2" gap={3} >
                        {data?.tags?.map((x, i) => (
                          <Stack
                            
                            key={i}
                            p={2}
                            border={'1px'}
                            borderRadius={'sm'}
                            shadow={'base'}
                            pos={'relative'}
                            borderColor={'gray.300'}
                            alignItems="center"
                            minW={'80px'}
                          >
                            <Box
                              cursor={'pointer'}
                              size="xs"
                              borderRadius={'full'}
                              color={'blue.600'}
                              pos={'absolute'}
                              bottom={-2}
                              right={-1}
                            >
                              {/* <FaTag /> */}
                            </Box>
                            <HStack >
                              <Text fontWeight={500} noOfLines={1} fontSize={'2xs'} >
                                {x}
                              </Text>
                              <Icon
                                onClick={() => handleRemoveFeature(i)}
                                cursor="pointer"
                                pos={'absolute'}
                                color={'red.300'}
                                top={-1}
                                right={-1}
                                as={MdCancel}
                                boxSize={4}
                              />
                            </HStack>
                          </Stack>
                        ))}
                      </HStack>
                    ) : (
                      <Text>No Tags data</Text>
                    )}
                  </HStack>

                </HStack>

              </Box>
            </Stack>

            {/* <HStack >
              <Center>
                <Checkbox isChecked={data?.isTemplate} onChange={(e) => setData({ ...data, isTemplate: e.target.checked })} />
              </Center>
              <Text fontSize='md' fontWeight={500}>Template</Text>
            </HStack> */}

            <Text fontSize='md' fontWeight={500} >Publish</Text>
            <RadioGroup onChange={(e) => setData({ ...data, status: e })} value={data?.status}>
              <Stack direction='column'>
                <Radio value='draft'>Draft</Radio>
                <Radio value='publish'>Publish</Radio>
              </Stack>
            </RadioGroup>
            <FormControl>
              <FormLabel mt="8px">Type</FormLabel>
              <Select  maxW={{
                md: '4xl',
              }}
              value={data?.type}
              placeholder="Type" onChange={(e) => {
                setData({...data, type: e.target.value})
              }
              }>
                <option value={'income'}>Income</option>
                <option value={'product'}>Product</option>
                <option value={'expense'}>Expense</option>
              </Select>
            </FormControl>
            {categories ? (<FormControl>
              <FormLabel mt="8px" fontWeight={'bold'}> Categories</FormLabel>
              <Select  maxW={{
                md: '4xl',
              }}
              placeholder="Category" onChange={(e) => {
                handleAddCategory(e.target.value)
              }
              }
              >
                {categories?.map((category, i) => { return(
                  <option key={i} value={category?.id}>{category?.name}</option>
                )})}
              </Select>
              <HStack mt={3} w={'full'}> 
                <Text fontWeight={'medium'}>Categories :</Text>  
                <Flex  gap={2} w={'300px'} overflow="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '2',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}>
                  {data?.categoryId?.map((category, i) => (
                    <Box key={i} pos={'relative'} borderRadius={'md'} shadow={'base'} px={2}>
                      <Text>{categories?.find((x) => x?.id === category)?.name}</Text>
                      <Box pos={'absolute'} top={0} right={-1} cursor={'pointer'} onClick={() => handleDeleteCategory(i)}>
                        <IoCloseCircle color='red' size={13} />
                      </Box>
                    </Box>
                  ))}
                </Flex>
              </HStack>
            </FormControl>) : (<></>)}
            <Stack w={'full'}>
              <DynamicButton
                action={'read'}
                title={'Add File'}
                onClick={openModal}
                variant={'solid'}
              />

              <DynamicButton
                action={'create'}
                title={'Save'}
                onClick={handleSave}
                variant={'solid'}
                isLoading={loadingAdd}
              />

              <DynamicButton
                action={'delete'}
                title={'Delete'}
                onClick={handleAlertDelete}
                variant={'solid'}
              />
            </Stack>

          </Stack>
        </Grid>
      </Stack>

      <DropboxUploader
        isActive={isModalOpen}
        onClose={closeModal}
        parentPath={`/${globalState.currentCompany}/sop`}
        shareLink={shareLink}
        setShareLink={handleShareLinkChange}
      />

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={() => setAlertDelete(false)}
        title={`Delete ${data?.title}`}
        onAction={handleDeleteFile}
      />

      <Modal
        isOpen={modalAddFeatures.isOpen}
        onClose={modalAddFeatures.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tags</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={newTags}
              placeholder={'Enter one new tags ...'}
              onChange={handleNewCategory}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" onClick={handleAddFeature}>
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default SopViewPage
