/* eslint-disable no-alert */
/* eslint-disable no-mixed-spaces-and-tabs */
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import {
  Box, Button, Center, Heading, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, HStack, Input, Spacer, Text, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Stack, Badge, Flex, InputGroup, InputRightElement, FormControl, FormLabel, useToast, Select
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase } from '../../Apis/firebaseApi'
import moment from 'moment'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import BackButtons from '../../Components/Buttons/BackButtons'
import { IoCloseSharp } from 'react-icons/io5'
import _axios from '../../Apis/AxiosBarrier'
import useUserStore from '../../Hooks/zustand/Store'
import { Link } from 'react-router-dom'
import ComponentExportExcel from './ComponentExportExcel'
import { moduleOption } from '../../Utils/dataUtils'

function CompanyPage() {
  const globalState = useUserStore();

  const [allCompanies, setAllCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 25;
  const [searchValue, setSearchValue] = useState('');
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenCreateCompany, setIsOpenCreateCompany] = useState(false);
  const [isOpenExpired, setIsOpenExpired] = useState(false);
  const [expiryTime, setExpiryTime] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [dataFIlter, setDataFilter] = useState({
    sort: undefined,
    arg: undefined
  });
  const toast = useToast()
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });

  const [formData, setFormData] = useState({
    company: '',
    coupon: '',
    discount: 0,
    module: 'hris', // hris, management, chat, marketing
    name: '',
    email: '',
    phone: '',
  });

  const [formDataCompany, setFormDataCompany] = useState({
    companyName: '',
    coupon: '',
    discount: 0,
    module: 'hris', // hris, management, chat, marketing
  });


  const handleSaveExpired = async () => {
    if (
      expiryTime === ''
    ) {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    const time = moment(expiryTime).valueOf()
    const data = {
      'collection': 'companies',
      'doc': companyId,
      'expired_at': time,
    };
    try {
      globalState.setIsLoading(true);
      await _axios.post('/company-setExpired', data);
      const selectCompany = companies.find((e) => e.id === companyId);
      handleSearch('', selectCompany.name)
      globalState.setIsLoading(false);

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      // Close the modal or perform any other necessary actions
      setIsOpenExpired(false);
    } catch (error) {
      // Handle error scenarios and show an error toast
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeCompany = (e) => {
    const { name, value } = e.target;
    setFormDataCompany((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (
      formData.company === '' ||
      formData.discount === '' ||
      formData.module === '' ||
      formData.name === '' ||
      formData.email === '' ||
      formData.phone === ''
    ) {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    const conditions = [
      { field: 'email', operator: '==', value: formData.email },
    ];
    globalState.setIsLoading(true);

    const res = await getCollectionFirebase('users', conditions);
    if (res.length > 0) {
      globalState.setIsLoading(false);
      return toast({
        title: 'User Validation',
        description: 'The user already exists',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    const createDataUser = {
      'name': formData.name,
      'email': formData.email,
      'country':'indonesia',
      'phoneNumber': formData.phone ,//optional,
      'module' : 'deoapp.admin'
    }

    try {
      const result = await _axios.post('/user-create', createDataUser);

      if (result.status) {
        toast({
          title: 'Data Saved',
          description: 'The dataUser has been successfully saved.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } else {
        return toast({
          title: 'Error',
          description: result.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    

      // const modulesArray = Array.isArray(formData.module) ? [formData.module] : [formData.module];
      const cleanedData = formData.company.trim().toLowerCase();
      const dataCompany = {
        'companyName': cleanedData,
        'coupon':'',
        'discount': 0,
        'module': formData.module //hris, management, chat, marketing
      }

      const createdCompany = await _axios.post('/company-create', dataCompany);

      if (createdCompany.status) {
        toast({
          title: 'Data Saved',
          description: 'The data Company has been successfully saved.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Data Saved',
          description: createdCompany.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });

      const dataUpdate = {
        'uid': result.uid,
        'companyId': createdCompany.companyId,
      };

      const endpoint = '/company-ownerAdd';
      await _axios.post(endpoint, dataUpdate);

      window.location.href = `/companies/${createdCompany.companyId}`;
      globalState.setIsLoading(false);

      setFormData({
        company: '',
        coupon: '',
        discount: 0,
        module: 'hris', // hris, management, chat, marketing
        name: '',
        email: '',
        phone: '',
      });
      setIsOpenCreate(false);
    } catch (error) {
      globalState.setIsLoading(false);
      // Handle error scenarios and show an error toast
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const closeModalCompanyAndUser = () => {
    setFormData({
      company: '',
      coupon: '',
      discount: 0,
      module: 'hris', // hris, management, chat, marketing
      name: '',
      email: '',
      phone: '',
    });
    setIsOpenCreate(false)
  }

  const closeModalCompany = () => {
    setFormDataCompany({
      companyName: '',
      coupon: '',
      discount: 0,
      module: 'hris',
    });
    setIsOpenCreateCompany(false)
  }

  const handleSaveCompany = async () => {
    if (
      formDataCompany.companyName === '' ||
      formDataCompany.discount === '' ||
      formDataCompany.module === ''
    ) {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }

    const cleanedData = formDataCompany.companyName.trim().toLowerCase();
    formDataCompany.companyName = cleanedData

    try {
      globalState.setIsLoading(true);
      const createdCompany = await _axios.post('/company-create', formDataCompany);
      globalState.setIsLoading(false);

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      window.location.href = `/companies/${createdCompany.companyId}`;
      setIsOpenCreateCompany(false);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const openModalExpired = (id) => {
    setCompanyId(id)
    setIsOpenExpired(true)
  }

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleSort = (sort, desc, type) => {
    setSort({ sort: sort, desc: desc, type: type });
    setDataFilter({
      sort: sort,
      arg: type
    })
    handleSearch(desc, null, type)
  };

  const handleSearch = async (sort, search, arg) => {
    try {
      const conditions = search
        ? [
          {
            field: 'name',
            operator: '==',
            value: search.trim(),
          },
        ]
        : [];
      let sortBy = {}

      if (arg === 'expiredAt') {
        sortBy = sort ? { field: 'expired_at', direction: 'desc' } : { field: 'expired_at', direction: 'asc' };
      } else if (arg === 'createdAt') {
        sortBy = sort ? { field: 'createdAt', direction: 'desc' } : { field: 'createdAt', direction: 'asc' };
      } else {
        sortBy = { field: 'name', direction: 'asc' };
      }
      const limitValue = startIndex + itemsPerPage;
      globalState.setIsLoading(true);
      const getCompany = await getCollectionFirebase(
        'companies',
        conditions,
        sortBy,
        limitValue
      );
      const getAllCompany = await getCollectionFirebase('companies', []);
      globalState.setIsLoading(false);
      setAllCompanies(getAllCompany.length);
      setCompanies(getCompany);
    } catch (error) {
	    throw new Error(`Error sentry: ${error}`)
    }
  };

  const deleteCompany = async (id) => {
    try {
      const userConfirmed = window.confirm('Are you sure you want to delete this company?\nPress OK to confirm or Cancel to cancel.');

      if (userConfirmed) {
        globalState.setIsLoading(true);

        await _axios.post('/company-delete', { companyId: id });
        handleSearch(sort.desc, null, sort.type)
        globalState.setIsLoading(false);
        toast({
          title: 'Success Delete',
          description: 'The data has been successfully Delete.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });

      }
    } catch (error) {
      globalState.setIsLoading(false);
      return toast({
        title: 'Admin Deoapp',
        description: 'Error deleting company:', error,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    handleSearch(dataFIlter.sort, undefined, dataFIlter.arg);
    // getAllCompany();
  }, [startIndex]);

  return (
    <Stack p={[1 ,1 , 5]}>
      <Stack spacing={3}>

        <HStack>
          <BackButtons />
          <Heading size={'md'}>Companies</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <Flex justifyContent={['flex-end']} alignItems="end" gap={3} direction={['column-reverse', 'row', 'row']} mb={3}>
            <Badge colorScheme='orange'>
              <Text>Total Companies: {allCompanies}</Text>
            </Badge>
          </Flex>
          <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Search Company"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch('', searchValue, 'filter');
                  }
                }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={() => handleSearch('', searchValue, 'filter')}
                >
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>
            <Spacer />
            <Stack spacing={2}>
              <DynamicButton
                size={'md'}
                action="create"
                variant={'solid'}
                title={'Company & User'}
                onClick={() => setIsOpenCreate(true)}
              />
            </Stack>
            <Stack spacing={2}>
              <DynamicButton
                size={'md'}
                action="create"
                variant={'solid'}
                title={'Company'}
                onClick={() => setIsOpenCreateCompany(true)}
              />
            </Stack>
            <Stack spacing={2}>
              <ComponentExportExcel fileName="ExportCompany" />
            </Stack>
          </Flex>
          <TableContainer
            mt={5}
            boxShadow={'base'}
            fontWeight={500}
            fontSize={'sm'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Status Paid</Th>
                  <Th
                    cursor="pointer"
                    onClick={() => {
                      handleSort('avgScore', !sort.desc, 'expiredAt');
                    }}
                  >Expired At{' '}
                    {sort.sort === 'avgScore' ? (
                      sort?.desc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      <></>
                    )}</Th>
                  <Th
                    cursor="pointer"
                    onClick={() => {
                      handleSort('avgScore', !sort.desc, 'createdAt');
                    }}
                  >Created At{' '}
                    {sort.sort === 'avgScore' ? (
                      sort?.desc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      <></>

                    )}</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {companies?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      {x?.name}
                      <Text fontSize="2xs">ID: {x?.id}</Text>
                    </Td>
                    <Td>
              
                    </Td>
                    <Td>
                      <DynamicButton
                        mr="2"
                        size={'xs'}
                        action="update"
                        icon={IoCloseSharp}
                        variant={'solid'}
                        onClick={() => openModalExpired(x.id)}
                      />
                      {' '}
                      {moment(x?.expired_at?.seconds * 1000).format('LLLL')}
                    </Td>
                    <Td>
                      {moment(x?.createdAt?.seconds * 1000).format('LLLL')}
                    </Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <Link to={`/companies/${x.id}`}>
                          <DynamicButton
                            size="xs"
                            action="read"
                            variant={'solid'}
                          />
                        </Link>
                        {!x?.expired_at && (
                          <DynamicButton
                            size="xs"
                            action="delete"
                            variant={'solid'}
                            onClick={() => deleteCompany(x.id)}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        {!searchValue ? (
          <Center py={5}>
            {companies?.length > startIndex && (
              <Box textAlign='center'>
                <Button colorScheme='green' onClick={handleLoadMore}>
                  Load More
                </Button>
              </Box>
            )}
          </Center>
        ) : (
          <></>
        )}
      </Stack>

      <Modal isOpen={isOpenCreate} onClose={() => closeModalCompanyAndUser()} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Company & User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Flex
              w={'100%'}
              gap={5}
              direction={['column', 'row', 'row']}
            >
              <Box
                w={['100%', '50%', '50%']}
                p={5}
                borderRadius="md"
                shadow={'base'}
              >
                <Stack spacing={3} align={'center'}>
                  <FormControl mb="4">
                    <FormLabel>Company Name:</FormLabel>
                    <Input type="text" name="company" value={formData.company} onChange={handleChange} />
                  </FormControl>

                  <FormControl mb="4">
                    <FormLabel>Coupon:</FormLabel>
                    <Input type="text" name="coupon" value={formData.coupon} onChange={handleChange} />
                  </FormControl>

                  <FormControl mb="4">
                    <FormLabel>Discount:</FormLabel>
                    <Input type="number" name="discount" value={formData.discount} onChange={handleChange} />
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Module:</FormLabel>
                    <Select
                      name="module"
                      value={formData.module}
                      onChange={handleChange}
                      required
                    >
                      {moduleOption().map((option, index) => (
                        <option key={index} value={option.value}>{option.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>

              <Box
                p={5}
                w={['100%', '50%', '50%']}
                shadow={'base'}
              >
                <FormControl mb="4">
                  <FormLabel>Name User:</FormLabel>
                  <Input type="text" name="name" value={formData.name} onChange={handleChange} />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Email:</FormLabel>
                  <Input type="email" name="email" value={formData.email} onChange={handleChange} />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Phone:</FormLabel>
                  <Input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                </FormControl>
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                // onClick={onClose}
                onClick={() => closeModalCompanyAndUser()}
              />
              <DynamicButton
                color={'green'}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={handleSave}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCreateCompany} onClose={() => closeModalCompany()} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Company</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              p={5}
              borderRadius="md"
              shadow={'base'}
            >
              <Stack spacing={3} align={'center'}>
                <FormControl mb="4">
                  <FormLabel>Company Name:</FormLabel>
                  <Input type="text" name="companyName" value={formDataCompany.companyName} onChange={handleChangeCompany} />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Coupon:</FormLabel>
                  <Input type="text" name="coupon" value={formDataCompany.coupon} onChange={handleChangeCompany} />
                </FormControl>

                <FormControl mb="4">
                  <FormLabel>Discount:</FormLabel>
                  <Input type="number" name="discount" value={formDataCompany.discount} onChange={handleChangeCompany} />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Module:</FormLabel>
                  <Select
                    name="module"
                    value={formDataCompany.module}
                    onChange={handleChangeCompany}
                    required
                  >
                    {moduleOption().map((option, index) => (
                      <option key={index} value={option.value}>{option.name}</option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </ModalBody>

          <ModalFooter>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                onClick={() => closeModalCompany()}
              />
              <DynamicButton
                color={'green'}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={handleSaveCompany}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenExpired} onClose={() => setIsOpenExpired(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Expiry Time</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Expiry Time:</FormLabel>
              <Input
                type="datetime-local"
                onChange={(e) => setExpiryTime(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                onClick={() => setIsOpenExpired(false)}
              />
              <DynamicButton
                color={'green'}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={handleSaveExpired}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default CompanyPage