/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Container, Flex, Stack } from '@chakra-ui/react';
import { Fragment } from 'react';
import SidebarComponentV3 from '../Components/Sidebar/SidebarComponentV3';
import themeConfig from '../Config/themeConfig';
import { useLocation } from 'react-router-dom';

function Layout({ children }) {


  const location = useLocation();

  const isFunnelEditPage = location.pathname.includes('/lp-builder');

  return (
    <>
      <Flex height="100vh" w={'full'}>

        {!isFunnelEditPage && (
          <SidebarComponentV3 layout={themeConfig.layout} />
        )}

        <Container maxW={'full'} overflowY={'scroll'} position='relative' >

          <Stack>
            {children}
          </Stack>

        </Container>
      </Flex>
    </>
  );
}

export default Layout;
