import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Image,
  Input,
  ListItem,
  Select,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import { useParams } from 'react-router-dom';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import GridBox from '../../Components/Grid/GridBox';
import useUserStore from '../../Hooks/zustand/Store';
import { FcFile } from 'react-icons/fc';
import _axios from '../../Apis/AxiosBarrier';

function AdsRequestView() {
  const params = useParams();
  const toast = useToast();
  const [data, setData] = useState({});
  const [status, setStatus] = useState('');
  const [traficMeta, setTraficMeta] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const apikey = process.env.REACT_APP_FUNCTIONS_HOST;
  // const status = "inavtive"
  const [dataCompany, setDataCompany] = useState('');
  const steps = [
    { title: 'Request', description: 'Client Request', value: 'request' },
    { title: 'In Progress', description: 'Being built', value: 'inProgress' },
    // { title: 'Done', description: 'Complate', value: "done" },
    { title: 'Active', description: 'Done Push to Meta', value: 'active' },
    { title: 'Inactive', description: 'Ads Done Meta', value: 'inactive' },
  ];
  const statusIndex = steps.findIndex((step) => step.value === data.status);
  const globalState = useUserStore();

  const getData = async () => {
    try {
      globalState.setIsLoading(true);
      const result = await getSingleDocumentFirebase(
        'marketing_meta',
        params.id
      );
      if (result.campaignId) {
        const data = {
          campaignId: result.campaignId,
        };

        try {
          const getDataFromPinecone = await _axios.post(
            apikey + '/airbyte-adsInsights',
            data
          );

          globalState.setIsLoading(false);
          setTraficMeta(getDataFromPinecone.data);
        } catch (error) {
          globalState.setIsLoading(false);
          console.error('Error fetching data from Pinecone:', error);
          toast({
            title: 'Error!',
            description: error.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      }

      const resultCompany = await getSingleDocumentFirebase(
        'companies',
        result.companyId
      );
      const durationEnd = result.budget.durationEnd;

      const tanggalBatas = new Date(durationEnd);
      const tanggalSaatIni = new Date();
      if (tanggalSaatIni > tanggalBatas) {
        if (result.status === 'active') {
          updateStatus('inactive');
        }
      }
      globalState.setIsLoading(false);

      setDataCompany(resultCompany);
      setStatus(result.status);
      setData(result);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle case where dateString is undefined or null

    const formattedDate = new Date(dateString).toLocaleString('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    return formattedDate;
  };

  const updateStatus = async (arg) => {
    try {
      const nextStatusIndex = steps.findIndex((step) => step.value === status);
      if (statusIndex === 0 && nextStatusIndex > 1) {
        return toast({
          title: 'Deoapp.com',
          description: 'Status can only be one step ahead',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      const statusChange = steps.findIndex((step) => step.value === status);

      if (statusIndex > statusChange) {
        return toast({
          title: 'Deoapp.com',
          description:
            'You cannot change the current status to the previous status ',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      let dataSave;

      if (data.status === 'inProgress') {
        if (campaignId === '') {
          return toast({
            title: 'Deoapp.com',
            description: 'Campaign ID cannot be empty',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        } else {
          dataSave = {
            campaignId: campaignId,
            status: arg || status,
          };
        }
      } else {
        dataSave = {
          status: arg || status,
        };
      }

      globalState.setIsLoading(true);
      await updateDocumentFirebase('marketing_meta', params.id, dataSave);
      setStatus(status);
      getData();
      globalState.setIsLoading(false);
      toast({
        title: 'Status Updated',
        description: 'The status has been successfully updated.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the status.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const formatRupiah = (amount) => {
    if (!amount) return '';

    const formattedAmount = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(amount);

    return formattedAmount;
  };

  useEffect(() => {
    getData();
    // getDataCompany();
  }, []);

  return (
    <Stack p={[1, 1, 3]}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Detail Campaign</Heading>
        <Spacer />
      </HStack>

      <Stepper
        index={statusIndex + 1}
        p={5}
        borderRadius={'md'}
        shadow={'base'}
        my={5}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step?.title}</StepTitle>
              <StepDescription fontSize={12}>
                {step?.description}
              </StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Grid templateColumns={'repeat(2, 1fr)'} gap={5}>
        <Stack>
          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Change Status
              </Text>
              <FormControl mb="4">
                <Flex align="center" mt={3}>
                  <Box p={5} w={['100%']}>
                    <FormControl mb="4">
                      <Flex>
                        <Select
                          mr={3}
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option value="request">Request</option>
                          <option value="inProgress">In Progress</option>
                          <option value="active">Active</option>
                        </Select>
                        <DynamicButton
                          size={'md'}
                          action="custom"
                          title={'Update'}
                          color="green"
                          onClick={() => updateStatus()}
                        />
                      </Flex>
                    </FormControl>
                    {data.status === 'inProgress' && (
                      <FormControl mb={4}>
                        <FormLabel>Campaign Id</FormLabel>
                        <Input
                          type="text"
                          placeholder="Campaign Id : xxxx"
                          // value={division}
                          onChange={(e) => setCampaignId(e.target.value)}
                          required
                        />
                      </FormControl>
                    )}
                  </Box>
                </Flex>
              </FormControl>
            </CardBody>
          </Card>

          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Budget & Strategies
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Daily Budget'}
                  value={formatRupiah(data?.budget?.dailyBudget)}
                />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Duration Start'}
                  value={formatDate(data?.budget?.durationStart)}
                />
                <GridBox
                  title={'Duration End'}
                  value={formatDate(data?.budget?.durationEnd)}
                />
              </Grid>
            </CardBody>
          </Card>

          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Campaign
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Objective'} value={data.objective} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Name'}
                  value={dataCompany?.name + ' ' + data?.campaignName}
                />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Ad Account'} value={data.adAccount} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Facebook'} value={data.facebookLink} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Instagram'} value={data.instagramLink} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Pixel Id'} value={data.pixelId} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Tag'} value={data.tags} />
              </Grid>
            </CardBody>
          </Card>

          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Audience
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Age'}
                  value={`${data?.targeting?.ageStart} - ${data?.targeting?.ageEnd}`}
                />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Gender'} value={data?.targeting?.sex} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Devices'} value={data?.targeting?.devices} />
              </Grid>
              {data?.targeting?.detailedTargeting && (
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="b" flex="1" textAlign="left">
                          Detailed Targeting
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <UnorderedList>
                        {data?.targeting?.detailedTargeting?.map((x, index) => (
                          <ListItem key={index}>{x}</ListItem>
                        ))}
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}

              <Text fontWeight="bold" textAlign="center" mt={5}>
                Custom Audience
              </Text>
              <Accordion allowToggle>
                {data?.targeting?.customAudience?.map((x, index) => (
                  <AccordionItem key={index}>
                    <h2>
                      <AccordionButton>
                        <Box as="b" flex="1" textAlign="left">
                          {x?.type}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <UnorderedList>
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          gap={3}
                          mt={2}
                          borderBottom={'1px'}
                          borderColor={'gray.300'}
                        >
                          <GridBox
                            title={'Audience Name'}
                            value={x.audienceName}
                          />
                        </Grid>

                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          gap={3}
                          mt={2}
                          borderBottom={'1px'}
                          borderColor={'gray.300'}
                        >
                          <GridBox title={'Category'} value={x.category} />
                        </Grid>

                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          gap={3}
                          mt={2}
                          borderBottom={'1px'}
                          borderColor={'gray.300'}
                        >
                          <GridBox
                            title={'Description'}
                            value={x.description}
                          />
                        </Grid>
                        {x.type !== 'customerList' && (
                          <>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox
                                title={'Account Name'}
                                value={x.accountName}
                              />
                            </Grid>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox title={'Event'} value={x.events} />
                            </Grid>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox
                                title={'Retention Days'}
                                value={x.retentionDays}
                              />
                            </Grid>
                          </>
                        )}
                        {x.type === 'customerList' && (
                          <Grid
                            templateColumns="repeat(2, 1fr)"
                            gap={3}
                            mt={2}
                            borderBottom="1px"
                            borderColor="gray.300"
                          >
                            <Box>
                              <Text as="b" ml={4}>
                                File
                              </Text>
                            </Box>
                            <Box
                              ml="auto"
                              cursor="pointer"
                              onClick={() => window.open(x?.link, '_blank')}
                            >
                              <Text>
                                <FcFile />
                              </Text>
                            </Box>
                          </Grid>
                        )}
                      </UnorderedList>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </CardBody>
          </Card>
        </Stack>
        <Stack>
          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Traffic
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'CPC'} value={traficMeta?.cpc} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'CPR'} value={traficMeta?.cpr} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'CTR'} value={traficMeta?.ctr} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'CPM'} value={traficMeta?.cpm} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'REACH'} value={traficMeta?.reach} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'IMPRESSION'} value={traficMeta?.impressions} />
              </Grid>
            </CardBody>
          </Card>

          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">
                Ads
              </Text>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox title={'Ad Name'} value={data?.adsCreation?.adName} />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Website URL'}
                  value={data?.adsCreation?.websiteUrl}
                />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Language'}
                  value={data?.adsCreation?.language}
                />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2}>
                <GridBox title={'Format'} value={data?.adsCreation?.format} />
              </Grid>

              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="b" flex="1" textAlign="left">
                        Location
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      {data?.adsCreation?.location?.map(
                        (recommendation, index) => (
                          <ListItem key={index}>
                            {recommendation.province}, {recommendation.city},{' '}
                            {recommendation.district}
                          </ListItem>
                        )
                      )}
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Text fontWeight="bold" textAlign="center" mt={5}>
                Ads Display
              </Text>

              <Grid templateColumns="repeat(2, 1fr)" gap={3} my={4}>
                {/* <GridBox title={"Logo"} value={data?.adsCreation?.businessLogo} /> */}
                <Box>
                  <Text as="b" ml={4}>
                    Logo
                  </Text>
                </Box>

                <Box justifySelf="end">
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    src={data?.adsCreation?.businessLogo}
                    alt="Image "
                  />
                  {/* <Text textAlign="end">{value}</Text> */}
                </Box>
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Business Name'}
                  value={data?.adsCreation?.businessName}
                />
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                mt={2}
                borderBottom={'1px'}
                borderColor={'gray.300'}
              >
                <GridBox
                  title={'Primary Text'}
                  value={data?.adsCreation?.primaryText}
                />
              </Grid>

              {data?.adsCreation?.format === 'carousel' ? (
                <>
                  <Accordion allowToggle>
                    {data?.adsCreation?.carousel?.map((x, index) => (
                      <AccordionItem key={index}>
                        <h2>
                          <AccordionButton>
                            <Box as="b" flex="1" textAlign="left">
                              Carousel {index + 1}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <UnorderedList>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox title={'Headline'} value={x.headline} />
                            </Grid>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox title={'Call To Action'} value={x.cta} />
                            </Grid>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox
                                title={'Description'}
                                value={x.description}
                              />
                            </Grid>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderBottom={'1px'}
                              borderColor={'gray.300'}
                            >
                              <GridBox title={'Web Url'} value={x.webUrl} />
                            </Grid>
                            <Grid
                              ListItem
                              templateColumns="repeat(2, 1fr)"
                              gap={3}
                              mt={2}
                              borderColor={'gray.300'}
                            >
                              <Box>
                                <Text as="b" ml={4}>
                                  {x?.type}
                                </Text>
                              </Box>

                              {x?.type === 'image' && (
                                <Box justifySelf="end">
                                  <Image
                                    borderRadius="full"
                                    boxSize="50px"
                                    src={x?.link}
                                    alt="Image "
                                  />
                                  {/* <Text textAlign="end">{value}</Text> */}
                                </Box>
                              )}
                              {x?.type !== 'image' && (
                                <Box>
                                  <Text textAlign="end">{x?.link}</Text>
                                </Box>
                              )}
                            </Grid>
                          </UnorderedList>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </>
              ) : (
                <>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={3}
                    mt={2}
                    borderBottom={'1px'}
                    borderColor={'gray.300'}
                  >
                    <GridBox
                      title={'Headline'}
                      value={data?.adsCreation?.headline}
                    />
                  </Grid>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={3}
                    mt={2}
                    borderBottom={'1px'}
                    borderColor={'gray.300'}
                  >
                    <GridBox
                      title={'Description'}
                      value={data?.adsCreation?.description}
                    />
                  </Grid>
                  <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2}>
                    <GridBox
                      title={'Call to Action'}
                      value={data?.adsCreation?.cta}
                    />
                  </Grid>

                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="b" flex="1" textAlign="left">
                            Media
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <UnorderedList>
                          {data?.adsCreation?.mediaLink?.map(
                            (recommendation, index) => (
                              <Grid
                                ListItem
                                key={index}
                                templateColumns="repeat(2, 1fr)"
                                gap={3}
                                mt={2}
                                borderBottom={'1px'}
                                borderColor={'gray.300'}
                              >
                                <Box>
                                  <Text as="b" ml={4}>
                                    {recommendation?.type}
                                  </Text>
                                </Box>

                                {recommendation?.type === 'image' && (
                                  <Box justifySelf="end">
                                    <Image
                                      borderRadius="full"
                                      boxSize="50px"
                                      src={recommendation?.link}
                                      alt="Image "
                                    />
                                    {/* <Text textAlign="end">{value}</Text> */}
                                  </Box>
                                )}

                                {/* Add a return statement for the case where recommendation?.type is not "image" */}
                                {recommendation?.type !== 'image' && (
                                  <Box>
                                    <Text textAlign="end">
                                      {recommendation?.link}
                                    </Text>

                                    {/* Render other content for types other than "image" */}
                                  </Box>
                                )}
                              </Grid>
                            )
                          )}
                        </UnorderedList>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              )}

              <Text fontWeight="bold" textAlign="center" mt={5}>
                Ad Placement
              </Text>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="b" flex="1" textAlign="left">
                        Feeds
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      {data?.adPlacement?.feeds?.map(
                        (recommendation, index) => (
                          <ListItem key={index}>{recommendation}</ListItem>
                        )
                      )}
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="b" flex="1" textAlign="left">
                        Stories And Reels
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <UnorderedList>
                      {data?.adPlacement?.storiesAndReels?.map(
                        (recommendation, index) => (
                          <ListItem key={index}>{recommendation}</ListItem>
                        )
                      )}
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              {/* <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={"Media"} value={data?.adsCreation?.format} />
              </Grid> */}
            </CardBody>
          </Card>
        </Stack>
      </Grid>
    </Stack>
  );
}

export default AdsRequestView;
