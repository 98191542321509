import { Box, Card, CardBody, Grid, Heading, HStack, Spacer, Stack, Text, useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  getSingleDocumentFirebase, getUsersByIds,
} from '../../Apis/firebaseApi';
import { useParams } from 'react-router-dom';
import GridBox from '../../Components/Grid/GridBox';
import useUserStore from '../../Hooks/zustand/Store';
import moment from 'moment';
import { formatIDR } from '../../Utils/numberUtil';

function BillingView() {
  const params = useParams();
  const toast = useToast()
  const [data, setData] = useState({});
  const [dataCompany, setDataCompany] = useState('')
  const [dataUser, setDataUser] = useState('')
  const [dataProject, setDataProject] = useState('')
  const globalState = useUserStore();


  const getData = async () => {
    try {
      globalState.setIsLoading(true);
      const result = await getSingleDocumentFirebase('billings', params.id);
      const usersData = await getUsersByIds([result.userId]);
      const companyData = await getSingleDocumentFirebase('companies', result.companyId);
      const projectData = await getSingleDocumentFirebase('projects', result.projectId);
      setDataProject(projectData)
      setDataUser(usersData[0])
      setDataCompany(companyData)
      globalState.setIsLoading(false);
      setData(result);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle case where dateString is undefined or null
  
    const formattedDate = new Date(dateString).toLocaleString('id-ID', {
      day: '2-digit', // Use 2-digit instead of 'numeric' for day
      month: '2-digit', // Use 2-digit instead of 'long' for month
      year: 'numeric',
    });
  
    return formattedDate.replace(/ /g, (match) => {
      if (match === ' ') return ' 0';
    });
  };

  useEffect(() => {
    getData();
    // getDataCompany();
  }, []);

  return (
    <Stack p={[1, 1, 3]}>
      <HStack>
        <BackButtons />
        <Box>

          <Heading size={'md'}>Detail Billing</Heading>
          <Text>{data?.name}</Text>
        </Box>
        <Spacer />
      </HStack>

      <Grid templateColumns={'repeat(2, 1fr)'} gap={5}>
        <Stack>
          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">Detail User</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Name'} value={dataUser?.name} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Email'} value={dataUser?.email} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Phone Number'} value={dataUser?.phone} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Company Name'} value={dataCompany?.name} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'project Name'} value={dataProject?.name} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'project Desc'} value={dataProject?.description} />
              </Grid>
            </CardBody>
          </Card>

        </Stack>
        <Stack>
          <Card borderRadius={'md'}>
            <CardBody>
              <Text fontWeight="bold" textAlign="center">Transaction</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'External ID'} value={data?.external_id} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Name'} value={data?.name} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Status'} value={data?.status} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Outlet ID'} value={data?.outletId} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Country'} value={data?.country === 'ID' ? 'Indonesia' : data?.country} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Method'} value={data?.method} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Expected Amount'} value={formatIDR(data?.expected_amount)} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Bank Code'} value={data?.bank_code} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Transaction To'} value={data?.company} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Module'} value={data?.module} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Type'} value={data?.type} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Currency'} value={data?.currency} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Merchant Code'} value={data?.merchant_code} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Expiration Date'} value={formatDate(data?.expiration_date)} />
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
                <GridBox title={'Created At'} value={moment(data?.createdAt?.seconds * 1000).format('LLLL')} />
              </Grid>
            </CardBody>
          </Card>


        </Stack>
      </Grid>
    </Stack>
  )
}

export default BillingView