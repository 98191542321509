/* eslint-disable react/prop-types */
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  HStack,
} from '@chakra-ui/react';
import DynamicButton from '../Buttons/DynamicButton';

const AlertDuplicateDialog = ({ isLoading, isOpen, onClose, onAction, title }) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}?
          </AlertDialogHeader>

          <AlertDialogBody>Are you sure want to duplicate this file?</AlertDialogBody>

          <AlertDialogFooter>
            <HStack spacing={2}>
              <DynamicButton onClick={onClose} action={'delete'} variant={'solid'} title={'Cancel'} />
              <DynamicButton isLoading={isLoading} onClick={onAction} action={'create'} variant={'solid'} title={'Confirm'} />
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDuplicateDialog;
