/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Stack,
  Divider,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Link,
  VStack,
  Flex,
  useToast,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import axios from 'axios';
import moment from 'moment';
import { getCollectionFirebase, getSingleDocumentFirebase } from '../../../Apis/firebaseApi';
import useUserStore from '../../../Hooks/zustand/Store';
import { formatFrice } from '../../../Utils/Helper';
import _axios from '../../../Apis/AxiosBarrier';
import { banks } from '../../../Assets/JSON/bankjson';
import CompanyModalPayment from './CompanyModalPayment';
import { useParams } from 'react-router-dom';

function CompanyPaymentApi({ isOpen, onClose, dataBilling, detailCompany }) {
  const globalState = useUserStore();
  const toast = useToast();
  const param = useParams()

  const typeDescription = (type) => {
    switch (type) {
    case 'onetime':
      return '*One-time payment. No recurring bills.';
    case 'subscribtion':
      return '*Recurring billing will be generated upon expiration.';
    case 'top-up':
      return '*The system will provide credit that can be used.';
    default:
      return '';
    }
  };



  const [formData, setFormData] = useState({ quantity: 1 });
  const [loading, setLoading] = useState(false);


  const [dataResult, setDataResult] = useState('');

  const [modalPayment, setModalPayment] = useState(false);


  const [isError, setIsError] = useState({
    name: false,
  });



  const nameParams = dataBilling?.owners?.name || '';
  const emailParams = dataBilling?.owners?.email || '';
  const phoneParams = dataBilling?.owners?.phoneNumber || '';
  const projectId = dataBilling?.project?.id || '';
  const companyId = dataBilling?.companyId;
  const companyParams = detailCompany;
  const typeParams = dataBilling;

  const dataParams = {
    name: nameParams,
    email: emailParams,
    phoneNumber: phoneParams,
    company: companyParams.name,
    projectId: projectId,
    companyId: companyId,
    type: typeParams,
  };

  const header = {
    headers: {
      'X-API-KEY': process.env.REACT_APP_API_KEY_README,
    },
  };

  const handleCreateOrder = async () => {
    setLoading(true);
  
    if (!formData?.bank || !formData?.paymentType) {
      setLoading(false);
      toast({
        title: 'Choose your payment method!',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const data = {
      payment_type: formData?.bank === 'gopay' ? 'gopay' : formData?.bank === 'qr' ? 'qr' : formData?.bank === 'manual' ? 'manual' : 'bank_transfer',
      order_id: `APB-${moment().unix()}`,
      gross_amount: totalPrice,
      bank: formData?.bank,
      echannel: false,
    };
  
    try {
      const midtransUrl = process.env.REACT_APP_PAYMENT_MIDTRANS_URL;
      const billingsUrl = `https://api.deoapp.com/subscription?uid=${dataBilling?.owners.id}&type=${formData?.paymentType}&companyId=${companyId}&projectId=${dataParams?.projectId}`;
  
      if (formData.bank === 'manual') {
        const newData = {
          ...data,
          va_number: '5931040988',
          account_holder: 'PT.Edrus Pasar Digital',
          bank: 'bca',
          transaction_status: 'pending',
          currency: 'IDR',
          linkRedirect: dataBilling?.apps?.redirectUrl,
          appId: dataBilling?.apps?.id,
          email: dataParams?.email,
          name: dataBilling?.apps?.title,
          duration: Number(dataBilling?.duration),
          type: formData?.paymentType,
          username: dataParams?.name,
          contact_information: {
            companyIdUser: companyId,
            email: dataParams?.email,
            name: dataParams?.name,
            phone_number: dataParams?.phoneNumber,
          },
          createdBy: {
            email: globalState?.email,
            status: 'admin',
          },
        };
  
        const response = await axios.post(billingsUrl, newData, header);
        const detailData = await getSingleDocumentFirebase('billings', response.data._path.segments[1]);
        setDataResult(detailData);
        handleOpenModal();
      } else {
        const res = await axios.post(midtransUrl, data, header);
        if (res.status === 201) {
          const newData = {
            ...res.data.data,
            linkRedirect: dataBilling?.apps?.redirectUrl,
            appId: dataBilling?.apps?.id,
            email: dataParams?.email,
            name: dataBilling?.apps?.title,
            duration: Number(dataBilling?.duration),
            type: formData?.paymentType,
            username: dataParams?.name,
            contact_information: {
              companyIdUser: companyId,
              email: dataParams?.email,
              name: dataParams?.name,
              phone_number: dataParams?.phoneNumber,
            },
            createdBy: {
              email: globalState?.email,
              status: 'admin',
            },
          };
  
          const response = await axios.post(billingsUrl, newData, header);
          const detailData = await getSingleDocumentFirebase('billings', response.data._path.segments[1]);
          setDataResult(detailData);
          handleOpenModal();
        } else {
          toast({
            title: 'Error!',
            description: 'An error occurred while creating order.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setModalPayment(true);
  };

  const handleCloseModal = () => {
    setModalPayment(false);
    setDataResult('');
    onClose();
  };

  const handleData = (e) => {
    const { id: key, value } = e.target;
    const existingData = { ...formData };
    existingData[key] = value;
    setFormData(existingData);
  };

  const itemPrice = dataParams?.type?.grossAmount;


  let totalPrice = Number(itemPrice)
  const bankNames = banks.map((bank) => bank?.name);
  const costAdmin =
        formData?.bank === 'qr'
          ? 0.7 : formData?.bank === 'gopay' ? 2
            : bankNames.includes(formData?.bank)
              ? 5000
              : 0;
  const totalCostAdmin =
        formData?.bank === 'qr' || formData?.bank === 'gopay'
          ? (totalPrice * costAdmin) / 100
          : bankNames.includes(formData?.bank)
            ? 5000
            : 0;
  totalPrice += totalCostAdmin;

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'full'} placement="bottom">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <SimpleGrid columns={[1, 1, 2]} p={5} overflowY={'scroll'} align={'center'}>
              <Stack px={'5%'}>
                <FormControl isInvalid={isError.name}>
                  <FormLabel>Name</FormLabel>

                  <Input
                    type="text"
                    id="name"
                    onChange={handleData}
                    placeholder={'Nama Anda'}
                    value={dataParams?.name}
                    disabled
                  />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Name is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <FormLabel>No HP/WA</FormLabel>

                  <Input
                    type="text"
                    id="phone"
                    onChange={handleData}
                    placeholder={'No. Whatsapp Anda'}
                    value={dataParams?.phoneNumber}
                    disabled
                  />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <FormLabel>Email</FormLabel>

                  <Input
                    type="email"
                    id="email"
                    onChange={handleData}
                    placeholder={'Email Anda'}
                    value={dataParams?.email}
                    disabled
                  />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={isError.name}>
                  <FormLabel>Company</FormLabel>

                  <Input
                    type="text"
                    id="company"
                    onChange={handleData}
                    placeholder={'Company Anda'}
                    value={dataParams?.company}
                    disabled
                  />
                  {!isError ? (
                    <></>
                  ) : (
                    <FormErrorMessage>Email is required.</FormErrorMessage>
                  )}
                </FormControl>
                <Heading mt={10} size="md">
                                    Payment Method
                </Heading>
                <SimpleGrid columns={2}>
                  <Box position="relative">
                    <Box
                      cursor={'pointer'}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          bank: 'gopay',
                          paymentService: 'gopay',
                        })
                      }
                      _hover={{
                        transform: 'scale(1.05)',
                        transition: 'ease-in 0.1s',
                      }}
                      p={3}
                      m={1}
                      borderWidth={formData?.bank === 'gopay' ? 2 : 1}
                      borderColor={
                        formData?.bank === 'gopay' ? 'orange' : 'lightgray'
                      }
                    >
                      <Image
                        src="https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png"
                        width={'7em'}
                        alt="gopay"
                      />
                    </Box>


                  </Box>
                  <Box position="relative">
                    <Box
                      cursor={'pointer'}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          bank: 'qr',
                          paymentService: 'qr',
                        })
                      }
                      _hover={{
                        transform: 'scale(1.05)',
                        transition: 'ease-in 0.1s',
                      }}
                      p={3}
                      m={1}
                      borderWidth={formData?.bank === 'qr' ? 2 : 1}
                      borderColor={
                        formData?.bank === 'qr' ? 'orange' : 'lightgray'
                      }
                    >
                      <Image
                        src="https://seeklogo.com/images/Q/quick-response-code-indonesia-standard-qris-logo-F300D5EB32-seeklogo.com.png"
                        width={'7em'}
                        alt="qr"
                      />
                    </Box>
                  </Box>
                  <Box position="relative">
                    <Box
                      cursor={'pointer'}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          bank: 'manual',
                          paymentService: 'manual',
                        })
                      }
                      _hover={{
                        transform: 'scale(1.05)',
                        transition: 'ease-in 0.1s',
                      }}
                      p={3}
                      m={1}
                      borderWidth={formData?.bank === 'manual' ? 2 : 1}
                      borderColor={
                        formData?.bank === 'manual' ? 'orange' : 'lightgray'
                      }
                    >
                      <Text fontWeight={'bold'} py={2} fontSize={'lg'}>Transfer Manual</Text>
                    </Box>
                  </Box>


                  {banks.map((bank, i) => (
                    <Box position="relative" key={i}>
                      <Box
                        onClick={() =>
                          setFormData({ ...formData, bank: bank.name })
                        }
                        cursor={'pointer'}
                        _hover={{
                          transform: 'scale(1.05)',
                          transition: 'ease-in 0.1s',
                        }}
                        m={1}
                        p={3}
                        borderWidth={formData?.bank === bank.name ? 2 : 1}
                        borderColor={
                          formData?.bank === bank.name ? 'orange' : 'lightgray'
                        }
                      >
                        <Image src={bank.uri} width={'5em'} />
                      </Box>
                    </Box>
                  ))}
                </SimpleGrid>

                <Stack my={2}>
                  <Heading size="md">Payment Type</Heading>
                  <RadioGroup
                    onChange={(value) =>
                      setFormData({ ...formData, paymentType: value })
                    }
                    value={formData?.paymentType}
                  >
                    <Stack direction="row" justifyContent={'space-around'}>
                      <Radio value="onetime">One Time</Radio>
                      {/* <Radio value="top-up">Top Up</Radio> */}
                      <Radio value="subscribtion">Subscribtion</Radio>
                    </Stack>
                  </RadioGroup>
                  <Text>{typeDescription(formData?.paymentType)}</Text>
                </Stack>


              </Stack>
              <VStack px={'5%'} py={10} position="relative">
                <Heading size="md">Akses Yang Kamu Dapatkan</Heading>

                <Stack p={5} borderRadius={'lg'}>
                  <Text
                    noOfLines={4}
                    fontSize={'sm'}
                    dangerouslySetInnerHTML={{
                      __html: dataBilling?.description,
                    }}
                  />
                </Stack>
                <Stack w="100%" mb={5} p={'5'}>
                  <Flex justify="space-between">
                    <Text>{dataBilling?.name}</Text>
                    <Text fontWeight="semibold">
                                            IDR {formatFrice(Number(itemPrice))}
                    </Text>
                  </Flex>

                  <Flex justify="space-between">
                    <Text>Biaya Admin</Text>
                    <Text fontWeight="semibold">
                      {formData.bank === 'gopay' || formData.bank === 'qr'
                        ? `${costAdmin}%`
                        : 'IDR ' + formatFrice(costAdmin)}
                    </Text>
                  </Flex>

                  <Divider />

                  <Flex justify="space-between">
                    <Text fontWeight="semibold">Total</Text>
                    <Text fontWeight="extrabold">
                                            IDR {formatFrice(totalPrice)}
                    </Text>
                  </Flex>
                </Stack>

                <Button
                  bg={'black'}
                  color={'white'}
                  borderWidth={1}
                  borderRadius={10}
                  fontWeight={500}
                  onClick={handleCreateOrder}
                  // pos={'absolute'}
                  bottom={0}
                  right={2}
                  left={2}
                  h={'3.5em'}
                  isLoading={loading}
                  style={{
                    width: '-webkit-fill-available',
                  }}
                >
                                    Buat Pesanan
                </Button>

                {dataResult !== '' && (
                  <Button
                    bg={'black'}
                    color={'white'}
                    borderWidth={1}
                    borderRadius={10}
                    fontWeight={500}
                    onClick={handleOpenModal}
                    // pos={'absolute'}
                    bottom={0}
                    right={2}
                    left={2}
                    h={'3.5em'}
                    style={{
                      width: '-webkit-fill-available',
                    }}
                  >
                                        Lihat Pembayaran
                  </Button>
                )}
              </VStack>
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <CompanyModalPayment
        isOpen={modalPayment}
        onClose={handleCloseModal}
        dataResult={dataResult}
        setDataResult={setDataResult}
      />
    </>
  );
}

export default CompanyPaymentApi;
