/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Box, Button, Heading, HStack, Spacer, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, InputGroup, Input, InputRightElement, Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  deleteDocumentFirebase, getCollectionFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/zustand/Store';
import AlertConfirmation from '../../Components/Components/AlertConfirmation';
import ImageModal from '../../Components/Components/ImageModal';
import moment from 'moment'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import CouponCreate from './CouponCreate';

function CouponPageV2() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [loadingStates, setLoadingStates] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popupImage, setPopupImage] = useState(false);
  const [image, setImage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const [dataDelete, setDataDelete] = useState({
    id: '',
    index: '',
  });
  const [editData, setEditData] = useState(null);
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSort = (sort, desc, type) => {
    setSort({ sort: sort, desc: desc, type: type });
    getData(desc, null, type)
  };

  const getData = async (sort, search, arg) => {
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [];
      if (search) {
        conditions.push({ field: 'title', operator: '==', value: search });
      }
      const sortBy = sort ? { field: 'createdAt', direction: 'desc' } : { field: 'createdAt', direction: 'asc' };
      const limitValue = startIndex + itemsPerPage;

      const res = await getCollectionFirebase(
        'coupon',
        conditions,
        sortBy,
        limitValue
      );
      setData(res);

      const totalContacts = await getCollectionFirebase(
        'coupons',
        conditions
      );
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
	    throw new Error(`Error : ${error}`)
    }
  };

  const onOpenModal = (data) => {
    setEditData(data);
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setEditData(null);
    setIsOpenModal(false);
  };

  const onSubmitModal = async () => {
    getData();
  };

  const openConfirmationAlert = (id, index) => {
    onOpen();
    setDataDelete({
      id: id,
      index: index,
    });
  };

  const deleteTemplateLp = async () => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dataDelete.index] = true;
      return newStates;
    });

    await deleteDocumentFirebase('coupons', dataDelete.id);
    onClose();
    getData();
    toast({
      title: 'Admin',
      description: 'Success delete Template',
      position: 'top-right',
      status: 'success',
    });
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dataDelete.index] = false;
      return newStates;
    });
  };

  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage, getCollectionFirebase, globalState.currentCompany,]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Coupon</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <HStack mb={5}>
            <Spacer />
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Search Name"
                onChange={(event) => setSearchValue(event.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => getData('', searchValue, 'filter')}>
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>

            <DynamicButton
              size={'sm'}
              action="create"
              title={'Create'}
              onClick={() => onOpenModal(null)}
            />
          </HStack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Coupon </Th>
                  <Th>Discount (1% - 100%) </Th>
                  <Th>Company </Th>
                  <Th cursor="pointer"
                    onClick={() => {
                      handleSort('avgScore', !sort.desc, 'createdAt');
                    }}
                  >Created At {' '}
                    {sort.sort === 'avgScore' ? (
                      sort?.desc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      <></>
                    )}</Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      <HStack>
                        <Stack spacing={0}>
                          <Text
                            fontWeight={'bold'}
                            fontSize="sm"
                          >
                            {x?.title}
                          </Text>
                          <Text fontSize="3xs">{x?.id}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td> {x?.discountRate} %</Td>
                    <Td> {x?.companyId}</Td>
                    <Td>{moment(x?.createdAt?.seconds * 1000).format('LLLL')}</Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <DynamicButton
                          size="sm"
                          action="update"
                          onClick={() => onOpenModal(x)}
                        />
                        <DynamicButton
                          size="sm"
                          action="delete"
                          isLoading={loadingStates[i]}
                          onClick={() => openConfirmationAlert(x.id, i)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack alignItems={'center'} justifyContent="center" mt={3}>
            {currentPage < totalPages && (
              <Button
                colorScheme={'green'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Stack>
        </Box>
      </Box>

      <CouponCreate
        isOpen={isOpenModal}
        onClose={onCloseModal}
        editData={editData}
        resSave={onSubmitModal}
      />

      <ImageModal
        isOpen={popupImage}
        onClose={() => setPopupImage(false)}
        image={image}
      />

      <AlertConfirmation
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        title={'Delete Coupon'}
        onAction={deleteTemplateLp}
        description={'Do you want to delete this coupon ?'}
      />
    </>
  );
}

export default CouponPageV2;
