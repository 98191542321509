/* eslint-disable react/prop-types */
import {
  Flex,
  HStack,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import DynamicButton from '../../../../Components/Buttons/DynamicButton';
const ListComponent = ({
  title,
  onShowDetail,
  tags
}) => {
  return (
    <HStack
      borderRadius={'md'}
      h="100px"
      w={'full'}
      bg={useColorModeValue('gray.100', 'gray.700')}
      justifyContent={'space-between'}

      px="16px"
      shadow={'md'}
    >
      <Stack align={'center'} w={'xs'}>
        <Text textTransform={'capitalize'} fontWeight={'bold'} >Nama</Text>
        <Text textTransform={'capitalize'} textOverflow={'ellipsis'}>{title || ''}</Text>
      </Stack>
      <Stack justify={'center'} alignItems={'center'}  w={'sm'}>
        <Text textTransform={'capitalize'} fontWeight={'bold'}>Tags</Text>
        <Flex gap={1}>
          {tags?.map((tag, i) => (
            <Tag
              size={'sm'}
              key={i}
              variant="solid"
              colorScheme="teal"
            >
              {tag}
            </Tag>
          ))}
        </Flex>
      </Stack>
      {onShowDetail && (
        <DynamicButton
          action={'read'}
          variant={'solid'}
          title={'Detail'}
          onClick={() => onShowDetail()}
        />
      )}
    </HStack>
  );
};

export default ListComponent;
