/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Stack,
  Image,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { IoCloseSharp } from 'react-icons/io5';
import ImageComponent from '../../Components/Image/ImageComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase, setDocumentFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';
import { uploadImage } from '../../Apis/firebaseFunctions';

const AppsCreate = ({
  isOpen,
  onClose,
  editData,
  resSave,
}) => {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const dataOptions = ['Tech', 'Beauty', 'Health', 'Business'];
  const [formData, setFormData] = useState({
    html: '',
    image: '',
    title: '',
    category: 'Tech',
    type: 'landingPage',
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        title: editData.title || '',
        price: editData.price || '',
        price_plan: editData.price_plan || '',
        link: editData.link || '',
        video: editData.video || '',
        description: editData.description || '',
      });
    } else {
      setFormData({
        title: '',
        price: '',
        price_plan: '',
        link: '',
        video: '',
        description: '',
      });
    }
  }, [editData]);

  const submitImage = async (file) => {
    try {
      const res = (await uploadImage(file[0])).data;

      if (res.status) {
        setFormData((prevData) => ({ ...prevData, image: res.data }));
        toast({
          title: 'Upload Image',
          description: 'Image saved',
          status: 'success',
          duration: 2000,
        });
      }
    } catch (error) {
      toast({
        title: 'Image Size Error',
        description: error.message,
        status: 'error',
      });
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({ ...prevData, image: '' }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onSubmit = async () => {
    try {
      if (
        formData.title === '' ||
                formData.price === '' ||
                formData.price_plan === '' ||
                formData.description === '' ||
                formData.video === '' ||
                formData.link === ''
      ) {
        return toast({
          title: 'Input Validation',
          description: 'Please fill in all fields',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      const isValidLink = isValidURL(formData.link);
      if (!isValidLink) {
        return toast({
          title: 'Input Validation',
          description: 'Please enter a valid accessible link',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      const isYouTubeEmbedLink = formData.video.includes('youtube.com/embed');
      if (isYouTubeEmbedLink) {
        let url = formData.video;
        if (formData.video.includes('<iframe')) {
          const startIdx = formData.video.indexOf('src="') + 5;
          const endIdx = formData.video.indexOf('"', startIdx);
          url = formData.video.substring(startIdx, endIdx);
        }
        formData.video = url; // Memperbarui formData.video dengan URL yang berhasil dipotong
      } else {
        return toast({
          title: 'Input Validation',
          description: 'Please enter a valid YouTube video URL',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      // return console.log(editData, formData, "ffffff")

      setIsLoading(true);
      globalState.setIsLoading(true);
      if (editData) {
        await updateDocumentFirebase('apps', editData.id, formData);
      } else {
        formData.createdAt = new Date();
        await setDocumentFirebase(
          'apps',
          formData.title,
          formData,
          // globalState.currentCompany
        );
      }
      globalState.setIsLoading(false);

      toast({
        title: 'Deoapp',
        description: 'Success Save Data',
        status: 'success',
      });
      setIsLoading(false);
      setFormData({
        title: '',
        price: '',
        price_plan: '',
        link: '',
        video: '',
        description: '',
      });
      resSave();
      onClose();
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Apps Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            w={'100%'}
            mb={3}
            gap={5}
            direction={['column', 'row', 'row']}
          >
            {/* Box for image upload */}
            <Box
              w={['100%', '50%', '50%']}
              p={5}
              borderRadius="md"
              shadow={'base'}
            >
              <FormControl mb="4">
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  placeholder='xxxx'
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Price</FormLabel>
                <Input
                  type="number"
                  name="price"
                  placeholder='10000'
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Price Plan</FormLabel>
                <Input
                  type="text"
                  name="price_plan"
                  placeholder='Monthly / Account / Page / etc'
                  value={formData.price_plan}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </Box>

            <Box
              p={5}
              w={['100%', '50%', '50%']}
              shadow={'base'}
            >
              <FormControl mb="4">
                <FormLabel>link (Web)</FormLabel>
                <Input
                  type="text"
                  name="link"
                  placeholder='https://admin.deoapp.com/'
                  value={formData.link}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Video (link Embed Youtube)</FormLabel>
                <Input
                  type="text"
                  name="video"
                  placeholder='https://www.youtube.com/embed/mFbILexYSQg?si=ULGUz68MUeC7f9b5'
                  value={formData.video}
                  onChange={handleChange}
                  required
                />
              </FormControl>
            </Box>
          </Flex>
                    
          <Box
            // w={['100%', '50%', '50%']}
            p={5}
            borderRadius="md"
            shadow={'base'}
          >

            <FormControl mt="5">
              <FormLabel ml={2}>Description</FormLabel>
              <Textarea
                // height={200}
                name="description"
                placeholder='Write a description for this tutorial...'
                value={formData.description}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Box>


          {/* Buttons for cancel and submit */}
          <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
            <DynamicButton
              size={'sm'}
              action="custom"
              color={'red'}
              icon={IoCloseSharp}
              title={'Cancel'}
              onClick={onClose}
            />
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={onSubmit}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AppsCreate;
