import {
  Box,
  HStack,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import { useEffect } from 'react';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function LandingPageIntegration() {
  const [data, setData] = useState();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase(
        'landing_page',
        'integration'
      );
      setData(res.data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleEdit = (index, value) => {
    const newArr = data;
    newArr[index] = value;
    setData(newArr);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const dataSend = {
        data: data,
      };
      await setDocumentFirebase('landing_page', 'integration', dataSend);
      setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Box>
      <HStack>
        <BackButtons />
        <Heading size={'md'}>Integration</Heading>
        <Spacer />
      </HStack>

      <SimpleGrid columns={6}>
        {data?.map((item, index) => (
          <Box key={index} p={5} shadow="md" borderWidth="1px">
            {item && <Image src={item} alt="ini Image" />}
            <Input
              type="text"
              placeholder="Image"
              defaultValue={item}
              onChange={(e) => handleEdit(index, e.target.value)}
            />
          </Box>
        ))}
      </SimpleGrid>
      <DynamicButton
        color="green"
        isLoading={isLoading}
        size="sm"
        action="custom"
        title="Add New Section"
        onClick={() => setData([...data, 'new Integration'])}
      />

      <DynamicButton
        color="green"
        isLoading={isLoading}
        size="sm"
        action="custom"
        title="Submit"
        onClick={() => handleSave()}
      />
    </Box>
  );
}

export default LandingPageIntegration;
