/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Center,
  Image,
  VStack,
  Link,
  useToast,
} from '@chakra-ui/react';
import { FaRegCopy } from 'react-icons/fa';
import useUserStore from '../../../Hooks/zustand/Store';
import { formatFrice } from '../../../Utils/Helper';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';
import _axios from '../../../Apis/AxiosBarrierReadme';
import { banks } from '../../../Assets/JSON/bankjson';



function findBankImage(banks, bankName) {
  const selected = banks?.find((x) => x?.name?.toLowerCase() == bankName);
  const img = selected?.uri;
  return img;
}

function CompanyModalPayment({
  isOpen,
  onClose,
  dataResult,
}) {
  const toast = useToast();
  const globalState = useUserStore()


  const [alertUpdate, setAlertUpdate] = useState(false)
  const [loading, setLoading] = useState(false)


  const handleStringCopy = (string) => {
    navigator.clipboard.writeText(string);
    toast({
      title: 'Success!',
      description: 'VA Address copied',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };


  const handleUpdateBilling = async () => {
    setLoading(true)
    const updateData = {
      order_id: dataResult?.order_id,
      uid: globalState?.uid,
      duration: dataResult?.duration
    };
  
    try {
      const res = await _axios.post('/billings-update', updateData);
      if(res.status === true){
        toast({
          title: 'Success!',
          description: 'Success update this order',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        setAlertUpdate(false)
      }
    
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }finally{
      setLoading(false)
    }
  };

  const totalAmount =
        dataResult?.payment_type === 'gopay'
          ? (parseInt(dataResult.gross_amount) * 2) / 100
          :  dataResult?.payment_type === 'qr' ? (parseInt(dataResult.gross_amount) * 0.7) / 100
            : dataResult?.payment_type === 'manual' ? 0 : 4440;


  return (
    <Stack>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        size={'xl'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent transform="translateY(-50%)" top="5%" shadow="xl">
          <ModalHeader>
            <Center>Payment</Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={'70vh'} overflowY="scroll">
            <VStack justifyContent={'center'} p={5}>

              <VStack justifyContent={'center'} p={5}>
                <Heading align={'center'} size="md">
                                    Terima Kasih sudah melakukan order
                </Heading>
                <Text align={'center'}>
                                    Untuk menyelesaikan pesanan, silakan melakukan pembayaran di
                                    bawah ini :
                </Text>
                <Heading color="green" size="lg">
                                    IDR{' '}
                  {formatFrice(
                    parseInt(dataResult?.gross_amount) + totalAmount
                  )}
                </Heading>
                {dataResult?.payment_type === 'gopay' ? (
                  <VStack p={5}>
                    <Image
                      src="https://antinomi.org/wp-content/uploads/2022/03/logo-gopay-vector.png"
                      width={'10em'}
                      alt="gopay"
                    />
                    <VStack p={5}>
                      <Text align={'center'}>
                                                Silakan buka link GOPAY di bawah ini pada smartphone
                                                kamu atau scan QR:
                      </Text>
                      <Box px={100}>
                        <Center>
                          <Image src={dataResult?.actions[0].url} w={350} />
                        </Center>
                        <Stack pt={5}>
                          <Link to={dataResult?.actions[1]?.url}>
                            <Text
                              color={'blue'}
                              fontWeight={'bold'}
                              align={'center'}
                              w={'400px'}
                            >
                              {dataResult?.actions[1]?.url}
                            </Text>
                          </Link>
                        </Stack>
          

                      </Box>
                    </VStack>
                  </VStack>
                ) : dataResult?.payment_type === 'qr' ? (
                  <VStack p={5}>
                    <Image
                      src="https://seeklogo.com/images/Q/quick-response-code-indonesia-standard-qris-logo-F300D5EB32-seeklogo.com.png"
                      width={'10em'}
                      alt="gopay"
                    />
                    <VStack p={5}>
                      <Text align={'center'}>
                                                Silakan buka QRIS di bawah ini pada smartphone
                                                kamu atau scan QR:
                      </Text>
                      <Box px={100}>
                        <Center>
                          <Image src={dataResult?.actions[0].url} w={350} />
                        </Center>
                        <Stack pt={5}>
                          <Link to={dataResult?.actions[1]?.url}>
                            <Text
                              color={'blue'}
                              fontWeight={'bold'}
                              align={'center'}
                              w={'400px'}
                            >
                              {dataResult?.actions[1]?.url}
                            </Text>
                          </Link>
                        </Stack>
                    
                      </Box>
                    </VStack>
                  </VStack>
                ) : (
                  <VStack p={5}>
                    <Image
                      w={100}
                      // src={findBankImage(banks, data?.payment_information?.va_numbers[0]?.bank)}
                      src={findBankImage(banks, dataResult?.bank)}
                    />
                    <HStack>
                      {/* <Text>{data?.payment_information?.va_numbers[0]?.bank?.toUpperCase()} VA :<strong>{data?.payment_information?.va_numbers[0]?.va_number}</strong> </Text> */}

                      <Text
                        fontSize={20}
                        textTransform="capitalize"
                        fontWeight={'bold'}
                      >
                        {dataResult?.payment_type === 'manual' ? 'Account Number' : 'VA'}: {dataResult?.va_number} <br /> {dataResult?.account_holder && `(${dataResult?.account_holder})`}
                      </Text>
                      <Box
                        _hover={{
                          transform: 'scale(1.1)',
                        }}
                        cursor="pointer"
                      >
                        <FaRegCopy
                          onClick={() =>
                            handleStringCopy(dataResult?.va_number)
                          }
                        />
                      </Box>
                    </HStack>
                    <Text>Order Id: {dataResult?.order_id}</Text>
                  
                    {dataResult?.payment_type === 'manual' && (
                      <Button
                        mt={5}
                        bg={'black'}
                        color={'white'}
                        borderWidth={1}
                        borderRadius={10}
                        fontWeight={500}
                        onClick={() => setAlertUpdate(true)}
                        // pos={'absolute'}
                        bottom={0}
                        right={2}
                        left={2}
                        h={'3.5em'}
                        isLoading={loading}
                        disabled={loading}
                        style={{
                          width: '-webkit-fill-available',
                        }}
                      >
                                                        Update Billing Paid
                      </Button>
                    )}

                  </VStack>
                )}
                <Text my={5} align={'center'}>
                                    Jangan lupa untuk menyimpan screenshot bukti pembayaran dan
                                    order id
                </Text>
              </VStack>

            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    
      <AlertDialogV2
        isLoading={loading}
        isOpen={alertUpdate}
        onClose={() => setAlertUpdate(false)}
        title={`are you sure want to update this order ${dataResult?.order_id}`}
        onAction={handleUpdateBilling}
      />

    </Stack>
  );
}

export default CompanyModalPayment;
