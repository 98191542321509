/* eslint-disable no-alert */
import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Spacer,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Stack,
  FormControl,
  FormLabel,
  useToast,
  Switch,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  addDocumentFirebase,
  deleteDocumentFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore';
import { auth, db } from '../../Config/firebase';
import BackButtons from '../../Components/Buttons/BackButtons';
import DropboxUploader from '../../Components/DropBox/DropboxUploader';
import { decryptToken } from '../../Utils/encrypToken';
import moment from 'moment';
import useUserStore from '../../Hooks/zustand/Store';

function CreatomatePage() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const toast = useToast();
  const [loadingStates, setLoadingStates] = useState([]);
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [formData, setFormData] = useState({
    rasio: '1:1',
    publish: true,
  });
  const [allSwitchValues, setAllSwitchValues] = useState(
    Array(data.length).fill(false)
  );

  const [shareLink, setShareLink] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [name, setName] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [editData, setEditData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [tokenDropBox, setTokenDropBox] = useState('');

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const getData = async () => {
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [{ field: 'type', operator: '==', value: 'video' }];
      if (searchValue !== '') {
        conditions.push({ field: 'name', operator: '==', value: searchValue });
      }
      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;
      const res = await getCollectionFirebase(
        'templates',
        conditions,
        sortBy,
        limitValue
      );
      setData(res);

      const collectionRef = collection(db, 'templates');
      const conditionsCount = [where('type', '==', 'video')];

      if (searchValue !== '') {
        conditionsCount.push(where('name', '==', searchValue));
      }

      const snapshot = await getCountFromServer(
        query(collectionRef, where('type', '==', 'video'), ...conditionsCount)
      );

      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const deleteTemplate = async (id, index) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });

    const confirmDelete = window.confirm(
      'Jika kamu menghapus data ini tidak bisa di kembalikan lagi'
    );
    if (confirmDelete) {
      await deleteDocumentFirebase('templates', id);
      getData();
      toast({
        title: 'Admin',
        description: 'Success delete',
        status: 'success',
      });
    }
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const handleShareLinkChange = (x) => {
    if (x !== '') {
      setShareLink({ link: x.link, type: x.type });
      const { link, type } = x;
      let htmlContent = '';

      if (type === 'image') {
        htmlContent = `<p><img width="100px" src="${link}" alt="Image" /></p>`;
      } else if (type === 'audio') {
        htmlContent = `<p><iframe class="ql-video" frameborder="0" allowfullscreen="true" src=${link}></iframe></p>`;
      } else if (type === 'video') {
        htmlContent = `<p><iframe class="ql-audio" frameborder="0" allowfullscreen="true" src=${link}></iframe></p>`;
      } else {
        htmlContent = `<p>file: <a href=${link} rel="noopener noreferrer" target="_blank">${JSON.stringify(
          link
        )}</a></p><br/> `;
      }

      setHtmlContent(htmlContent);
    }
  };

  const handleCloseModal = () => {
    setName('');
    setShareLink('');
    setTemplateId('');
    handleShareLinkChange('');
    setHtmlContent('');
    onClose();
  };

  const onClose = () => {
    setEditData(null);
    setIsOpen(false);
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  const onOpen = (data) => {
    if (data) {
      setName(data.name);
      handleShareLinkChange(
        data?.link ? { link: data.link, type: 'video' } : ''
      );
      setTemplateId(data.templateId);
    }
    setEditData(data);
    setIsOpen(true);
  };

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      setTokenDropBox(resultData);
    } catch (error) {
      return error;
    }
  };

  const handleSwitchChangePush = async (index, id) => {
    const updatedSwitchValues = [...allSwitchValues];
    updatedSwitchValues[index] = !updatedSwitchValues[index];
    setAllSwitchValues(updatedSwitchValues);

    const dataSave = {
      publish: updatedSwitchValues[index],
    };

    try {
      await updateDocumentFirebase('templates', id, dataSave);
      getData();
      toast({
        title: 'Data Updated',
        description: 'The data has been successfully updated.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating data:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const onSubmitModal = async () => {
    if (!shareLink || !shareLink.link) {
      return toast({
        title: 'Admin',
        description: 'Template is required',
        status: 'error',
      });
    }

    if (name === '' || templateId === '') {
      return toast({
        title: 'Admin',
        description: 'Please Complete Form',
        status: 'error',
      });
    }

    // setIsLoading(true);
    const dataSave = {
      name: name,
      type: 'video',
      templateId: templateId,
      link: shareLink.link,
      userUid: auth.currentUser.uid,
      companyUid: globalState.currentCompany,
      rasio: formData.rasio,
      publish: formData.publish,
    };
    // setIsLoading(false);

    if (editData) {
      await updateDocumentFirebase('templates', editData.id, dataSave);
    } else {
      const isTitleExists = data.some(
        (item) => item.name.trim() === name.trim()
      );

      if (isTitleExists) {
        // setIsLoading(false);
        return toast({
          title: 'Admin',
          description: 'Name Already In Use',
          status: 'error',
        });
      }
      await addDocumentFirebase(
        'templates',
        dataSave,
        globalState.currentCompany
      );
    }

    getData();
    setName('');
    setShareLink('');
    setHtmlContent('');
    setTemplateId('');
    onClose();
  };

  const handleSwitchChange = () => {
    setFormData({ ...formData, publish: !formData.publish });
  };

  useEffect(() => {
    getData();
    getAccessToken();
  }, [
    currentPage,
    itemsPerPage,
    getCollectionFirebase,
    globalState.currentCompany,
  ]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Template Creatomate</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <Flex gap={3} direction={['column-reverse', 'row', 'row']} mb={3}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Search Name"
                onChange={(event) => setSearchValue(event.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => getData()}>
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>
            <Spacer />
            <Stack spacing={2}>
              <DynamicButton
                size={'md'}
                action="create"
                title={'Create New'}
                onClick={() => onOpen(null)}
              />
            </Stack>
          </Flex>
          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name </Th>
                  <Th>Video </Th>
                  <Th>Templated Id </Th>
                  <Th>Publish</Th>
                  <Th>Created At </Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      <HStack>
                        <Stack>
                          <Text
                            // textTransform={'capitalize'}
                            fontWeight={'bold'}
                            fontSize="sm"
                          >
                            {x?.name}
                          </Text>
                          <Text fontSize="3xs">{x?.id}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td fontSize={'sm'} width={'350px'}>
                      {x?.link && (
                        <video controls width="50%" height="80">
                          <source src={x?.link} type="video/mp4" />
                          Maaf, browser Anda tidak mendukung tag video.
                        </video>
                      )}
                    </Td>
                    <Td fontSize={'sm'}>
                      {/* {x?.templateId?.substring(0, 10) + "..."} */}
                      <Popover isLazy>
                        <PopoverTrigger>
                          <Box _hover={{ cursor: 'pointer' }}>
                            {x?.templateId?.substring(0, 10) + '...'}
                          </Box>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverHeader fontWeight="semibold">
                            Template Id
                          </PopoverHeader>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody>{x?.templateId}</PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Td>
                    <Td>
                      <Switch
                        size="md"
                        onChange={() => handleSwitchChangePush(i, x.id)}
                        isChecked={x?.publish}
                      />
                    </Td>
                    <Td fontSize={'sm'}>
                      {/* {formatDateFirestore(x.createdAt)} */}
                      {moment(x?.createdAt?.seconds * 1000).format('LLLL')}
                    </Td>

                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <DynamicButton
                          size="sm"
                          action="update"
                          onClick={() => onOpen(x)}
                        />
                        <DynamicButton
                          size="sm"
                          action="delete"
                          isLoading={loadingStates[i]}
                          onClick={() => deleteTemplate(x.id, i)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack alignItems={'center'} justifyContent="center" mt={3}>
            {currentPage < totalPages && (
              <Button
                colorScheme={'green'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Stack>
        </Box>

        <Modal isOpen={isOpen} onClose={handleCloseModal} size={'3xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex w={'100%'} gap={5} direction={['column', 'row', 'row']}>
                <Box
                  w={['100%', '50%', '50%']}
                  bgColor={'white'}
                  p={5}
                  borderRadius="md"
                  shadow={'base'}
                >
                  <FormControl mb="4">
                    <FormLabel>Tamplate Creatomate</FormLabel>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>FIle Link</FormLabel>
                    <Text>
                      <a
                        href={shareLink?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Text>{shareLink?.link}</Text>
                      </a>
                    </Text>
                  </FormControl>

                  <Button
                    onClick={openModal}
                    colorScheme={'green'}
                    variant="outline"
                  >
                    Upload
                  </Button>

                  {shareLink && (
                    <Button
                      onClick={() => {
                        setShareLink('');
                        setHtmlContent('');
                      }}
                      colorScheme={'red'}
                      ml={3}
                      variant="outline"
                    >
                      Delete
                    </Button>
                  )}
                </Box>

                <Box
                  p={5}
                  w={['100%', '50%', '50%']}
                  bg={'white'}
                  shadow={'base'}
                >
                  <FormControl mb="4">
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Template id</FormLabel>
                    <Input
                      type="text"
                      value={templateId}
                      onChange={(e) => setTemplateId(e.target.value)}
                      required
                    />
                  </FormControl>

                  <FormControl mb="4">
                    <FormLabel>Rasio</FormLabel>
                    <Select
                      value={formData.rasio}
                      onChange={(e) =>
                        setFormData({ ...formData, rasio: e.target.value })
                      }
                      required
                    >
                      <option value="1:1">1:1 (480 x 480 px)</option>
                      <option value="9:16">9:16 (270 x 480 px)</option>
                      <option value="16:9">16:9 (480 x 270 px)</option>
                    </Select>
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Publish</FormLabel>
                    <Switch
                      size="md"
                      onChange={handleSwitchChange}
                      isChecked={formData.publish}
                    />
                  </FormControl>
                </Box>
              </Flex>

              <HStack
                mt="10"
                mb="5"
                display="flex"
                justifyContent="end"
                spacing={2}
              >
                <DynamicButton
                  size={'md'}
                  action="custom"
                  color="red"
                  title={'Cancel'}
                  // onClick={handleCloseModal}
                />
                <DynamicButton
                  variant={'solid'}
                  size={'md'}
                  action="create"
                  title={'Submit'}
                  onClick={() => onSubmitModal()}
                />
              </HStack>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>

        <DropboxUploader
          isActive={isModalOpen}
          onClose={closeModal}
          parentPath={`/${globalState?.currentCompany}/${globalState?.currentProject}/template-marketer`}
          shareLink={shareLink}
          setShareLink={handleShareLinkChange}
          accessTokenDb={tokenDropBox}
          rasio={formData.rasio}
        />
      </Box>
    </>
  );
}

export default CreatomatePage;
