/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Text,
  ScaleFade,
  Stack,
} from '@chakra-ui/react';

function AlertConnection() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000); // Menghilangkan alert setelah 2 detik
    };
    const handleOffline = () => {
      setIsOnline(false);
      setShowAlert(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Stack>
      {showAlert && (
        <ScaleFade in={showAlert} initialScale={0.9}>
          {isOnline ? (
            <Alert
              display={showAlert ? 'flex' : 'none'}
              status="success"
              h={['30px', '30px', '40px']}
              overflowX="auto"
            >
              <AlertIcon />
              <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
              Connection! 📶
              </AlertTitle>
              <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                <Text>Connection established.</Text>
              </AlertDescription>
            </Alert>
          ) : (
            <Alert status="error" h={['30px', '30px', '40px']} overflowX="auto">
              <AlertIcon />
              <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
              Connection! 📢
              </AlertTitle>
              <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                <Text>
                Unstable internet connection. Please check your connection.
                </Text>
              </AlertDescription>
            </Alert>
          )}
        </ScaleFade>
      )}
    </Stack>
  );
}

export default AlertConnection;
