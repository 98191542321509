import React from 'react'
import CrmIndex from '../Pages/Crm/CrmIndex';
import ConfigChatPage from '../Pages/Crm/ConfigChat/ConfigChatPage';

const CrmRouter = [
  {
    path: '/crm',
    element: <CrmIndex />,
  },
  {
    path: '/crm/config-chat',
    element: <ConfigChatPage />,
  },
];

export default CrmRouter;
