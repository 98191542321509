/* eslint-disable no-undef */
import {
  Box,
  Button,
  Container,
  Heading,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Divider,
  HStack,
  Spacer,
  SimpleGrid,
  Image,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';
import PricingPage from '../Payment/PricingPage';
import axios from 'axios';
import { formatFrice } from '../../Utils/Helper';
import { useNavigate } from 'react-router-dom';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import _axios from '../../Apis/AxiosBarrier';

function MembershipPaymentPage() {
  const [value, setValue] = useState('credit card');
  const [companyData, setCompanyData] = useState([]);
  const globalState = useUserStore();
  const [externalId, setExternalId] = useState('');
  const [features, setFeatures] = useState({
    id: 'p_1500k',
    value: 0,
    sales: 1500000,
    // sales: 10000,
    customers: 1000,
    hr: 10,
    warehouse: 10000,
    outlet: 3,
    price: 1500000,
    // price: 10000,
  });
  const [payData, setPayData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [discount, setDiscount] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedPaymentMethodImage, setSelectedPaymentMethodImage] =
    useState('');

  const apikey = process.env.REACT_APP_PAYMENT_KEY;

  const banks = [
    {
      name: 'BNI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
    },
    {
      name: 'MANDIRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
    },
    {
      name: 'PERMATA',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
    },
    {
      name: 'BRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
    },
  ];

  const getData = () => {
    const cuid = globalState?.currentCompany;
    const getdataCompany = globalState?.companies?.find((x) => x.id === cuid);
    setCompanyData(getdataCompany);
    setDiscount(getdataCompany?.discount?.discountRate || 0);
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Announce',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  const handlePay = async () => {
    setIsLoad(true);
    const paramPay = {
      is_production: true,
      package_code: features.id,
      company_uid: globalState?.currentCompany,
      user_uid: globalState?.uid,
      type: 'va',
      bank: value,
      discount_fee: discount || 0,
    };
    // console.log("handle pay", paramPay);
    try {
      const url = 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/membershipCreateAndPay';
      const response = await axios.post(url, paramPay, {
        headers: { Authorization: apikey },
      });
      setIsLoad(false);
      if (response.data.status) {
        const saveSubscription = response.data.data;
        saveSubscription.company_id = companyData.id;
        saveSubscription.company_name = companyData.name;
        await setDocumentFirebase(
          'subscription',
          saveSubscription.external_id,
          saveSubscription
        );

        setExternalId(saveSubscription?.external_id);

        if (value === 'CREDIT_CARD') {
          window.open(response?.data?.data?.invoice_url, '_blank');
        } else {
          setPayData(saveSubscription);
        }
      } else {
        return toast({
          status: 'warning',
          duration: 9000,
          title: response.data.message,
          isClosable: true,
        });
      }
      // return response.data;
    } catch (error) {
      setIsLoad(false);
      return toast({
        status: 'warning',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  };

  const handlePaymentMethodSelect = (method) => {
    setValue(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(
      imageFind?.uri || 'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
    );
  };

  const handleReload = async () => {
    const res = await getSingleDocumentFirebase('subscription', externalId);

    try {
      if (res?.status === 'PAID') {
        if (companyData?.discount) {
          await _axios.post('tapfiliate-postData', {
            param: 'conversions/',
            data: {
              external_id: externalId || '',
              coupon: companyData?.coupon,
              amount: features?.price - features?.price * (discount / 100),
              currency: 'IDR',
              meta_data: {
                name: globalState?.name,
                email: globalState?.email,
                company: companyData?.name,
              },
            },
          });

        }
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      } finally {
      navigate('/');
      window.location.reload();
    }
  };

  const handleCancelPay = () => {
    setPayData({});
  };

  useEffect(() => {
    getData();
    return () => {
      setCompanyData([]);
    };
  }, [globalState?.currentCompany]);

  return (
    <Container
      py={3}
      size={'5xl'}
      w="100%"
      minH={'100vh'}
      alignItems="center"
      justifyContent={'center'}
    >
      <Stack>
        <Stack>
          <Heading size={'lg'}>Make Payment </Heading>
          <Heading fontWeight={'extrabold'}>{companyData?.name}</Heading>
          <Divider />
        </Stack>
        <HStack>
          <Text fontWeight={'bold'} color="gray.700">
            Users Access
          </Text>
          <Spacer />
          <Text fontWeight={'bold'}>{companyData?.users?.length} Users</Text>
        </HStack>

        {companyData?.discount && (
          <HStack>
            <Text fontWeight={'bold'} color="gray.700">
              Coupon
            </Text>
            <Spacer />
            <Text
              fontWeight={'bold'}
            >{`${companyData?.discount?.title} `}</Text>
          </HStack>
        )}

        <HStack>
          <Text fontWeight={'bold'} color="gray.700">
            Discount
          </Text>
          <Spacer />
          <Text fontWeight={'bold'}>{discount && discount}%</Text>
        </HStack>

        <HStack>
          <Spacer />
          <Text
            color={'red.500'}
            fontStyle="italic"
            fontSize={'sm'}
            fontWeight={500}
          >
            Expired {moment(companyData?.expired_at).fromNow()}
          </Text>
        </HStack>
        <Divider />
        <PricingPage
          features={features}
          setFeatures={setFeatures}
          discount={discount || 0}
        />
        {payData.external_id === undefined ? (
          <>
            <RadioGroup
              value={value}
              onChange={handlePaymentMethodSelect}
              m="2"
            >
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <Text fontWeight={500} fontSize="sm">
                    Choose Virtual Account :
                  </Text>
                  <SimpleGrid columns={[2, null, 4]} align="start">
                    {banks?.map((x, index) => (
                      <Radio key={index} value={x.name}>
                        <Image src={x?.uri} w="80px" />
                      </Radio>
                    ))}
                  </SimpleGrid>
                </Stack>

                <Stack spacing={2}>
                  <Text fontWeight={500} fontSize="sm">
                    Choose Credit Card :
                  </Text>
                  <Radio value={'CREDIT_CARD'}>
                    <Image
                      src={
                        'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
                      }
                      w="90px"
                    />
                  </Radio>
                </Stack>
              </Stack>
            </RadioGroup>
            {isLoad ? (
              <Button w="full" colorScheme="blue" isLoading>
                Pay
              </Button>
            ) : (
              <Button w="full" colorScheme="blue" onClick={() => handlePay()}>
                Pay
              </Button>
            )}
          </>
        ) : (
          <>
            <Stack>
              <HStack w="full" px={5}>
                <Image
                  src={selectedPaymentMethodImage}
                  alt={payData?.bank_code}
                  w="80px"
                  borderRadius="xl"
                />

                <Spacer />

                <Text
                  fontSize="sm"
                  fontWeight={'bold'}
                  color={
                    payData?.status === 'pending' ? 'red.600' : 'green.600'
                  }
                  textTransform="uppercase"
                >
                  {payData?.status}
                </Text>
              </HStack>

              <Box bg="white" px={5}>
                <Text>No. Virtual Account : </Text>
                <Divider my={2} />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Text fontSize={20} color="gray.500">
                    {payData ? payData?.account_number : 'none'}
                  </Text>
                  <Text
                    color="blue.600"
                    cursor="pointer"
                    onClick={() => handleCopy(payData?.account_number)}
                  >
                    SALIN
                  </Text>
                </Box>
                <Divider my={2} />
                <HStack py={1}>
                  {/* <Text fontSize="sm" textTransform={'uppercase'}>{store}</Text> */}
                  <Spacer />
                  <Text fontSize="sm">
                    Rp. {formatFrice(payData?.expected_amount)}
                  </Text>
                </HStack>
                <Divider my={2} />

                <Text fontSize={10} color="gray.600">
                  Proses verifikasi otomatis kurang dari 10 menit setelah
                  pembayaran berhasil
                </Text>
                <Spacer />
                <Text fontSize={10} color="gray.600">
                  Bayar ke Virtual Account di atas sebelum membuat donasi baru
                  dengan Virtual account agar nomor tetap sama.
                </Text>
              </Box>
              <Box bg="white" p={5}>
                <Text fontSize={10} color="gray.600">
                  Petunjuk Transfer mBanking :
                </Text>
                <Divider />
                <Text fontSize={10} color="gray.600">
                  1. Login ke mBanking-mu, pilih Bayar, kemudian cari Xendit /{' '}
                  {payData.merchant_code} Virtual Account
                </Text>
                <Text fontSize={10} color="gray.600">
                  2. Masukkan nomor Virtual Account
                </Text>
                <Text fontSize={10} color="gray.600">
                  3. Pastikan nama dan nominal bayar benar
                </Text>
                <Text fontSize={10} fontWeight={500} color="red.500">
                  4. Jika kamu sudah melakukan pembayaran, klik tombol done
                </Text>
              </Box>

              <HStack alignItems="center" justifyContent="center">
                <Box>
                  <DynamicButton
                    action={'delete'}
                    title="Cancel"
                    variant={'solid'}
                    onClick={() => handleCancelPay()}
                  />
                </Box>

                <Box>
                  <DynamicButton
                    action={'create'}
                    title="Done"
                    variant={'solid'}
                    onClick={() => handleReload()}
                  />
                </Box>
              </HStack>
            </Stack>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default MembershipPaymentPage;
