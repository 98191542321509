/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { IoCloseSharp } from 'react-icons/io5';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase, getCollectionFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';

const CouponCreate = ({
  isOpen,
  onClose,
  editData,
  resSave,
}) => {
  const toast = useToast();
  const globalState = useUserStore();
  const [formData, setFormData] = useState({
    discountRate: '',
    title: '',
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        title: editData.title || '',
        discountRate: editData.discountRate || '',
      });
    } else {
      setFormData({
        title: '',
        discountRate: ''
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isValidDiscountRate = (discountRate) => {
    const rate = parseFloat(discountRate);
    return !isNaN(rate) && rate >= 1 && rate <= 100;
  };

  const isValidTitle = (title) => {
    return !/\s/.test(title);
  };

  const onSubmit = async () => {
    try {
      if (!isValidDiscountRate(formData.discountRate)) {
        return toast({
          title: 'Admin',
          description: 'Coupon rate discount must be between 1 and 100',
          status: 'error',
        })
      }
    
      if (!isValidTitle(formData.title)) {
        return toast({
          title: 'Admin',
          description: 'Title cannot contain spaces',
          status: 'error',
        })
      }

      globalState.setIsLoading(true);

      if (editData) {
        await updateDocumentFirebase('coupons', editData.id, formData);
      } else {
        const res = await getCollectionFirebase( 'coupons', [],);
        const isTitleExists = res.some((item) => item.title === formData.title);

        if (isTitleExists) {
          return toast({
            title: 'Admin',
            description: 'Title coupon already exists',
            status: 'error',
          });
        }
        await addDocumentFirebase( 'coupons', formData, globalState.currentCompany);
      }
      globalState.setIsLoading(false);
      setFormData({
        discountRate: '',
        title: '',
      });
      resSave();
      onClose();
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb="4">
            <FormControl mb="4">
              <FormLabel>Rate Discount (1% - 100%):</FormLabel>
              <Input
                type="number"
                min={1}
                max={100}
                value={formData.discountRate}
                name="discountRate"
                onChange={handleChange}
                required
              />
            </FormControl>

            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </FormControl>

          {/* Buttons for cancel and submit */}
          <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
            <DynamicButton
              size={'sm'}
              action="custom"
              color={'red'}
              icon={IoCloseSharp}
              title={'Cancel'}
              onClick={onClose}
            />
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={onSubmit}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CouponCreate;
