import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import RichTextEditor from '../../Components/Quill/RichTextEditor';
import useUserStore from '../../Hooks/zustand/Store';

function LandingPageQA() {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState([]);

  const handleChange = (
    departementIndex,
    field,
    value,
    subDivisiIndex,
    divisiIndex
  ) => {
    // return console.log(departementIndex, field, value, subDivisiIndex, divisiIndex, "ddddd")
    setFormData((prevFormData) => {
      const updatedDivisi = [...prevFormData];
      if (field === 'question') {
        updatedDivisi[departementIndex].question = value;
      } else if (field === 'answer') {
        updatedDivisi[departementIndex].answer = value;
      }
      return updatedDivisi;
    });
  };

  const removeDepartement = (divisiIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData.splice(divisiIndex, 1);
      return updatedFormData;
    });
  };

  const handleContentChange = (value, departmentIndex) => {
    handleChange(departmentIndex, 'answer', value);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const dataSend = {
        data: formData,
      };
      await setDocumentFirebase('landing_page', 'questionAndAnswer', dataSend);
      setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const addDepartement = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        question: '',
        answer: '',
      },
    ]);
  };

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase(
        'landing_page',
        'questionAndAnswer'
      );
      setFormData(res.data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Home Landing Question And Answer</Heading>
          <Spacer />
          <DynamicButton
            color="green"
            onClick={addDepartement}
            size="sm"
            action="create"
            title="Add Q&A"
          />
        </HStack>

        {formData.map((department, departmentIndex) => (
          <Box
            p="5"
            borderRadius="md"
            shadow="base"
            mt={5}
            key={departmentIndex}
          >
            <Flex justifyContent="flex-end" alignItems="center">
              <DynamicButton
                size="md"
                action="delete"
                title="Delete Q&A"
                onClick={() => removeDepartement(departmentIndex)}
              />
            </Flex>
            <Flex w="100%" gap={5} direction={['column', 'row', 'row']}>
              {/* <Box w={['100%', '50%', '50%']} p={5} borderRadius="md" mt={16}> */}
              <FormControl mb="4">
                <FormLabel>Question:</FormLabel>
                <Flex>
                  <Input
                    type="text"
                    value={department.question}
                    onChange={(e) =>
                      handleChange(departmentIndex, 'question', e.target.value)
                    }
                  />
                </Flex>
              </FormControl>
              {/* </Box> */}
            </Flex>

            <Flex flexDirection={['column', 'row', 'row']} mb={50}>
              <Box flex="1">
                <Text>Answer :</Text>
                <RichTextEditor
                  value={department?.answer}
                  onChange={(value) =>
                    handleContentChange(value, departmentIndex)
                  }
                />
              </Box>
            </Flex>
          </Box>
        ))}

        <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
          <DynamicButton
            color="green"
            isLoading={isLoading}
            size="sm"
            action="custom"
            title="Submit"
            onClick={handleSave}
          />
        </HStack>
      </Box>
    </>
  );
}

export default LandingPageQA;
