/* eslint-disable no-unused-vars */
import {
  HStack,
  Heading,
  Spacer,
  useDisclosure,
  useToast,
  Stack,
  Box,
  Button,
  Container,
  Center,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import useUserStore from '../../../Hooks/zustand/Store';
import { countDocumentsFirebase, getCollectionFirebase } from '../../../Apis/firebaseApi';
import BackButtons from '../../../Components/Buttons/BackButtons';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import CategoriesDrawer from './Components/CategoriesDrawer';
import ListComponent from './Components/ListComponent';
  
function CategoriesPage() {
  const navigate = useNavigate();
  
  // initial data
  const globalState = useUserStore();
  const [data, setData] = useState({categories: [], formData: {}, totalDoc:0});
  const [isLoading, setIsLoading] = useState(false);
  
  // Keperluan Data dal Filtering
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 10;
  
  // conditional session
  const drawerSaveCategory = useDisclosure();
  const toast = useToast()


  // handle open drawer
  const handleDetailCategories = (value) => {
    setData((prev) => ({...prev, formData: value}))
    drawerSaveCategory.onOpen()
  };
  

  const getData = async () => {
    try {
      const limitValue = startIndex + itemsPerPage;
      const resCategories = await getCollectionFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: globalState?.currentCompany }], null, limitValue, null);
      const resTotalDoc = await countDocumentsFirebase('sop_categories', [{ field: 'companyId', operator: '==', value: globalState?.currentCompany }])
      setData((prev) => ({...prev, categories: resCategories, totalDoc: resTotalDoc}))
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };
  
  useEffect(() => {
    getData()
  }, []);
  
  return (
    <>
      <Stack w='full' spacing={5} p={[1, 1, 5]}>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <HStack>
            <BackButtons />
            <Heading size='md' textTransform='capitalize'>
                Kategori
            </Heading>
          </HStack>
          <Spacer />
          <DynamicButton
            title={'Tambah Kategori'}
            action={'create'}
            variant={'solid'}
            onClick={() =>  {setData((prev) => ({...prev, formData: {}})); drawerSaveCategory.onOpen()}}
          />
        </Stack>
        <Box position='relative'>
          {data?.categories?.length > 0 ? (<>
            <Box width='auto' p={5} borderRadius="20px"
              borderWidth={0.5}  shadow={'base'} >
              <Stack gap={2}>
                {data?.categories?.map((category, i) => { return(
                  <Box key={i}>
                    <ListComponent title={category?.name} tags={category?.tags} onShowDetail={() => handleDetailCategories(category)}/>
                  </Box>
                )
                })}
              </Stack>
            
            </Box></>) : (<><Center><Text>Tidak Ada data</Text></Center></>)}
        
          {data?.totalDoc < (startIndex + itemsPerPage) ? <></> :  <Center mt={1}>
            <Button
              colorScheme={'blue'}
              fontSize="sm"
              onClick={handleLoadMore}
            >
              {'Load more'}
            </Button>
          </Center>}
        </Box>
  
        <CategoriesDrawer
          getData={() => getData()}
          isOpen={drawerSaveCategory.isOpen}
          onClose={drawerSaveCategory.onClose}
          data={data}
          setData={setData}
        />
      </Stack>
    </>
  );
}
  
export default CategoriesPage;
  

  