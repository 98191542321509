/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  DrawerFooter,
  Select,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import DynamicButton from '../../../../Components/Buttons/DynamicButton';
import { IoCloseCircle } from 'react-icons/io5';
import useUserStore from '../../../../Hooks/zustand/Store';
import { addDocumentFirebase, setDocumentFirebase } from '../../../../Apis/firebaseApi';
function CategoriesDrawer({isOpen, onClose, data, setData, getData}) {
  // initial state
  const globalState = useUserStore()
  // conditional session
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)


  // handle save data to crm_product_categories
  const handleSaveData = async () => {
    setIsLoading(true)
    try {
      const newData ={
        ...data?.formData,
        companyId : globalState?.currentCompany,
        projectId : globalState?.currentProject,
      };
      if(data?.formData?.id) {
        await setDocumentFirebase('sop_categories', data?.formData?.id, newData )
      } else {
        await addDocumentFirebase(
          'sop_categories',
          newData,
          globalState?.currentCompany
        )
      }
      toast({
        title : 'Success',
        description : 'Kategori berhasil ditambahkan',
        isClosable : true,
        duration : 3000,
        status : 'success'
      })
      getData()
      setIsLoading(false)
      onClose()
    } catch (error) {
      console.log(error.message)
      setIsLoading(false)
      toast({
        title : 'Oops!',
        description : error.message,
        isClosable : true,
        duration : 3000,
        status : 'error'
      })
    }
  }

  const handleAddTags = (value) => {
    if(data?.formData?.tags?.find((x) => x === value) !== undefined) return   toast({
      title: 'Pemberitahuan',
      description: 'Kategori sudah ditambahkan',
      position: 'top-right',
      duration: 3000,
      status: 'info',
      isClosable: true,
    });

    const existingFeatures = Array.isArray(data?.formData?.tags) ? data?.formData?.tags : [];
    const newFeatureList = [...existingFeatures, value];
    setData((prev) =>  ({
      ...prev,
      formData: {
        ...prev.formData,
        tags: newFeatureList
      }
    }))

  };

  const handleDeleteTags = (index) => {
    const updatedFeatures = [...data?.formData?.tags];
    updatedFeatures.splice(index, 1);
    setData((prev) =>  ({
      ...prev,
      formData: {
        ...prev.formData,
        tags: updatedFeatures
      }
    }))
  }


  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Kategori</DrawerHeader>

        <DrawerBody>
          <FormControl>
            <FormLabel>Nama kategori</FormLabel>
            <Input
              type="text"
              onChange={(e) =>
                setData((prev) => ({...prev, formData: {...prev.formData, name: e.target.value}}) )
              }
              defaultValue={data?.formData?.name}
            />
          </FormControl>
          <FormControl>
            <FormLabel mt="8px">Tags</FormLabel>
            <Select  maxW={{
              md: '4xl',
            }}
            placeholder="Tags Kategori"  onChange={(e) => {
              handleAddTags(e.target.value)
            }
            }>
              <option value={'sop'}>SOP</option>
              <option value={'kpi'}>KPI</option>
              <option value={'okr'}>OKR</option>
            </Select>
            <HStack mt={3}> 
              <Text fontWeight={'medium'}>Tags :</Text>  
              <Flex wrap={'wrap'} gap={2}>
                {data?.formData?.tags?.map((tag, i) => (
                  <Box key={i} pos={'relative'} borderRadius={'md'} shadow={'base'} px={2}>
                    <Text>{tag}</Text>
                    <Box pos={'absolute'} top={0} right={-1} cursor={'pointer'} onClick={() => handleDeleteTags(i)}>
                      <IoCloseCircle color='red' size={13} />
                    </Box>
                  </Box>
                ))}
              </Flex>
            </HStack>
          </FormControl>
         
        
       
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <DynamicButton isLoading={isLoading} action={'create'} title={'Save'} variant={'solid'} onClick={() => handleSaveData()}/>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
CategoriesDrawer.propTypes={
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func
}
export default CategoriesDrawer