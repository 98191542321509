export const moduleOption = () => {
  const data = [
    { name: 'Management', value: 'management' },
    { name: 'Hris', value: 'hris' },
    { name: 'Chat', value: 'chat' },
    { name: 'Marketing', value: 'marketing' },
    { name: 'LP Builder', value: 'lp-builder' },
    { name: 'Mind Map', value: 'mind-map' },
    { name: 'SOP', value: 'sop' },
    { name: 'Social Media', value: 'socialmedia' },
    { name: 'CRM', value: 'crm' },
    { name: 'Research', value: 'research' },
    { name: 'Integrations', value: 'integrations' },
  ];
   
  return data;
}

export const airbyteOption = () => {
  const data = [
    {
      'source_name': 'activecampaign',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'adjust',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'aha',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'aircall',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'airtable',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'alpha-vantage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'amazon-ads',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'amazon-seller-partner',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'amazon-sqs',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'amplitude',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'apify-dataset',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'appfollow',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'apple-search-ads',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'appsflyer',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'asana',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'ashby',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'auth0',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'aws-cloudtrail',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'azure-blob-storage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'azure-table',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'babelforce',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'bamboo-hr',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'bigquery',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'bing-ads',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'braintree',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'braze',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'breezometer',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'callrail',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'captain-data',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'cart',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'chargebee',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'chargify',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'chartmogul',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'clickhouse',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'clickup-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'clockify',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'close-com',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'cockroachdb',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'coda',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'coin-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'coingecko-coins',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'coinmarketcap',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'commcare',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'commercetools',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'configcat',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'confluence',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'convertkit',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'convex',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'copper',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'datadog',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'datascope',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'db2',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'delighted',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'dixa',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'dockerhub',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'dremio',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'drift',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'dynamodb',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'e2e-test',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'elasticsearch',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'emailoctopus',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'everhour',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'exchange-rates',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'facebook-marketing',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'facebook-pages',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'faker',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'fastbill',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'fauna',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'file',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'firebase-realtime-database',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'firebolt',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'flexport',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'freshcaller',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'freshdesk',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'freshsales',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'freshservice',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'fullstory',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gainsight-px',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gcs',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'genesys',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'getlago',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'github',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gitlab',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'glassfrog',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gnews',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'ginee',
      'connector_type': 'ginee'
    },
    {
      'source_name': 'gocardless',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gong',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-ads',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-analytics-data-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-analytics-v4-service-account-only',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-analytics-v4',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-directory',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-drive',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-pagespeed-insights',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-search-console',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-sheets',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-webfonts',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'google-workspace-admin-reports',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'greenhouse',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gridly',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'gutendex',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'harvest',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'hellobaton',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'hubplanner',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'hubspot',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'insightly',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'instagram',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'instatus',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'intercom',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'intruder',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'ip2whois',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'iterable',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'jira',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'k6-cloud',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'kafka',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'klarna',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'klaus-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'klaviyo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'kyriba',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'kyve',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'launchdarkly',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'lemlist',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'lever-hiring',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'linkedin-ads',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'linkedin-pages',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'lokalise',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'looker',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailchimp',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailerlite',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailersend',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailgun',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailjet-mail',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mailjet-sms',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'marketo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'merge',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'metabase',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'microsoft-dataverse',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'microsoft-onedrive',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'microsoft-sharepoint',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'microsoft-teams',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mixpanel',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'monday',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mongodb-internal-poc',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mongodb-v2',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mssql',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'my-hours',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'mysql',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'n8n',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'nasa',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'netsuite',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'news-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'newsdata',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'notion',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'nytimes',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'okta',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'omnisend',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'onesignal',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'open-exchange-rates',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'openweather',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'opsgenie',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'oracle',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'orb',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'orbit',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'oura',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'outbrain-amplify',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'outreach',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pardot',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'partnerstack',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'paypal-transaction',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'paystack',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pendo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'persistiq',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pexels-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pinterest',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pipedrive',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pivotal-tracker',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'plaid',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'plausible',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pocket',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pokeapi',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'polygon-stock-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'postgres',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'posthog',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'postmarkapp',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'prestashop',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'primetric',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'public-apis',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'punk-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'pypi',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'qualaroo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'quickbooks',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'railz',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'rd-station-marketing',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'recharge',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'recreation',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'recruitee',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'redshift',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'reply-io',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'retently',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'ringcentral',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'rki-covid',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'rocket-chat',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'rss',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 's3',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'salesforce',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'salesloft',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sap-fieldglass',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'secoda',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sendgrid',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sendinblue',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'senseforce',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sentry',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'serpstat',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sftp-bulk',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sftp',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'shopify',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'shortio',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'slack',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'smaily',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'smartengage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'smartsheets',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'snapchat-marketing',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'snowflake',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'sonar-cloud',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'spacex-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'square',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'statuspage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'strava',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'stripe',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'survey-sparrow',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'surveycto',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'surveymonkey',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tempo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'teradata',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'the-guardian-api',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tidb',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tiktok-marketing',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'timely',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tmdb',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'todoist',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'toggl',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tplcentral',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'trello',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'trustpilot',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tvmaze-schedule',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'twilio-taskrouter',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'twilio',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'twitter',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'tyntec-sms',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'typeform',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'unleash',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'us-census',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'vantage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'visma-economic',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'vitally',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'waiteraid',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'weatherstack',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'webflow',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'whisky-hunter',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'wikipedia-pageviews',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'woocommerce',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'workable',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'workramp',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'wrike',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'xero',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'xkcd',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'yahoo-finance-price',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'yandex-metrica',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'yotpo',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'younium',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'youtube-analytics',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zapier-supported-storage',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zendesk-chat',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zendesk-sunshine',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zendesk-support',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zendesk-talk',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zenefits',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zenloop',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zoho-crm',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'zoom',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'farosai/airbyte-customer-io-source',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'farosai/airbyte-harness-source',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'farosai/airbyte-jenkins-source',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'farosai/airbyte-pagerduty-source',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'farosai/airbyte-victorops-source',
      'connector_type': 'airbyte'
    },
    {
      'source_name': 'whatsapp',
      'connector_type': 'maxchat'
    },
    {
      'source_name': 'payment-gateway',
      'connector_type': 'xendit'
    },
    {
      'source_name': 'crm',
      'connector_type': 'qontak'
    },
    {
      'source_name': 'accurate',
      'connector_type': 'cpssoft'
    },
    {
      'source_name': 'aws-storage',
      'connector_type': 'aws'
    },
    {
      'source_name': 'aws-email',
      'connector_type': 'aws'
    },
    {
      'source_name': 'dropbox-storage',
      'connector_type': 'dropbox'
    },
    {
      'source_name': 'midtrans-payment-gateway',
      'connector_type': 'payment-gateway'
    },
    {
      'source_name': 'xendit-payment-gateway',
      'connector_type': 'payment-gateway'
    }
  ]
  return data;
}