/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Heading,
  Text,
  HStack,
  Stack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Center,
  VStack,
  Spacer,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { formatFrice } from '../../../Utils/Helper';
import moment from 'moment';
  
  
function ModalBillingDetail({
  isOpen,
  onClose,
  dataResult,
}) {
   
  
  return (
    <Modal
      isOpen={isOpen}
      blockScrollOnMount={false}
      size={'xl'}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent transform="translateY(-50%)" top="5%" shadow="xl">
        <ModalHeader>
          <Center>Billing</Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={'70vh'} overflowY="scroll">
          <VStack justifyContent={'center'} p={5}>
  
            <VStack justifyContent={'center'} p={5}>
              <Heading align={'center'} size="md">
                    Terima Kasih sudah melakukan order
              </Heading>
              <Text align={'center'}>
                    Berikut Detail Billing orderan anda
              </Text>
              <Stack
                w="full"
                p="4"
                borderColor="gray.300"
                borderWidth={1}
                rounded="md"
                spacing={2}
              >
                <Heading size="sm" mb="2" align="center">
                      Billing Detail
                </Heading>
                <Flex direction="column">
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Order Status:</Text>
                    <Spacer />
                    <Text textAlign="right">{dataResult?.transaction_status === 'settlement' ? 'Paid' : 'Unpaid'}</Text>
                  </HStack>

                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Order Paid:</Text>
                    <Spacer />
                    <Text textAlign="right">{moment(dataResult?.lastUpdated?.seconds * 1000).format('DD-MM-YYYY HH:mm')}</Text>
                  </HStack>
  
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Order ID:</Text>
                    <Spacer />
                    <Text textAlign="right">{dataResult?.order_id}</Text>
                  </HStack>
  
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Nama :</Text>
                    <Spacer />
                    <Text textAlign="right">{dataResult?.contact_information?.name}</Text>
                  </HStack>
  
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Nomor Hp :</Text>
                    <Spacer />
                    <Text textAlign="right">{dataResult?.contact_information?.phone_number}</Text>
                  </HStack>
  
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Durasi :</Text>
                    <Spacer />
                    <Text textAlign="right">{dataResult?.duration} Hari</Text>
                  </HStack>

                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">expiredApp :</Text>
                    <Spacer />
                    <Text textAlign="right">{moment(dataResult?.expiredApp?.seconds * 1000).format('DD-MM-YYYY HH:mm')}</Text>
                  </HStack>

                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">App ID:</Text>
                    <Spacer />
                    <Text textAlign="right" textTransform={'capitalize'}>
                      {dataResult?.appId}
                    </Text>
                  </HStack>
  
                  {dataResult?.payment_type && (
                    <HStack justifyContent="space-between" fontSize="sm" my={1}>
                      <Text fontWeight="bold">Payment Method:</Text>
                      <Spacer />
                      <Text textAlign="right" textTransform={'capitalize'}>
                        {dataResult?.payment_type}
                      </Text>
                    </HStack>
                  )}
                  <HStack justifyContent="space-between" fontSize="sm" my={1}>
                    <Text fontWeight="bold">Payment Type:</Text>
                    <Spacer />
                    <Text textAlign="right" textTransform={'uppercase'}>
                      {dataResult?.type}
                    </Text>
                  </HStack>
  
                  <Divider />
                  <HStack justifyContent="space-between" fontSize="sm" my={2}>
                    <Text fontWeight="bold">Total Price:</Text>
                    <Spacer />
                    <Text textAlign="right" fontWeight={700} fontSize={'lg'}>
                    Rp {formatFrice(Number(dataResult?.gross_amount || 0))}
                    </Text>
                  </HStack>
                </Flex>
  
                  
              </Stack>
  
              <Text my={5} align={'center'}>
                    Jangan lupa untuk menyimpan screenshot bukti pembayaran dan
                    order id
              </Text>
            </VStack>
  
  
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
  
export default ModalBillingDetail;
  