import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';

function LandingPageLink() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState([]);

  const handleChange = (
    departementIndex,
    field,
    value,
    subDivisiIndex,
    divisiIndex
  ) => {
    setFormData((prevFormData) => {
      const updatedDivisi = [...prevFormData];
      if (field === 'link') {
        updatedDivisi[departementIndex].link = value;
      }
      return updatedDivisi;
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const dataSend = {
        data: formData,
      };
      await setDocumentFirebase('landing_page', 'linkEmbed', dataSend);
      setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('landing_page', 'linkEmbed');
      setFormData(res.data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Home Landing Link Embed</Heading>
          <Spacer />
        </HStack>

        {formData.map((department, departmentIndex) => (
          <Box
            p="5"
            borderRadius="md"
            shadow="base"
            mt={5}
            key={departmentIndex}
          >
            <Flex w="100%" gap={2} direction={['column', 'row', 'row']}>
              {/* <Box w={['100%', '50%', '50%']} py={5} borderRadius="md"> */}
              <FormControl mb="4">
                <FormLabel>{department.title} :</FormLabel>
                <Flex>
                  <Input
                    type="text"
                    value={department.link}
                    onChange={(e) =>
                      handleChange(departmentIndex, 'link', e.target.value)
                    }
                  />
                </Flex>
              </FormControl>
              {/* </Box> */}
            </Flex>
          </Box>
        ))}

        <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
          <DynamicButton
            color="green"
            isLoading={isLoading}
            size="sm"
            action="custom"
            title="Submit"
            onClick={handleSave}
          />
        </HStack>
      </Box>
    </>
  );
}

export default LandingPageLink;
