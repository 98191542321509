import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  getCollectionFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
  updateDocumentFirebase,
} from '../../Apis/firebaseApi';
import { uploadImage } from '../../Apis/firebaseFunctions';
import useUserStore from '../../Hooks/zustand/Store';
import { moduleOption } from '../../Utils/dataUtils';

function LandingPagePricing() {
  const toast = useToast();
  const globalState = useUserStore();
  const [formData, setFormData] = useState({});
  const [companySelect, setCompanySelect] = useState('');
  const [projectSelect, setProjectSelect] = useState('');
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);

  const handleChange = (section, itemIndex, field, value, type) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (type === 'monthly') {
        updatedFormData[section][itemIndex].monthly[field] = value;
      } else if (type === 'annual') {
        updatedFormData[section][itemIndex].annual[field] = value;
      }
      return updatedFormData;
    });
  };

  const addEquipment = (section, itemIndex, type) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[section][itemIndex][type].equipment.push(''); // Tambahkan string kosong ke dalam array equipment
      return updatedFormData;
    });
  };

  const removeEquipment = (section, itemIndex, equipmentIndex, type) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[section][itemIndex][type].equipment.splice(
        equipmentIndex,
        1
      ); // Hapus item di indeks yang diberikan dari array equipment
      return updatedFormData;
    });
  };

  const handleEquipmentChange = (
    section,
    itemIndex,
    equipmentIndex,
    value,
    type
  ) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (type === 'monthly') {
        updatedFormData[section][itemIndex].monthly.equipment[equipmentIndex] =
          value;
      } else if (type === 'annual') {
        updatedFormData[section][itemIndex].annual.equipment[equipmentIndex] =
          value;
      }
      return updatedFormData;
    });
  };

  const handleSave = async () => {
    try {
      globalState.setIsLoading(true);
      const dataSend = {
        data: formData,
      };
      //  return console.log(dataSend, "ffff")
      await setDocumentFirebase('landing_page', 'pricing', dataSend);
      globalState.setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('landing_page', 'pricing');
      const sortBy = { field: 'name', direction: 'asc' };
      const getCompany = await getCollectionFirebase('product', [], sortBy);
      setCompanies(getCompany);
      setFormData(res.data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const handleCompanyChange = async (companyId, section, itemIndex, type) => {
    console.log(companyId);
    handleChange(section, itemIndex, 'productId', companyId, type);
    setCompanySelect(companyId);
    setProjectSelect(''); // Reset project when company changes
    if (companyId) {
      try {
        const conditions = [
          { field: 'productId', operator: '==', value: companyId },
        ];
        const sortBy = { field: 'name', direction: 'asc' };
        const getProjects = await getCollectionFirebase(
          'product_variants',
          conditions,
          sortBy
        );
        setProjects(getProjects);
      } catch (error) {
        console.log(`Error fetching projects: ${error}`);
      }
    } else {
      setProjects([]);
    }
  };

  const handleVariant = async (variantId, section, itemIndex, type) => {
    if (variantId) {
      try {
        const getProjects = await getSingleDocumentFirebase(
          'product_variants',
          variantId
        );
        handleChange(section, itemIndex, 'price', getProjects.price_int, type);
      } catch (error) {
        console.log(`Error fetching projects: ${error}`);
      }
    } else {
      setProjects([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Pricing Landing Page</Heading>
          <Spacer />
        </HStack>

        <Accordion allowToggle mt={8}>
          {Object.entries(formData).map(([section, testimonials], index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Pricing {section.charAt(0).toUpperCase() + section.slice(1)}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {testimonials.map((testimonial, itemIndex) => (
                  <Box p="5" borderRadius="md" shadow="base" key={itemIndex}>
                    <Flex w="100%" gap={5} direction={['column', 'row', 'row']}>
                      <Box w={['100%', '50%', '50%']} p={5} borderRadius="md">
                        <Text textAlign="center">Monthly</Text>
                        <FormControl mb="4">
                          <FormLabel>Product by CRM :</FormLabel>
                          <Flex>
                            <select
                              onChange={(e) =>
                                handleCompanyChange(
                                  e.target.value,
                                  section,
                                  itemIndex,
                                  'monthly'
                                )
                              }
                              value={testimonial.monthly.productId}
                              style={{ width: '100%', padding: '0.5rem' }}
                            >
                              <option value="" disabled={!companySelect}>
                                {companySelect
                                  ? companySelect
                                  : 'Select Products'}
                              </option>
                              {companies.map((company, index) => (
                                <option key={index} value={company.id}>
                                  {company.name}
                                </option>
                              ))}
                            </select>
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Variant By CRM :</FormLabel>
                          <Flex>
                            <select
                              onChange={(e) => {
                                handleVariant(
                                  e.target.value,
                                  section,
                                  itemIndex,
                                  'monthly'
                                );
                                setProjectSelect(e.target.value);
                                handleChange(
                                  section,
                                  itemIndex,
                                  'variantId',
                                  e.target.value,
                                  'monthly'
                                );
                              }}
                              value={testimonial.monthly.variantId}
                              style={{ width: '100%', padding: '0.5rem' }}
                              disabled={!companySelect} // Disable if no company is selected
                            >
                              <option value="" disabled={!projectSelect}>
                                {projectSelect
                                  ? projectSelect
                                  : 'Select Variant'}
                              </option>
                              {projects.map((project, index) => (
                                <option key={index} value={project.id}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </Flex>
                        </FormControl>

                        <FormControl mb="4">
                          <FormLabel>Link Redirect After Payment :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.monthly.link}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'link',
                                  e.target.value,
                                  'monthly'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Module :</FormLabel>
                          <Flex>
                            <Select
                              value={testimonial.monthly.module}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'module',
                                  e.target.value,
                                  'monthly'
                                )
                              }
                              required
                            >
                              {moduleOption().map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Title :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.monthly.title}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'title',
                                  e.target.value,
                                  'monthly'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Description :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.monthly.description}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'description',
                                  e.target.value,
                                  'monthly'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          {/* <FormLabel>Price : (<Text>*IDR</Text>)</FormLabel> */}
                          <FormLabel
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            Price{' '}
                            <Text fontSize="xs" color="tomato">
                              (*IDR)
                            </Text>{' '}
                            :{' '}
                          </FormLabel>
                          <Flex>
                            <Input
                              type="number"
                              disabled
                              value={testimonial.monthly.price}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'price',
                                  e.target.value,
                                  'monthly'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <Flex
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={3}
                        >
                          <DynamicButton
                            size="md"
                            action="create"
                            onClick={() =>
                              addEquipment(section, itemIndex, 'monthly')
                            }
                          />
                        </Flex>

                        <FormControl mb="4">
                          <FormLabel>Equipment :</FormLabel>
                          {testimonial.monthly.equipment.map(
                            (equipment, equipmentIndex) => (
                              <Flex key={equipmentIndex}>
                                <Input
                                  type="text"
                                  mb={2}
                                  value={equipment}
                                  onChange={(e) =>
                                    handleEquipmentChange(
                                      section,
                                      itemIndex,
                                      equipmentIndex,
                                      e.target.value,
                                      'monthly'
                                    )
                                  }
                                />
                                <DynamicButton
                                  size="md"
                                  action="delete"
                                  onClick={() =>
                                    removeEquipment(
                                      section,
                                      itemIndex,
                                      equipmentIndex,
                                      'monthly'
                                    )
                                  }
                                />
                              </Flex>
                            )
                          )}
                        </FormControl>
                      </Box>

                      <Box p={5} w={['100%', '50%', '50%']}>
                        <Text textAlign="center">Annual</Text>
                        <FormControl mb="4">
                          <FormLabel>Product by CRM :</FormLabel>
                          <Flex>
                            <select
                              onChange={(e) =>
                                handleCompanyChange(
                                  e.target.value,
                                  section,
                                  itemIndex,
                                  'annual'
                                )
                              }
                              value={testimonial.annual.productId}
                              style={{ width: '100%', padding: '0.5rem' }}
                            >
                              <option value="" disabled={!companySelect}>
                                {companySelect
                                  ? companySelect
                                  : 'Select Products'}
                              </option>
                              {companies.map((company, index) => (
                                <option key={index} value={company.id}>
                                  {company.name}
                                </option>
                              ))}
                            </select>
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Variant By CRM :</FormLabel>
                          <Flex>
                            <select
                              onChange={(e) => {
                                handleVariant(
                                  e.target.value,
                                  section,
                                  itemIndex,
                                  'annual'
                                );
                                setProjectSelect(e.target.value);
                                handleChange(
                                  section,
                                  itemIndex,
                                  'variantId',
                                  e.target.value,
                                  'annual'
                                );
                              }}
                              value={testimonial.annual.variantId}
                              style={{ width: '100%', padding: '0.5rem' }}
                              disabled={!companySelect} // Disable if no company is selected
                            >
                              <option value="" disabled={!projectSelect}>
                                {projectSelect
                                  ? projectSelect
                                  : 'Select Variant'}
                              </option>
                              {projects.map((project, index) => (
                                <option key={index} value={project.id}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Link Redirect After Payment :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.annual.link}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'link',
                                  e.target.value,
                                  'annual'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Link Payment :</FormLabel>
                          <Flex>
                            <Select
                              value={testimonial.annual.module}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'module',
                                  e.target.value,
                                  'annual'
                                )
                              }
                              required
                            >
                              {moduleOption().map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            {/* <Input
                              type="text"
                              value={testimonial.annual.link}
                              onChange={(e) => handleChange(section, itemIndex, 'link', e.target.value, 'monthly')}
                            /> */}
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Title :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.annual.title}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'title',
                                  e.target.value,
                                  'annual'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          <FormLabel>Description :</FormLabel>
                          <Flex>
                            <Input
                              type="text"
                              value={testimonial.annual.description}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'description',
                                  e.target.value,
                                  'annual'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb="4">
                          {/* <FormLabel>Price :</FormLabel> */}
                          <FormLabel
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            Price{' '}
                            <Text fontSize="xs" color="tomato">
                              (*IDR)
                            </Text>{' '}
                            :{' '}
                          </FormLabel>

                          <Flex>
                            <Input
                              type="number"
                              disabled
                              value={testimonial.annual.price}
                              onChange={(e) =>
                                handleChange(
                                  section,
                                  itemIndex,
                                  'price',
                                  e.target.value,
                                  'annual'
                                )
                              }
                            />
                          </Flex>
                        </FormControl>
                        <Flex
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={3}
                        >
                          <DynamicButton
                            size="md"
                            action="create"
                            onClick={() =>
                              addEquipment(section, itemIndex, 'annual')
                            }
                          />
                        </Flex>

                        <FormControl mb="4">
                          <FormLabel>Equipment :</FormLabel>
                          {testimonial.annual.equipment.map(
                            (equipment, equipmentIndex) => (
                              <Flex key={equipmentIndex}>
                                <Input
                                  type="text"
                                  mb={2}
                                  value={equipment}
                                  onChange={(e) =>
                                    handleEquipmentChange(
                                      section,
                                      itemIndex,
                                      equipmentIndex,
                                      e.target.value,
                                      'annual'
                                    )
                                  }
                                />
                                <DynamicButton
                                  size="md"
                                  action="delete"
                                  onClick={() =>
                                    removeEquipment(
                                      section,
                                      itemIndex,
                                      equipmentIndex,
                                      'annual'
                                    )
                                  }
                                />
                              </Flex>
                            )
                          )}
                        </FormControl>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
          <DynamicButton
            color="green"
            size="sm"
            action="custom"
            title="Submit"
            onClick={handleSave}
          />
        </HStack>
      </Box>
    </>
  );
}

export default LandingPagePricing;
