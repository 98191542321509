import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { MdSupervisorAccount } from 'react-icons/md';
import { getCollectionFirebase, getUsersByIds } from '../../../Apis/firebaseApi';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import { FaRegCalendar } from 'react-icons/fa';
import useUserStore from '../../../Hooks/zustand/Store';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import moment from 'moment';

function DashboardPage() {
  const globalState = useUserStore();
  const [companySelect, setCompanySelect] = useState('');
  const [projectSelect, setProjectSelect] = useState('');
  const [totalCardCreate, setTotalCardCreate] = useState(0);
  const [totalUsers, setTotalUsers] = useState('');
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getCompany = async (search) => {
    try {
      const conditions = [];
      const sortBy = { field: 'name', direction: 'asc' };
      const getCompany = await getCollectionFirebase('companies', conditions, sortBy);
      setCompanies(getCompany)
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const dates = useRef({
    startDate: moment().subtract(30, 'days').unix(),
    endDate: moment().unix(),
  });

  const onDateChange = (data) => {
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    
    startDate.setDate(startDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 2);

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];


    dates.current = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };

  const getData = async () => {
    try {
      if (!companySelect || !projectSelect) {
        toast({
          title: 'Deoapp.com',
          description: 'Please select both Company and Project',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
        return;
      }

      const { startDate, endDate } = dates.current;

      const startDateRes = new Date(startDate); 
      const endDateRes = new Date(endDate);

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const conditions = companySelect !== '' ? [
        { field: 'companyId', operator: '==', value: companySelect },
        { field: 'projectId', operator: '==', value: projectSelect },
        { field: 'createdAt', operator: '>=', value: startDateRes },
        { field: 'createdAt', operator: '<=', value: endDateRes },
      ] : [];

      globalState.setIsLoading(true);
      const res = await getCollectionFirebase('kanban', conditions, sortBy);
      const createdByValues = res.map((item) => item.createdBy);

      const frequencyCount = createdByValues.reduce((acc, value) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      const totalFrequency = Object.values(frequencyCount).reduce((total, count) => total + count, 0);
      setTotalCardCreate(totalFrequency)
      const uniqueCreatedByValues = Array.from(new Set(createdByValues));
      const usersData = await getUsersByIds(uniqueCreatedByValues);
      const usersDataWithTotalCard = usersData.map((user) => ({
        ...user,
        totalCard: frequencyCount[user.id] || 0,
      }));

      setTotalUsers(usersDataWithTotalCard);
      globalState.setIsLoading(false);
      setData(usersDataWithTotalCard);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleCompanyChange = async (companyId) => {
    setCompanySelect(companyId);
    setProjectSelect(''); // Reset project when company changes
    if (companyId) {
      try {
        const conditions = [{ field: 'companyId', operator: '==', value: companyId }];
        const sortBy = { field: 'name', direction: 'asc' };
        const getProjects = await getCollectionFirebase('projects', conditions, sortBy);
        setProjects(getProjects);
      } catch (error) {
        console.log(`Error fetching projects: ${error}`);
      }
    } else {
      setProjects([]);
    }
  };


  const handleReloadFilter = () => {
    // setCompanySelect("")
    // getData()
  }

  useEffect(() => {
    getCompany()
  }, []);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Dashboard</Heading>
          <Spacer />
        </HStack>

        <Box p={2} my={3} borderRadius={'md'} shadow={'base'}>
          <HStack>

            <Spacer />
            <select
              onChange={(e) => handleCompanyChange(e.target.value)}
              value={companySelect}
              style={{ width: '25%', padding: '0.5rem' }}
            >
              <option value="" disabled={!companySelect}>
                {companySelect ? companySelect : 'Select Company'}
              </option>
              {companies.map((company, index) => (
                <option key={index} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => setProjectSelect(e.target.value)}
              value={projectSelect}
              style={{ width: '25%', padding: '0.5rem' }}
              disabled={!companySelect} // Disable if no company is selected
            >
              <option value="" disabled={!projectSelect}>
                {projectSelect ? projectSelect : 'Select Project'}
              </option>
              {projects.map((project, index) => (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>

            <Button
              onClick={onOpen}
              bgColor={'transparent'}
              // shadow="md"
              variant="outline"
              borderColor="blue.600"
              color="blue.600"
            >
              <HStack>
                <FaRegCalendar />
                <Text>Filter date</Text>
              </HStack>
            </Button>

            <DynamicButton
              title={'Search'}
              action="custom"
              color={'blue'}
              onClick={() => {
                getData();
              }}
            />
            {/* <DynamicButton color={'blue'} action={'custom'} icon={TbReload} onClick={handleReloadFilter} /> */}
          </HStack>
        </Box>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <Stack overflowY="auto" spacing={5} w="full" mt={5}>
            <Stack>
              <HStack>
                <Text fontSize={'xl'} fontWeight='bold' color={'gray.600'}>Report</Text>
                <Text fontSize={'md'} color='gray.500' textTransform={'capitalize'}>( Kanban )</Text>
              </HStack>
            </Stack>
            <Stack alignItems={'center'} justifyContent='center'>
              <SimpleGrid columns={[1, 2, 4]} gap={5}>
                <Stack>
                  <Stack shadow={3} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
                    <HStack>
                      <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                        <MdSupervisorAccount size={'20px'} color='white' />
                      </Stack>
                      <Text color={'gray.500'}>Kanban Created</Text>
                    </HStack>
                    <Stack>
                      <Heading>{totalCardCreate}</Heading>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack shadow={3} p={5} spacing={5} justifyContent='center' borderRadius='xl'>
                    <HStack>
                      <Stack bgColor={'blue.400'} borderRadius='full' alignItems={'center'} justifyContent='center' p={1}>
                        <MdSupervisorAccount size={'20px'} color='white' />
                      </Stack>
                      <Text color={'gray.500'}>Total User</Text>
                    </HStack>
                    <Stack>
                      <Heading>{totalUsers?.length}</Heading>
                    </Stack>
                  </Stack>
                </Stack>
              </SimpleGrid>
            </Stack>
          </Stack>
        </Box>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Total Card</Th>
                  {/* <Th>Label</Th>
                  <Th>Duration</Th>
                  <Th>Due On</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      {x?.name}
                      <Text fontSize='2xs'>ID: {x?.id}</Text>
                    </Td>
                    <Td>{x?.totalCard}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <DatePicker onDateChange={onDateChange} />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              {/* <Button colorScheme="red" mr={3} onClick={onClose}>
                Close
              </Button> */}
              <DynamicButton
                title={'Close'}
                action="custom"
                color={'red'}
                // variant={'solid'}
                // onClick={() => getData()}
                onClick={onClose}
              />
              <DynamicButton
                title={'Search with filter'}
                action="read"
                // variant={'solid'}
                onClick={() => {
                  getData();
                  onClose();
                }}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DashboardPage;
