import React from 'react'
import ConfigurationIndex from '../Pages/Configuration/ConfigurationIndex';
import DomainsPage from '../Pages/Domains/DomainsPage';

const ConfigurationRouter = [
  {
    path: '/configuration',
    element: <ConfigurationIndex />,
  },
  {
    path: '/configuration/domain',
    element: <DomainsPage />,
  },

];

export default ConfigurationRouter;
