/* eslint-disable no-undef */
import {
  Box,
  Button,
  Container,
  Heading,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Divider,
  HStack,
  Spacer,
  SimpleGrid,
  useToast,
  Image,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  getCollectionFirebase,
  getCollectionWhereFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import { formatFrice } from '../../Utils/Helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import _axios from '../../Apis/AxiosBarrier';
import PricingPage from './PricingPage';
import axios from 'axios';
import Countdown from '../../Components/CountDown/CountDown';

function PaymentLpPage() {
  const [searchParams] = useSearchParams();
  const nameParams = searchParams.get('name');
  const emailParams = searchParams.get('email');
  const phoneParams = searchParams.get('phone');
  const valueParams = searchParams.get('total');
  const scheduleParams = searchParams.get('schedule_id');
  const couponParams = searchParams.get('coupon');
  const companyParams = searchParams.get('company');

  const [features, setFeatures] = useState('');

  const dataParams = {
    name: nameParams,
    email: emailParams,
    phone: phoneParams,
    schedule_id: scheduleParams,
    coupon: couponParams,
    company: companyParams,
    value: valueParams,
  };

  const [value, setValue] = useState('');
  const [externalId, setExternalId] = useState('');
  const [payData, setPayData] = useState({});
  const [isLoad, setIsLoad] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [dataQr, setDataQr] = useState('');
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [endDate, setEndDate] = useState('2023-12-29T07:10:47.811538Z');
  const toast = useToast();
  const [selectedPaymentMethodImage, setSelectedPaymentMethodImage] =
    useState('');

  const apikey = process.env.REACT_APP_PAYMENT_KEY;

  const banks = [
    {
      name: 'BNI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png',
    },
    {
      name: 'MANDIRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png',
    },
    {
      name: 'PERMATA',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png',
    },
    {
      name: 'BRI',
      uri: 'https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png',
    },
  ];

  const getPricing = async () => {
    try {
      const conditions = [
        { field: 'value', operator: '==', value: dataParams?.value },
      ];
      const sortBy = { field: 'lastUpdated', direction: 'desc' };
      const limitValue = 1;

      const featureSet = await getCollectionFirebase(
        'packages',
        conditions,
        sortBy,
        limitValue
      );
      setFeatures(featureSet[0]);

      const coupons = await getCollectionWhereFirebase(
        'coupons',
        'title',
        '==',
        dataParams.coupon
      );

      if (coupons.length > 0) {
        setDiscount(coupons[0]?.discountRate);
      } else {
        setDiscount(0);
      }
    } catch (error) {
      console.error('Error in getPricing:', error);
    }
  };

  useEffect(() => {
    getPricing();
    return () => {};
  }, []);

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast({
      title: 'Announce',
      description: 'Copy to clipboard.',
      status: 'success',
    });
  };

  const handlePay = async () => {
    if (value === '') {
      return toast({
        title: 'Deoapp.com',
        description: 'Mohon pilih jenis pembayaran terlebih dahulu.',
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
    const createData = {
      name: dataParams?.name,
      email: dataParams?.email,
      phone: dataParams?.phone,
      coupon: dataParams?.coupon,
      discount: discount,
      company: dataParams?.company,
    };
    setIsLoad(true);
    const conditions = [
      { field: 'email', operator: '==', value: dataParams?.email },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 1;

    try {
      let res;
      const resUser = await getCollectionFirebase(
        'users',
        conditions,
        sortBy,
        limitValue
      );

      if (resUser && resUser.length > 0) {
        const dataCreateCompany = {
          uid: resUser[0]?.id,
          companyName: dataParams?.company,
          coupon: dataParams?.coupon,
          discount: discount,
        };

        // const createdCompanyLp = await _axios.post('/company-createCompanyLp', dataCreateCompany);
        const createdCompanyLp = await axios.post(
          'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net/company-createCompanyLp',
          dataCreateCompany,
          {
            headers: {
              Authorization: 'pFa08EJkVRoT7GDiqk1',
            },
          }
        );

        res = {
          userData: {
            displayName: resUser[0]?.displayName,
            email: resUser[0]?.email,
            phoneNumber: resUser[0]?.phoneNumber,
            type: resUser[0]?.type,
            uid: resUser[0]?.id,
          },
          companyData: {
            companyId: createdCompanyLp?.data?.companyId,
            companyName: createdCompanyLp?.data?.companyData.name,
          },
          uid: resUser[0]?.id,
        };
      } else {
        try {
          const createdAccountRes = await _axios.post(
            '/company-createAccountLp',
            createData
          );
          res = createdAccountRes;
        } catch (error) {
          console.error('Error saat membuat akun baru:', error);
        }
      }

      if (value === 'QR_CODE') {
        const companyName = res?.companyData?.companyName;
        const paramPay = {
          is_production: true,
          package_code: features?.id,
          company_uid: res?.companyData?.companyId,
          user_uid: res?.uid,
          type: 'qr',
          bank: value,
          discount_fee: discount || 0,
        };
        try {
          const url = '/membershipCreateAndPayQr';
          const response = await _axios.post(url, paramPay, {
            headers: { Authorization: apikey },
          });
          if (response.status) {
            const saveSubscription = response.data;
            saveSubscription.company_id = paramPay?.company_uid;
            saveSubscription.company_name = companyName;
            saveSubscription.phone_number = dataParams?.phone;
            saveSubscription.package = features;
            saveSubscription.type = value;
            saveSubscription.lastUpdatedBy = {
              uid: res?.uid,
              email: res.userData.email,
            };
            await setDocumentFirebase(
              'subscription',
              saveSubscription.external_id,
              saveSubscription
            );

            setExternalId(saveSubscription?.external_id);
            const resQr = await _axios.get(saveSubscription.link_qr_code);
            setEndDate(saveSubscription.expires_at);
            setDataQr(resQr);
            setPayData(saveSubscription);
            setIsLoad(false);
          } else {
            return toast({
              status: 'warning',
              duration: 9000,
              title: response.message,
              isClosable: true,
            });
          }
          setIsLoad(false);
          return response.data;
        } catch (error) {
          setIsLoad(false);
          return toast({
            status: 'warning',
            duration: 9000,
            title: error.message,
            isClosable: true,
          });
        } finally {
          setIsLoad(false);
        }
      } else {
        const companyName = res?.companyData?.companyName;
        const paramPay = {
          is_production: true,
          package_code: features?.id,
          company_uid: res?.companyData?.companyId,
          user_uid: res?.uid,
          type: 'va',
          bank: value,
          discount_fee: discount || 0,
        };
        try {
          const url = '/membershipCreateAndPay';
          const response = await _axios.post(url, paramPay, {
            headers: { Authorization: apikey },
          });
          setIsLoad(false);
          if (response.status) {
            const saveSubscription = response.data;
            saveSubscription.company_id = paramPay?.company_uid;
            saveSubscription.company_name = companyName;
            saveSubscription.phone_number = dataParams?.phone;
            saveSubscription.package = features;
            saveSubscription.type = value;
            saveSubscription.lastUpdatedBy = {
              uid: res?.uid,
              email: res?.userData?.email,
            };
            await setDocumentFirebase(
              'subscription',
              saveSubscription.external_id,
              saveSubscription
            );

            setExternalId(saveSubscription?.external_id);

            if (value === 'CREDIT_CARD') {
              window.open(response?.data?.invoice_url, '_blank');
            } else {
              setPayData(saveSubscription);
            }
          } else {
            return toast({
              status: 'warning',
              duration: 9000,
              title: response.message,
              isClosable: true,
            });
          }
          setIsLoad(false);
          return response.data;
        } catch (error) {
          setIsLoad(false);
          return toast({
            status: 'warning',
            duration: 9000,
            title: error.message,
            isClosable: true,
          });
        } finally {
          setIsLoad(false);
        }
      }
    } catch (error) {
      console.log(error, 'ini error');
    } finally {
      setIsLoad(false);
    }
  };

  const handlePaymentMethodSelect = (method) => {
    setValue(method);
    const imageFind = banks.find((x) => x.name === method);
    setSelectedPaymentMethodImage(
      imageFind?.uri || 'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
    );
  };

  const handleReload = async () => {
    const res = await getSingleDocumentFirebase('subscription', externalId);

    try {
      if (res?.status === 'PAID') {
        setPayData(res);
      } else {
        return toast({
          status: 'warning',
          duration: 5000,
          position: 'top-right',
          title: 'Pembayaran Kamu Dalam Proses',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        position: 'top-right',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const toLogin = () => {
    window.location.href = features?.redirect;
  };  

  const handleCancelPay = () => {
    setPayData({});
  };
  return (
    <Container
      py={3}
      size={'5xl'}
      w="100%"
      minH={'100vh'}
      alignItems="center"
      justifyContent={'center'}
    >
      <Stack>
        <Stack>
          <Heading size={'lg'}>Make Payment </Heading>
          <Heading fontWeight={'extrabold'}>{dataParams?.name}</Heading>
          <Divider />
        </Stack>
        {/* menunjukan berapa banyak user yang bisa akses  */}
        {/* <HStack>
            <Text fontWeight={'bold'} color="gray.700">
                          Users Access
            </Text>
            <Spacer />
            <Text fontWeight={'bold'}> Users</Text>
          </HStack> */}

        {discount && (
          <HStack>
            <Text fontWeight={'bold'} color="gray.700">
              Coupon
            </Text>
            <Spacer />
            <Text fontWeight={'bold'}>{`${dataParams?.coupon} `}</Text>
          </HStack>
        )}

        <HStack>
          <Text fontWeight={'bold'} color="gray.700">
            Discount
          </Text>
          <Spacer />
          <Text fontWeight={'bold'}>{discount && discount}%</Text>
        </HStack>

        <HStack>
          <Spacer />
          <Text
            color={'red.500'}
            fontStyle="italic"
            fontSize={'sm'}
            fontWeight={500}
          >
            {/* Expired {moment(dataParams?.expired_at?.seconds * 1000).fromNow()} */}
          </Text>
        </HStack>
        <Divider />
        {features !== '' && (
          <PricingPage
            features={features && features}
            setFeatures={setFeatures}
            discount={discount || 0}
          />
        )}

        {payData.external_id === undefined ? (
          <>
            <RadioGroup
              value={value}
              onChange={handlePaymentMethodSelect}
              m="2"
            >
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <Text fontWeight={500} fontSize="sm">
                    Choose Virtual Account :
                  </Text>
                  <SimpleGrid columns={[2, null, 4]} align="start">
                    {banks?.map((x, index) => (
                      <Radio key={index} value={x.name}>
                        <Image src={x?.uri} w="80px" />
                      </Radio>
                    ))}
                  </SimpleGrid>
                </Stack>

                <Stack spacing={2}>
                  <Text fontWeight={500} fontSize="sm">
                    Qris :
                  </Text>
                  <SimpleGrid columns={[2, null, 4]} align="start">
                    <Radio value={'QR_CODE'}>
                      <Text>Qr Code</Text>
                    </Radio>
                  </SimpleGrid>
                </Stack>

                <Stack spacing={2}>
                  <Text fontWeight={500} fontSize="sm">
                    Choose Credit Card :
                  </Text>
                  <Radio value={'CREDIT_CARD'}>
                    <Image
                      src={
                        'https://sandbox.ipaymu.com/asset/images/logo_cc.png'
                      }
                      w="90px"
                    />
                  </Radio>
                </Stack>
              </Stack>
            </RadioGroup>
            {isLoad ? (
              <Button w="full" colorScheme="blue" isLoading>
                Pay
              </Button>
            ) : (
              <Button w="full" colorScheme="blue" onClick={() => handlePay()}>
                Pay
              </Button>
            )}
          </>
        ) : (
          <>
            {payData?.type === 'QR_CODE' && payData?.status !== 'PAID' ? (
              <>
                <HStack py={1}>
                  <Text>Status Payment</Text>
                  <Spacer />
                  <Text
                    fontSize="sm"
                    fontWeight={'bold'}
                    color={
                      payData?.status === 'ACTIVE' ? 'red.600' : 'green.600'
                    }
                    textTransform="uppercase"
                  >
                    {payData?.status === 'ACTIVE' ? 'PENDING' : payData?.status}
                  </Text>
                </HStack>
                <HStack py={1}>
                  <Text>Expired Payment</Text>
                  <Spacer />
                  <Text fontSize="sm">
                    <Countdown startDate={startDate} endDate={endDate} />
                  </Text>
                </HStack>
                <HStack py={1}>
                  <Spacer />
                  <Text fontSize="sm">Rp. {formatFrice(payData?.amount)}</Text>
                </HStack>
                <Stack
                  alignItems={'center'}
                  justifyContent="center"
                  spacing={2}
                >
                  <div dangerouslySetInnerHTML={{ __html: dataQr }} />
                  <HStack alignItems="center" justifyContent="center">

                  </HStack>
                  <HStack alignItems="center" justifyContent="center" mt={3}>
                    <Box>
                      <DynamicButton
                        action={'custom'}
                        color={'red'}
                        title="Cancel"
                        onClick={() => handleCancelPay()}
                      />
                    </Box>
                    <Box>
                      <DynamicButton
                        action={'custom'}
                        color={'green'}
                        title="Done"
                        onClick={() => handleReload()}
                      />
                    </Box>
                  </HStack>
                </Stack>
              </>
            ) : payData?.type === 'QR_CODE' && payData?.status === 'PAID' ? (
              <>
                <HStack py={1}>
                  <Text>Status Payment</Text>
                  <Spacer />
                  <Text
                    fontSize="sm"
                    fontWeight={'bold'}
                    color={
                      payData?.status === 'PENDING' ? 'red.600' : 'green.600'
                    }
                    textTransform="uppercase"
                  >
                    {payData?.status === 'ACTIVE' ? 'PENDING' : payData?.status}
                  </Text>
                </HStack>
                <HStack alignItems="center" justifyContent="center">
                  <Box>
                    <DynamicButton
                      action={'custom'}
                      color={'green'}
                      title="Back To Login"
                      variant={'solid'}
                      onClick={() => toLogin()}
                    />
                  </Box>
                </HStack>
              </>
            ) : (
              <Stack>
                <HStack w="full" px={5}>
                  <Image
                    src={selectedPaymentMethodImage}
                    alt={payData?.bank_code}
                    w="80px"
                    borderRadius="xl"
                  />

                  <Spacer />

                  <Text
                    fontSize="sm"
                    fontWeight={'bold'}
                    color={
                      payData?.status === 'PENDING' ? 'red.600' : 'green.600'
                    }
                    textTransform="uppercase"
                  >
                    {payData?.status}
                  </Text>
                </HStack>

                {payData?.status !== 'PAID' && (
                  <Box bg="white" px={5}>
                    <Text>No. Virtual Account : </Text>
                    <Divider my={2} />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Text fontSize={20} color="gray.500">
                        {payData ? payData?.account_number : 'none'}
                      </Text>
                      <Text
                        color="blue.600"
                        cursor="pointer"
                        onClick={() => handleCopy(payData?.account_number)}
                      >
                        SALIN
                      </Text>
                    </Box>
                    <Divider my={2} />
                    <HStack py={1}>
                      <Spacer />
                      <Text fontSize="sm">
                        Rp. {formatFrice(payData?.expected_amount)}
                      </Text>
                    </HStack>
                    <Divider my={2} />

                    <Text fontSize={10} color="gray.600">
                      Proses verifikasi otomatis kurang dari 10 menit setelah
                      pembayaran berhasil
                    </Text>
                    <Spacer />
                    <Text fontSize={10} color="gray.600">
                      Bayar ke Virtual Account di atas sebelum membuat donasi
                      baru dengan Virtual account agar nomor tetap sama.
                    </Text>
                  </Box>
                )}

                {payData?.status !== 'PAID' && (
                  <Box bg="white" p={5}>
                    <Text fontSize={10} color="gray.600">
                      Petunjuk Transfer mBanking :
                    </Text>
                    <Divider />
                    <Text fontSize={10} color="gray.600">
                      1. Login ke mBanking-mu, pilih Bayar, kemudian cari Xendit
                      / {payData.merchant_code} Virtual Account
                    </Text>
                    <Text fontSize={10} color="gray.600">
                      2. Masukkan nomor Virtual Account
                    </Text>
                    <Text fontSize={10} color="gray.600">
                      3. Pastikan nama dan nominal bayar benar
                    </Text>
                    <Text fontSize={10} fontWeight={500} color="red.500">
                      4. Jika kamu sudah melakukan pembayaran, klik tombol done
                    </Text>
                  </Box>
                )}

                {payData?.status !== 'PAID' && (
                  <HStack alignItems="center" justifyContent="center">
                    <Box>
                      <DynamicButton
                        action={'custom'}
                        color={'red'}
                        title="Cancel"
                        onClick={() => handleCancelPay()}
                      />
                    </Box>

                    <Box>
                      <DynamicButton
                        action={'custom'}
                        title="Done"
                        color={'green'}
                        onClick={() => handleReload()}
                      />
                    </Box>
                  </HStack>
                )}
                {payData?.status === 'PAID' && (
                  <HStack alignItems="center" justifyContent="center">
                    <Box>
                      <DynamicButton
                        action={'custom'}
                        color={'green'}
                        title="Back To Login"
                        variant={'solid'}
                        onClick={() => toLogin()}
                      />
                    </Box>
                  </HStack>
                )}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}

export default PaymentLpPage;