/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Stack,
  Input,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  useToast,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { IoCloseCircle, IoCloseSharp } from 'react-icons/io5';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import {
  addDocumentFirebase,
  updateDocumentFirebase,
} from '../../../Apis/firebaseApi';
import useUserStore from '../../../Hooks/zustand/Store';

const KpiCreate = ({ isOpen, onClose, editData, resSave, categories }) => {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const optionDivisi = [
    'google ads',
    'hr',
    'retention',
    'product research',
    'content creator',
  ];
  const optionFormat = ['number', 'usd', 'idr', 'percentage'];
  const optionPeriod = ['daily', 'weekly', 'monthly', 'quarterly'];
  const optionType = ['income', 'product', 'expense'];
  const optionAchivement = ['high', 'low'];
  const [formData, setFormData] = useState({
    divisi: 'google ads',
    title: '',
    target: 0,
    format: 'number',
    isAvarage: false,
    period: 'daily',
    achivement: 'high',
    description: '',
    projectId: '8OO4G3FzrC3RVrdqpFN1',
    companyId: '0BZDboI1UP5SoXFV1Z4n',
    categoryId:[]
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        divisi: editData.divisi || 'google ads',
        title: editData.title || '',
        target: editData.target || 0,
        format: editData.format || 'number',
        isAvarage: editData.isAvarage || false,
        period: editData.period || 'daily',
        achievement: editData.achievement || 'high',
        description: editData.description || '',
        projectId: editData.projectId || '8OO4G3FzrC3RVrdqpFN1',
        companyId: editData.companyId || '0BZDboI1UP5SoXFV1Z4n',
        categoryId: editData.categoryId || [],
      });
    } else {
      setFormData({
        divisi: 'google ads',
        title: '',
        target: 0,
        format: 'number',
        isAvarage: false,
        period: 'daily',
        achivement: 'high',
        description: '',
        projectId: '8OO4G3FzrC3RVrdqpFN1',
        companyId: '0BZDboI1UP5SoXFV1Z4n',
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newValue = e.target.type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const onSubmit = async () => {
    try {
      if (formData.title === '' || formData.description === '') {
        return toast({
          title: 'Input Validation',
          description: 'Please fill in all fields',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      setIsLoading(true);
      globalState.setIsLoading(true);

      if (editData) {
        await updateDocumentFirebase('kpi', editData.id, formData);
      } else {
        await addDocumentFirebase('kpi', formData, globalState.currentCompany);
      }
      globalState.setIsLoading(false);

      setIsLoading(false);
      setFormData({
        divisi: 'google ads',
        title: '',
        target: 0,
        format: 'number',
        isAvarage: false,
        period: 'daily',
        achivement: 'high',
        description: '',
        projectId: '8OO4G3FzrC3RVrdqpFN1',
        companyId: '0BZDboI1UP5SoXFV1Z4n',
      });
      resSave();
      onClose();
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleAddCategory = (value) => {
    if(formData?.categoryId?.find((x) => x === value) !== undefined) return   toast({
      title: 'Pemberitahuan',
      description: 'Kategori sudah ditambahkan',
      position: 'top-right',
      duration: 3000,
      status: 'info',
      isClosable: true,
    });

    const existingFeatures = Array.isArray(formData?.categoryId) ? formData?.categoryId : [];
    const newFeatureList = [...existingFeatures, value];
    setFormData({...formData, categoryId: newFeatureList})

  };

  const handleDeleteCategory = (index) => {
    const existingFeatures = Array.isArray(formData?.categoryId) ? formData?.categoryId : [];
    const updatedFeatures = [...existingFeatures];
    updatedFeatures.splice(index, 1);
    setFormData({...formData, categoryId: updatedFeatures})
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Template KPI</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w={'100%'} mb={3} gap={5} direction={['column', 'row', 'row']}>
            <Box
              w={['100%', '50%', '50%']}
              p={5}
              borderRadius="md"
              shadow={'base'}
            >
              <Stack spacing={3} align={'center'}>
                <FormControl mb="4">
                  <FormLabel>Division</FormLabel>
                  <Select
                    value={formData.divisi}
                    onChange={(e) =>
                      setFormData({ ...formData, divisi: e.target.value })
                    }
                    required
                  >
                    {optionDivisi.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Title</FormLabel>
                  <Input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Target</FormLabel>
                  <Input
                    type="number"
                    name="target"
                    value={formData.target}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Period</FormLabel>
                  <Select
                    value={formData.period}
                    onChange={(e) =>
                      setFormData({ ...formData, period: e.target.value })
                    }
                    required
                  >
                    {optionPeriod.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>

            <Box p={5} w={['100%', '50%', '50%']} shadow={'base'}>
              <FormControl mb="4">
                <FormLabel>Type</FormLabel>
                <Select
                  value={formData.type}
                  onChange={(e) =>
                    setFormData({ ...formData, type: e.target.value })
                  }
                  required
                >
                  {optionType.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {categories ? (<FormControl>
                <FormLabel mt="8px" fontWeight={'bold'}> Categories</FormLabel>
                <Select  maxW={{
                  md: '4xl',
                }}
                placeholder="Category" onChange={(e) => {
                  handleAddCategory(e.target.value)
                }
                }
                >
                  {categories?.map((category, i) => { return(
                    <option key={i} value={category?.id}>{category?.name}</option>
                  )})}
                </Select>
                <HStack mt={3} w={'full'}> 
                  <Text fontWeight={'medium'}>Categories :</Text>  
                  <Flex  gap={2} w={'200px'} overflow="scroll"
                    sx={{
                      '&::-webkit-scrollbar': {
                        w: '2',
                        h: '3',
                      },
                      '&::-webkit-scrollbar-track': {
                        w: '6',
                        h: '3',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10',
                        bg: 'gray.300',
                      },
                    }}>
                    {formData?.categoryId?.map((category, i) => (
                      <Box key={i} pos={'relative'} borderRadius={'md'} shadow={'base'} px={2}>
                        <Text>{categories?.find((x) => x?.id === category)?.name}</Text>
                        <Box pos={'absolute'} top={0} right={-1} cursor={'pointer'} onClick={() => handleDeleteCategory(i)}>
                          <IoCloseCircle color='red' size={13} />
                        </Box>
                      </Box>
                    ))}
                  </Flex>
                </HStack>
              </FormControl>) : (<></>)}
              <FormControl mb="4">
                <FormLabel>Achivement</FormLabel>
                <Select
                  value={formData.achivement}
                  onChange={(e) =>
                    setFormData({ ...formData, achivement: e.target.value })
                  }
                  required
                >
                  {optionAchivement.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Format</FormLabel>
                <Select
                  value={formData.format}
                  onChange={(e) =>
                    setFormData({ ...formData, format: e.target.value })
                  }
                  required
                >
                  {optionFormat.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb="4">
                <Checkbox
                  colorScheme="green"
                  id="isAverage"
                  name="isAvarage"
                  isChecked={formData.isAvarage}
                  onChange={handleChange}
                >
                  Is Average
                </Checkbox>
              </FormControl>
            </Box>
          </Flex>

          <FormControl mt="5">
            <FormLabel ml={2}>Description</FormLabel>
            <Textarea
              height={150}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </FormControl>

          {/* Buttons for cancel and submit */}
          <HStack
            mt="10"
            mb="5"
            display="flex"
            justifyContent="end"
            spacing={2}
          >
            <DynamicButton
              size={'sm'}
              action="custom"
              color={'red'}
              icon={IoCloseSharp}
              title={'Cancel'}
              onClick={onClose}
            />
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={onSubmit}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default KpiCreate;
