import {
  FcConferenceCall,
  FcSettings,
  FcCollaboration,
  FcBarChart,
  FcDocument,
  FcOpenedFolder,
  FcClock,
  FcOrganization,
  FcServices,
  FcGlobe,
  FcPanorama,
  FcMoneyTransfer,
  FcPortraitMode,
  FcAddDatabase,
  FcReadingEbook,
  FcBusinessman,
  FcAddressBook,
  FcReading,
  FcBusiness,
  FcComboChart,
  FcGenericSortingAsc,
  FcPaid,
  FcElectricalThreshold,
  FcTemplate,
  FcInfo,
  FcExternal,
  FcRatings,
  FcQuestions,
  FcVideoCall,
  FcVoicePresentation,
  FcHome,
  FcDebt,
  FcTreeStructure,
  FcParallelTasks,
} from 'react-icons/fc';


export const data = [

  {
    name: 'General',
    icon: FcGenericSortingAsc,
    link: '/companies/index',
    description:
      'collection of companies and users.',
    submenu: [
      {
        name: 'Company',
        icon: FcOrganization,
        link: '/companies/page',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'User',
        icon: FcConferenceCall,
        link: '/companies/user',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Billing',
        icon: FcPaid,
        link: '/companies/billing',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Integration',
        icon: FcServices,
        link: '/companies/integration',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Menu Description',
        icon: FcInfo,
        link: '/companies/config-menu',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Landing Page Data',
        icon: FcExternal,
        // link: '/companies/config-menu',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
        submenu: [
          {
            name: 'Data Home',
            icon: FcRatings,
            link: '/general/home',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
          {
            name: 'Pricing V2',
            icon: FcRatings,
            link: '/general/pricingV2',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
          {
            name: 'Question & Answer',
            icon: FcQuestions,
            link: '/general/question-and-answer',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
          {
            name: 'Link Video',
            icon: FcVideoCall,
            link: '/general/link-embed',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
          {
            name: 'Testimonial',
            icon: FcVoicePresentation,
            link: '/general/testimoni',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
          {
            name: 'Integration',
            icon: FcVoicePresentation,
            link: '/general/integration',
            description:
                'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
          },
        ]
      },
      {
        name: 'Apps',
        icon: FcHome,
        link: '/general/apps',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'User Lp',
        icon: FcDebt,
        link: '/general/user-lp',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: 'Orders',
      //   icon: FcVoicePresentation,
      //   link: '/general/orders',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
    ],
  },

  {
    name: 'Business',
    icon: FcBusiness,
    // link: 'https://productivity.deoapp.com/',
    link: '/business',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Dashboard',
        icon: FcComboChart,
        link: '/business/dashboard',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Coupon',
        icon: FcClock,
        link: '/business/coupon',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'SOPKPI',
    icon: FcElectricalThreshold,
    link: '/sopkpi',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Template KPI',
        icon: FcTemplate,
        link: '/sopkpi/kpi-template',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Template SOP',
        icon: FcTemplate,
        link: '/sopkpi/sop-template',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Kategori',
        icon: FcParallelTasks,
        link: '/sopkpi/categories-template',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
  {
    name: 'CRM',
    icon: FcTreeStructure,
    link: '/crm',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Config Chat',
        icon: FcTemplate,
        link: '/crm/config-chat',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'Marketing',
    icon: FcCollaboration,
    // link: 'https://productivity.deoapp.com/',
    link: '/marketing',
    description:
      'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Template Creatomate',
        icon: FcOpenedFolder,
        link: '/marketing/template-creatomate',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Template Landing Page',
        icon: FcOpenedFolder,
        link: '/marketing/template-lp',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Banner Management',
        icon: FcDocument,
        link: '/system/bener-management',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Domain',
        icon: FcGlobe,
        link: '/marketing/Domain',
        description:
        'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Campaign',
        icon: FcPanorama,
        link: '/marketing/ads-request',
      },
      {
        name: 'Content',
        icon: FcVideoCall,
        link: '/marketing/content',
      },
    ],
  },


  // {
  //   name: 'Karyawan.AI',
  //   icon: FcBusinessman,
  //   link: '/system/karyawan',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Karyawan',
  //       icon: FcPortraitMode,
  //       link: '/system/karyawan-ai',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //     {
  //       name: 'Knowledge Ai',
  //       icon: FcAddDatabase,
  //       link: '/system/knowledge',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //     {
  //       name: 'karyawan config',
  //       icon: FcSettings,
  //       link: '/system/config-karyawanai',
  //       description:
  //         'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //   ],
  // },

  // {
  //   name: 'Workbook.id',
  //   icon: FcReadingEbook,
  //   link: '/workbook',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Workbooks',
  //       icon: FcAddressBook,
  //       link: '/workbook/view',
  //       description:
  //       'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //     {
  //       name: 'Workbook categories',
  //       icon: FcBarChart,
  //       link: '/workbook/categories',
  //       description:
  //       'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //     {
  //       name: 'Workbook Writers',
  //       icon: FcReading,
  //       link: '/workbook/writers',
  //       description:
  //       'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //     },
  //   ],
  // },

  // batas sampai sini
];
