import React from 'react';
import NotificationPage from '../Pages/Notification/NotificationPage';
import { SettingAccountPage } from '../Pages/Settings/SettingAccountPage';

const SettingRouter = [
//   {
//     path: '/change-password',
//     element: <SettingPasswordPage />,
//   },
  {
    path: '/notification',
    element: <NotificationPage />,
  },
  {
    path: '/settings',
    element: <SettingAccountPage />,
  },
];

export default SettingRouter;
