/* eslint-disable no-mixed-spaces-and-tabs */
import { Box, Button, Container, FormLabel, Heading, HStack, Input, Select, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase } from '../../Apis/firebaseApi'
import BackButtonComponent from '../../Components/Buttons/BackButtonComponent'
import { createDomainCustom } from '../../Apis/firebaseFunctions'
import useUserStore from '../../Hooks/zustand/Store'


function DomainsNewPage() {
  const [data, setData] = useState({})
  const [projects,setProjects]=useState()
  const globalState = useUserStore();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [textVerif, setTextVerif] = useState(null);
  const [isComplete, setIsComplete] = useState(false)
  const toast = useToast()

  const handleSelectChange = (e) => {
    const selectedCompanyId = e.target.value;

    setData({
      ...data,
      companyId: selectedCompanyId,
    });
    getProjects(selectedCompanyId)
  };

  const getProjects = (companyId) => {
    const conditions = [
      { field: 'companyId', operator: '==', value: companyId },
    ];
    getCollectionFirebase('projects',conditions)
      .then((x) => setProjects(x))
      .catch((err) => console.log(err.message))
  }

  const handleSave = async () => {
    setData({...data, verif:  data.name, isSubdomain: false})

    if(!data.name) {
      return toast({
        title: 'Admin',
        description: 'Please fill the Name',
        status: 'error',
      })
    }
    if(!data.companyId) {
      return toast({
        title: 'Admin',
        description: 'Please fill the Company',
        status: 'error',
      })
    }
    if(!data.projectId) {
      return toast({
        title: 'Admin',
        description: 'Please fill the Project',
        status: 'error',
      })
    }
    if(!data.projectVercel) {
      return toast({
        title: 'Admin',
        description: 'Please fill the Project Vercel',
        status: 'error',
      })
    }
    setIsLoading(true);
    const response = (await createDomainCustom(data))
    if (response.status) {
      let domain = await getSingleDocumentFirebase('domains', data.projectId)
      if (domain === undefined) {
        domain = {'domain': [data.name]}
      } else {
        domain.domain.push(data.name)
      }
      await setDocumentFirebase('domains', data.projectId, domain)

      const domainData = response.data;
      domainData.domain = data.name
      domainData.companyId = data.companyId
      domainData.projectId = data.projectId
      addDocumentFirebase('domain_lists', domainData, domainData.companyId )
      if (domainData.verification !== undefined) {
        setTextVerif(domainData.verification[0])
      } else {
        if (domainData.name !== domainData.apexName) {
          const subdomain = domainData.name.replaceAll('.'+domainData.apexName, '')
          setData({...data, verif: subdomain, isSubdomain: true})
        } else {
          setData({...data, verif: domainData.domain, isSubdomain: false})
        }
      }
      setIsLoading(false);
      setIsComplete(true)
    } else {
      setIsLoading(false);
    }
  }

	
  return (<>
    <HStack mb='2'>
      <BackButtonComponent />
      <Heading size={'md'}>Domain Name</Heading>
    </HStack>

    <Container shadow='base' p="5" borderRadius="md" mt={5}>
      <Heading fontSize="md" mb={4}>
				Add new domain name
      </Heading>
      <Input
        m="1"
        type="text"
        placeholder="domain name ex: mydomain.com"
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <FormLabel mt={3} mb={2}>
				Select Company
      </FormLabel>
      <Select
        m="1"
        placeholder="Project"
        onChange={handleSelectChange}
        value={data.companyId} // Optional: Untuk mengatur nilai default dropdown
      >
        {globalState.companies?.map((x, i) => (
          <option key={i} value={x.id}>
            {x.name}
          </option>
        ))}
      </Select>

      <FormLabel mt={3} mb={2}>
				Select project
      </FormLabel>
      <Select
        m="1"
        placeholder="Project"
        onChange={(e) => setData({ ...data, projectId: e.target.value })}
      >
        {projects?.map((x, i) => (
          <option key={i} value={x.id}>
            {x.name}
          </option>
        ))}
      </Select>

      <FormLabel mt={3} mb={2}>
				Select project vercel
      </FormLabel>
      <Select
        m="1"
        placeholder="Project Vercel"
        onChange={(e) => setData({ ...data, projectVercel: e.target.value })}
      >
        <option value="pageview">Deoapp Pageview</option>
        <option value="domainview-react">Deoapp Domainview React</option>
      </Select>
      {/* <Heading fontSize='md'>Add new domain name</Heading>
			<Input m='1' type='text' placeholder='domain name ex: mydomain.com' onChange={(e) => setData({ ...data, name: e.target.value })} />
			<Select m='1' placeholder='Project' onChange={(e) => setData({ ...data, projectId: e.target.value })}>
				{projects?.map((x,i)=>
				<option key={i} value={x.id}>{x.name}</option>)}
			</Select> */}

      {textVerif ? 
        <Box p='5'>
          <Text>Create an {textVerif.type} record for {data?.name} pointing to our server.</Text>
          <HStack bgColor='gray.100' p='3' gap={2}>
            <Box>
              <Text>Type</Text>
              <Text>{textVerif.type}</Text>
            </Box>
            <Box>
              <Text>Name</Text>
              <Text>_vercel</Text>
            </Box>
            <Box>
              <Text>Value</Text>
              <Text>{textVerif.value}</Text>
            </Box>
          </HStack>
        </Box> : ''}
			
      {data?.verif? 
        !data.isSubdomain ?
          <Box p='5'>
            <Text>Create an A record for {data?.name} pointing to our server.</Text>
            <HStack bgColor='gray.100' p='3' gap={2}>
              <Box>
                <Text>Type</Text>
                <Text>A</Text>
              </Box>
              <Box>
                <Text>Name</Text>
                <Text>@</Text>
              </Box>
              <Box>
                <Text>Value</Text>
                <Text>76.76.21.21</Text>
              </Box>
            </HStack>
          </Box>
          :
          <Box p='5'>
            <Text>Set the following record on your DNS provider to continue.</Text>
            <HStack bgColor='gray.100' p='3' gap={2}>
              <Box>
                <Text>Type</Text>
                <Text>CNAME</Text>
              </Box>
              <Box>
                <Text>Name</Text>
                <Text>{data.verif}</Text>
              </Box>
              <Box>
                <Text>Value</Text>
                <Text>cname.vercel-dns.com</Text>
              </Box>
            </HStack>
          </Box>
        :
        <></>

      }

      {!isComplete ? 
        (isLoading ? 
          <Button isLoading colorScheme='green' m='1' width='full'>Save</Button> : 
          <Button colorScheme='green' m='1' width='full' onClick={() => handleSave()}>Save</Button>
        )
        : 
        <Button colorScheme='green' m='1' width='full' onClick={() => navigate('/domain')}>Complete</Button>}		
    </Container>
  </>
  )
}

export default DomainsNewPage