/* eslint-disable no-undef */
import {
  Avatar, Box, Button, Center, Heading, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, HStack, Input, Spacer, Text, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, Flex, FormControl, FormLabel, useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { arrayRemoveFirebase, arrayUnionFirebase, getCollectionFirebase, getUsersByIds } from '../../Apis/firebaseApi'
import { createUserFunctions } from '../../Apis/firebaseFunctions'
import { clientTypesense } from '../../Apis/typeSense'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { IoCloseSharp } from 'react-icons/io5'
import { data } from './../../Components/Sidebar/DataMenu';
import BackButtons from '../../Components/Buttons/BackButtons'
import useUserStore from '../../Hooks/zustand/Store'
import 'firebase/auth';
import axios from 'axios'
import Select from 'react-select';
import { Link } from 'react-router-dom'
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2'
import _axios from '../../Apis/AxiosBarrierReadme'

function UsersPage() {
  const globalState = useUserStore()
  const [dataUser, setData] = useState()
  const [counter, setCounter] = useState(0)
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [search, setSearch] = useState({})
  const [loading, setLoading] = useState(false)
  const { isOpen, onClose } = useDisclosure()
  const [modalDetail, setModalDetail] = useState(false)
  const [modalAccess, setModalAccess] = useState(false)
  const [companyUsers, setCompaniesUser] = useState(false)
  const [projectUsers, setProjectUsers] = useState(false)
  const [detailUser, setDetailUser] = useState(false)
  const [useNname, setUserName] = useState()
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [phone, setPhone] = useState('');
  const toast = useToast();

  const [alertDelete, setAlertDelete] = useState(false)
  const [userActive, setUserActive] = useState(null)


  const authToken = process.env.REACT_APP_PAYMENT_KEY;
  const apiUrl = process.env.REACT_APP_FUNCTIONS_HOST + '/user-create';
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleAddNewUser = () => {
    setLoading(true)
    const conditions = [
      { field: 'email', operator: '==', value: email.toLowerCase() },
    ];
    getCollectionFirebase('users', conditions)
      .then((x) => {
        if (x.length > 0
        ) {
          arrayUnionFirebase('companies', globalState.currentCompanies, 'users', [x[0].id])
            .then()
            .catch((err) => console.log(err.message))
        }
        else {
          createUserFunctions({ email: email, name: name, companyId: globalState.currentCompanies })
            .then()
            .catch((err) => console.log(err.message))
        }
        setLoading(false)
        onClose()
      })
      .catch((err) => console.log(err.message))
  }

  const handleSearch = (q, p) => {
    setSearch({ query: q, page: p })
    const searchParameters = {
      q: q,
      query_by: 'email',
      page: p,
      sort_by: '_text_match:desc'
    };
    clientTypesense
      .collections('users')
      .documents()
      .search(searchParameters)
      .then((x) => {
        setCounter(x.found)
        const newData = x.hits.map((y) => { return { ...y.document } })
        if (p === 1)
          setData(newData)
        else
          setData([...dataUser, ...newData])

      });
  }

  const handleModal = async (dataUser) => {
    setUserName(dataUser.name)
    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: dataUser.id,
      },
    ];

    const [companies, projects] = await Promise.all([
      getCollectionFirebase('companies', conditions),
      getCollectionFirebase('projects', conditions),
    ]);

    setCompaniesUser(companies)
    setProjectUsers(projects)
    setModalDetail(true)
  }

  const handleSave = async () => {
    if (
      name === '' ||
			email === '' ||
			phone === ''
    ) {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    setIsOpenCreate(false);
    const requestData = {
      'name': name,
      'email': email,
      'phone': phone,
      'country': 'indonesia',
      'module': 'deoapp.admin'
    };

    try {
      globalState.setIsLoading(true);
      const res = await axios.post(apiUrl, requestData, {
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json'
        }
      });
      if (res.data.status) {
        toast({
          title: 'Data Saved',
          description: 'The dataUser has been successfully saved.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Data Saved',
          description: res.data.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      globalState.setIsLoading(false);

      setName('')
      setEmail('')
      setPhone('')
      setIsOpenCreate(false);
    } catch (error) {
      // Handle error scenarios and show an error toast
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleModalAccess = async (arg) => {
    const usersData = await getUsersByIds([arg.id]);
    const accessPageValues = usersData[0]?.accessPage || [];
    const filteredOptions = colourOptions.filter((option) => accessPageValues.includes(option.label));

    setSelectedOptions(filteredOptions)
    setDetailUser(usersData)
    setModalAccess(true)
  }

  const handleSaveAccessUser = async () => {
    try {
      const selectedValues = selectedOptions.map((option) => option.label);
      globalState.setIsLoading(true);
      if (detailUser[0].accessPage.length > 0) {
        await arrayRemoveFirebase('users', detailUser[0].id, 'accessPage', detailUser[0].accessPage);
      }
      await arrayUnionFirebase('users', detailUser[0].id, 'accessPage', selectedValues);
      globalState.setIsLoading(false);
      setSelectedOptions([])
      handleSearch('*', 1)

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      setModalAccess(false)
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const colourOptions = data.map((item) => ({
    value: item.name.toLowerCase().replace(/\s/g, '-'), // Ubah menjadi lowercase dan ganti spasi dengan tanda '-'
    label: item.name
  }));

  useEffect(() => {
    handleSearch('*', 1)

    return () => {
      setData()
    }
  }, [globalState?.currentCompanies?.users?.length])

  const handleAlertDelete = (user) => {
    setAlertDelete(true)
    setUserActive(user)
  }

  const handleDeleteFile = async () => {

    globalState.setIsLoading(true)

    const dataSend = {
      uid : userActive?.id || ''
    }

    try {
      await _axios.post('user-delete', dataSend)
      setUserActive(null)
      setAlertDelete(false)
      toast({
        title: 'Success',
        description: 'Success delete user.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      handleSearch('*', 1)
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }finally{
      globalState.setIsLoading(false)
    }
  }

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Users</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
            <Input type='text' mb={3} placeholder='Search User' onChange={(e) => handleSearch(e.target.value, 1)} />
            <Spacer />
            <DynamicButton
              size={'md'}
              action="create"
              title={'User'}
              onClick={() => setIsOpenCreate(true)}
            />
            <Link to={'/companies/user/import'}>
              <DynamicButton
                size={'md'}
                color={'blue'}
                action="custom"
                title={'Bulk User'}
              />
            </Link>

          </Flex>
          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name </Th>
                  <Th>Email </Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataUser?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      <HStack>
                        <Avatar
                          name={x?.name}
                        />
                        <Stack spacing={0}>
                          <Text
                            textTransform={'capitalize'}
                            fontWeight={'bold'}
                            fontSize="sm"
                          >
                            {x?.name}
                          </Text>
                          <Text fontSize="3xs">{x?.id}</Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td> {x?.email}</Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <DynamicButton
                          size="sm"
                          action="read"
                          variant={'solid'}
                          onClick={() => handleModal(x)}
                        />
                        <DynamicButton
                          size="sm"
                          variant={'solid'}
                          action="delete"
                          onClick={() => handleAlertDelete(x)}
                        />
                        {globalState?.email === 'ami@importir.co' && (
                          <DynamicButton
                            size="sm"
                            action="update"
                            onClick={() => handleModalAccess(x)}
                          />
                        )}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Center py={5}>
            {dataUser?.length < counter ?
              <>
                <Box textAlign='center'>
                  <Text>{dataUser.length}/{counter}</Text>
                  <Button colorScheme='green' onClick={() => handleSearch(search?.query, search.page + 1)}>Load More</Button>
                </Box>

              </>
              :
              <>
              </>
            }
          </Center>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Lorem count={2} /> */}
            <Input type='text' placeholder='name' onChange={(e) => setName(e.target.value)} />
            <Input type='text' placeholder='email' onChange={(e) => setEmail(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            {
              loading ?
                <Button colorScheme='green' mr={3} isLoading>
									Save
                </Button>
                :
                <Button colorScheme='green' mr={3} onClick={() => handleAddNewUser()}>
									Save
                </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalDetail} onClose={() => setModalDetail(false)} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detail User {useNname}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer
              border={'1px'}
              borderRadius={'sm'}
              borderColor={'gray.100'}
              boxShadow={'base'}
            >
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Company </Th>
                    <Th>Project </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(companyUsers) && companyUsers.map((company, i) => (
                    <Tr key={i}>
                      <Td>
                        {company?.name}
                      </Td>
                      <Td>
                        {Array.isArray(projectUsers) && projectUsers
                          .filter((project) => project.companyId === company.id)
                          .map((project, j) => (
                            <ul key={j}>
                              <li>
                                {project.name}
                              </li>
                            </ul>
                          ))}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCreate} onClose={() => setIsOpenCreate(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name:</FormLabel>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Email:</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel>Phone:</FormLabel>
              <Input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                onClick={() => setIsOpenCreate(false)}
              />
              <DynamicButton
                color={'green'}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={handleSave}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalAccess} onClose={() => {
        setModalAccess(false);
        setSelectedOptions([]);
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Access User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Page:</FormLabel>
              <Select
                isMulti
                name="colors"
                value={selectedOptions}
                onChange={setSelectedOptions}
                options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                color={'red'}
                icon={IoCloseSharp}
                title={'Cancel'}
                onClick={() => {
                  setIsOpenCreate(false)
                  setSelectedOptions([]);
                }}
              />
              <DynamicButton
                color={'green'}
                size={'sm'}
                action="custom"
                title={'Submit'}
                onClick={() => handleSaveAccessUser('create')}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialogV2
        isLoading={loading}
        isOpen={alertDelete}
        onClose={() => setAlertDelete(false)}
        title={`Delete user ${userActive?.email || ''}`}
        onAction={handleDeleteFile}
      />
    </>
  )
}

export default UsersPage