//format number

// format ini untuk semua price rates, tinggal ditambahkan currency nya Ex : Rp. $. DLL
export function formatPrice(value) {
  if (typeof value === 'number') {
    if (value < 1000) {
      let val = value.toFixed(2);
      if (val === '0.00') {
        val = '0';
      } else {
        val = parseFloat(val).toString();
      }
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  } else {
    return '0';
  }
}



//format IDR
export const formatIDR =(value) => {
  const idr = Intl.NumberFormat('en-ID', {
    style: 'currency',
    currency: 'IDR',
  });
  return idr.format(value);

}
//format USD

// Format Date YYYY-mm-dd hh:ii:ss
export const formatDate = (value) => {
  const d = new Date(value);
  const dformat = [d.getMonth()+1,
    d.getDate(),
    d.getFullYear()].join('/')+' '+
              [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');
  return dformat;
}


// Format Date YYYY-mm-dd hh:ii:ss
export const formatDateFirestore = (time) => {
  return new Date(time.seconds * 1000 + time.nanoseconds/1000000).toLocaleDateString() ;
}