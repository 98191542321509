import React from 'react';
import { Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';

const SearchBar = ({ data, handleSearch, sortBySearch, sortBy }) => {
  return (
    <InputGroup maxW='lg'>
      <InputLeftElement pointerEvents='none'>
        <FaSearch />
      </InputLeftElement>
      <Input
        type='text'
        placeholder='Search Here...'
        onChange={(q) => handleSearch(q.target.value)}
      />
    </InputGroup>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  data: PropTypes.any,
  sortBy: PropTypes.any.isRequired,
  handleSearch: PropTypes.func.isRequired,
  sortBySearch: PropTypes.func.isRequired,
};