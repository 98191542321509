/* eslint-disable react/prop-types */
import {
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Spacer,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { Rupiah } from '../../Utils/NumberUtils';

const FeatureItem = ({ icon, label, value }) => (
  <HStack>
    {icon && <BsCheckLg color="white" />}
    {/* <Text color={'gray.100'}>{label.toUpperCase()}</Text> */}
    <Text color={'gray.100'}>
      {label && label.charAt(0).toUpperCase() + label.slice(1)}
    </Text>
    <Spacer />
    <Text fontWeight={'bold'} textAlign="right">
      {value}
    </Text>
  </HStack>
);

function PricingPage({ features, discount }) {
  const excludeKeys = [
    'value',
    'id',
    'subscription',
    'videoGuidline',
    'groupSupport',
    'price',
    'lastUpdated',
    'lastUpdatedBy',
  ];

  const handleWhatsapp = () => {
    const message = encodeURIComponent(
      'Hi Admin Deoapp for Enterprise,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊'
    );
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6281241678382&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  };

  return (
    <Stack alignItems={'center'} justifyContent="center" spacing={2}>
      <Text fontSize={'lg'} color="gray.600" textAlign={'center'}>
        Pick the right plan for your business
      </Text>
      {/* <AppSponsor /> */}
      <Heading>up to </Heading>

      {discount > 0 ? (
        <Stack>
          <HStack>
            <Tag bgColor={'red.600'} p={2}>
              <Heading fontWeight={'extrabold'} as="del" color={'white'}>
                {Rupiah(features?.price)}
                {features?.value === 120 ? '+' : ''}
              </Heading>
            </Tag>
          </HStack>

          <HStack>
            <Tag bgColor={'blue.600'} p={2}>
              <Heading fontWeight={'extrabold'} color={'white'}>
                {Rupiah(features?.price - (features?.price * discount) / 100)}
                {features?.value === 120 ? '+' : ''}
              </Heading>
            </Tag>
            <Heading>All in</Heading>
          </HStack>
        </Stack>
      ) : (
        <HStack>
          <Tag bgColor={'blue.600'} p={2}>
            <Heading fontWeight={'extrabold'} color={'white'}>
              {Rupiah(features?.price)}
              {features?.value === 120 ? '+' : ''}
            </Heading>
          </Tag>
          <Heading>All in</Heading>
        </HStack>
      )}

      <Stack width="full">
        <Heading textAlign="center" fontWeight={'bold'} fontSize="lg">
          {features?.value !== 120 ? (
            Rupiah(features?.price - (features?.price * discount) / 100) +
            '/month'
          ) : (
            <Button
              bgColor={'blue.600'}
              onClick={() => handleWhatsapp()}
              color="white"
            >
              Contact Sales
            </Button>
          )}
        </Heading>

        <Stack bgColor={'blue.600'} p={5} color="white" borderRadius={'lg'}>
          {features !== '' &&
            Object?.keys(features).map(
              (key) =>
                !excludeKeys.includes(key) && (
                  <FeatureItem
                    key={key}
                    icon={features[key] === 'UNLIMITED'}
                    label={key}
                    value={features[key]}
                  />
                )
            )}
        </Stack>

        <Center>
          <Heading color={'gray.600'}> + </Heading>
        </Center>

        {features.videoGuidline && (
          <Stack>
            <HStack>
              <BsCheckLg color="black" />
              <Text color={'gray.800'} fontWeight={500}>
                Video Guideline
              </Text>
              <Spacer />
              <Text fontWeight={'bold'} color="green.600">
                Included
              </Text>
            </HStack>

            <Text fontSize="sm" color="gray.800">
              You will also receive video guideline from our team and group
              support.
            </Text>
          </Stack>
        )}

        {features.groupSupport && (
          <Stack>
            <HStack>
              <BsCheckLg color="black" />
              <Text color={'gray.800'} fontWeight={500}>
                Group Support
              </Text>
              <Spacer />
              <Text fontWeight={'bold'} color="green.600">
                Included
              </Text>
            </HStack>
            <Text fontSize="sm" color="gray.800">
              You will also get support management with team deoapp / us.
            </Text>
          </Stack>
        )}
      </Stack>
      <Divider />
    </Stack>
  );
}

export default PricingPage;
