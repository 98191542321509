/* eslint-disable react/prop-types */
import React from 'react'
import { Heading, Stack, Text, Button, HStack, Spacer, AspectRatio, Divider, Tooltip } from '@chakra-ui/react';
import { AiFillAlert } from 'react-icons/ai';
import moment from 'moment';


function CardApp({ item, onClick }) {
  return (
    <Stack shadow='md' m='1' p='1' borderRadius={'md'}>
      <AspectRatio maxW='560px' ratio={16 / 9}>
        <iframe
          title='embed'
          src={item?.video || 'https://www.youtube.com/embed/RdLkextTUsk?si=Vl8uXQw-mk1riMcs'}
          allowFullScreen
        />
      </AspectRatio>
      <Stack spacing={2} m={2}>
        <HStack>
          <Heading fontSize={'md'} noOfLines={1}>
            {item?.title?.toUpperCase()}
          </Heading>
          <Spacer />
          <Stack>
            {
              item.isExpired ? (
                <Tooltip cursor={'pointer'} label={'Your App is expired'} >
                  <Stack>
                    <AiFillAlert color="yellow" />
                  </Stack>
                </Tooltip>
              ) : null}

          </Stack>
        </HStack>
        <Divider />
        <Text noOfLines={2} fontSize={'small'}>
          {item?.description}
        </Text>

        <Text noOfLines={2} fontSize={'xx-small'}>
          Expired: {moment(item?.expiredApp?.seconds * 1000).format('DD-MM-YYYY HH:mm')}
        </Text>
        <Spacer />


        <Button pos='relative' bottom='0' w='full' colorScheme='green' onClick={onClick}>
                    Use this app
        </Button>
      </Stack>
    </Stack>
  )
}

export default CardApp