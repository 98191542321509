import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Apis/firebaseApi';
import ImageComponent from '../../Components/Image/ImageComponent';
import { uploadImage } from '../../Apis/firebaseFunctions';
import RichTextEditor from '../../Components/Quill/RichTextEditor';
import useUserStore from '../../Hooks/zustand/Store';

function LandingPageHome() {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([]);

  const departments = ['management', 'marketing', 'crm', 'social media', 'hrd'];

  const handleChange = (
    departementIndex,
    field,
    value,
    subDivisiIndex,
    divisiIndex
  ) => {
    // return console.log(departementIndex, field, value, subDivisiIndex, divisiIndex, "ddddd")
    setFormData((prevFormData) => {
      const updatedDivisi = [...prevFormData];
      if (field === 'banner') {
        updatedDivisi[departementIndex].banner = value;
      } else if (field === 'subtitle') {
        updatedDivisi[departementIndex].subtitle = value;
      } else if (field === 'title') {
        updatedDivisi[departementIndex].title = value;
      } else if (field === 'divisi') {
        updatedDivisi[departementIndex].divisi[subDivisiIndex].name = value;
      } else if (field === 'department') {
        updatedDivisi[departementIndex].department = value;
      }
      return updatedDivisi;
    });
  };

  const removeDepartement = (divisiIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData.splice(divisiIndex, 1);
      return updatedFormData;
    });
  };

  const handleDeleteImage = (departmentIndex, imageIndex) => {
    setFormData((prevData) => {
      const updatedFormData = [...prevData];
      updatedFormData[departmentIndex].images[imageIndex] = '';
      return updatedFormData;
    });
  };

  const submitImage = async (departmentIndex, file, imageIndex) => {
    try {
      globalState.setIsLoading(true);

      const res = (await uploadImage(file[0])).data;
      if (res.status) {
        setFormData((prevData) => {
          const updatedFormData = [...prevData];
          updatedFormData[departmentIndex].images[imageIndex] = res.data;
          return updatedFormData;
        });
        globalState.setIsLoading(false);
        toast({
          title: 'Upload Image',
          description: 'Image saved',
          status: 'success',
          duration: 2000,
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);

      toast({
        title: 'Image Size Error',
        description: error.message,
        status: 'error',
      });
    }
  };

  const handleContentChange = (value, departmentIndex) => {
    handleChange(departmentIndex, 'subtitle', value);
  };

  const addImage = (divisiIndex) => {
    setFormData((prevFormData) => {
      const updatedDivisi = [...prevFormData];
      updatedDivisi[divisiIndex].images.push('');
      return updatedDivisi;
    });
  };

  const deleteSectionImage = (departmentIndex, divisiIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[departmentIndex] = {
        ...updatedFormData[departmentIndex],
        images: updatedFormData[departmentIndex].images.filter(
          (image, index) => index !== divisiIndex
        ),
      };
      return updatedFormData;
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const dataSend = {
        data: formData,
      };
      await setDocumentFirebase('landing_page', 'home', dataSend);
      setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('landing_page', 'home');
      setFormData(res.data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Home Landing Page</Heading>
          <Spacer />
        </HStack>

        <SimpleGrid columns={2} gap="3">
          {formData.map((department, departmentIndex) => (
            <Box
              p="5"
              borderRadius="md"
              shadow="base"
              mt={5}
              key={departmentIndex}
            >
              <Flex w="100%" gap={5} direction={['column', 'row', 'row']}>
                <Box w={['100%', '50%', '50%']} p={5} borderRadius="md" mt={16}>
                  <FormControl mb="4">
                    <FormLabel>Title:</FormLabel>
                    <Flex>
                      <Input
                        type="text"
                        value={department.title}
                        // disabled
                        onChange={(e) =>
                          handleChange(departmentIndex, 'title', e.target.value)
                        }
                      />
                    </Flex>
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Department:</FormLabel>
                    <Flex>
                      <Select
                        defaultValue={department?.department}
                        onChange={(e) => {
                          handleChange(
                            departmentIndex,
                            'department',
                            e.target.value
                          );
                        }}
                      >
                        {departments.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </Select>
                    </Flex>
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Banner:</FormLabel>
                    <Flex>
                      <Input
                        type="text"
                        value={department.banner}
                        onChange={(e) =>
                          handleChange(
                            departmentIndex,
                            'banner',
                            e.target.value
                          )
                        }
                      />
                    </Flex>
                  </FormControl>
                </Box>

                <Box p={5} w={['100%', '50%', '50%']}>
                  <Flex justifyContent="flex-end" alignItems="center" mb={3}>
                    <DynamicButton
                      size="md"
                      action="delete"
                      title="Feature"
                      onClick={() => removeDepartement(departmentIndex)}
                    />
                  </Flex>

                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Image
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Flex
                          justifyContent="flex-end"
                          alignItems="center"
                          mb={3}
                        >
                          <DynamicButton
                            size="md"
                            action="create"
                            title="Add Image"
                            onClick={() => addImage(departmentIndex)}
                          />
                        </Flex>
                        <SimpleGrid
                          columns={department.images?.length > 2 ? 2 : 1}
                          spacing={3}
                        >
                          {department.images.map((image, imageIndex) => (
                            <Box key={imageIndex} flex="1">
                              <Box p={5} borderRadius="md" shadow="base">
                                <Stack spacing={3} align="center">
                                  <ImageComponent
                                    image={image} // Assuming image is the URL of the image
                                    name={formData.name}
                                    width="200px"
                                  />
                                  {image ? (
                                    <Button
                                      size="xs"
                                      colorScheme="red"
                                      onClick={() =>
                                        handleDeleteImage(
                                          departmentIndex,
                                          imageIndex
                                        )
                                      }
                                    >
                                      Clear Image
                                    </Button>
                                  ) : (
                                    <Box>
                                      <Input
                                        type="file"
                                        mb={4}
                                        onChange={(e) =>
                                          submitImage(
                                            departmentIndex,
                                            e.target.files,
                                            imageIndex
                                          )
                                        }
                                      />
                                      <Button
                                        size="xs"
                                        colorScheme="red"
                                        // onClick={() => handleDeleteImage(departmentIndex, imageIndex)}
                                        onClick={() =>
                                          deleteSectionImage(
                                            departmentIndex,
                                            imageIndex
                                          )
                                        }
                                      >
                                        Delete Section
                                      </Button>
                                    </Box>
                                  )}
                                </Stack>
                              </Box>
                            </Box>
                          ))}
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Flex>

              <Flex flexDirection={['column', 'row', 'row']} mb={19}>
                <Box flex="1">
                  <RichTextEditor
                    value={department?.subtitle}
                    onChange={(value) =>
                      handleContentChange(value, departmentIndex)
                    }
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>

        <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
          <DynamicButton
            color="green"
            isLoading={isLoading}
            size="sm"
            action="custom"
            title="Check console"
            onClick={() => console.log(formData)}
          />

          <DynamicButton
            color="green"
            isLoading={isLoading}
            size="sm"
            action="custom"
            title="Add New Section"
            onClick={() =>
              setFormData([
                ...formData,
                { title: '', subtitle: '', banner: '', images: [] },
              ])
            }
          />

          <DynamicButton
            color="green"
            isLoading={isLoading}
            size="sm"
            action="custom"
            title="Submit"
            onClick={handleSave}
          />
        </HStack>
      </Box>
    </>
  );
}

export default LandingPageHome;
