/* eslint-disable no-alert */
import {
  Badge,
  Box, Card, CardBody, Divider, Flex, FormControl, FormLabel, Grid, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import KnowladgeTable from './KnowladgeTable';
import BackButtons from '../../Components/Buttons/BackButtons';
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase, getUsersByIds, setDocumentFirebase } from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { IoCloseSharp } from 'react-icons/io5';
import _axios from '../../Apis/AxiosBarrier';
import useUserStore from '../../Hooks/zustand/Store';
import GridBox from '../../Components/Grid/GridBox';
import moment from 'moment';
import { moduleOption } from '../../Utils/dataUtils';
import AlertConfirmation from '../../Components/Components/AlertConfirmation';
import { arrayUnion } from 'firebase/firestore';
import CompanyBilling from './Components/CompanyBilling';

function CompanyView() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [users, setUsers] = useState([]);
  const [owner, setOwner] = useState([]);
  const [addOwner, setAddOwner] = useState('management');
  const [projects, setProjects] = useState([]);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [openModalAddModule, setOpenModalAddModule] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailUser, setEmailUser] = useState('');
  const [userData, setUserData] = useState('');
  const [detailCompany, setDetailCompany] = useState('');
  const [dataCreate, seDataCreate] = useState('');
  const globalState = useUserStore();
  const modalAddProject = useDisclosure();
  const [dataProject, setDataProject] = useState({
    name: '',
    description: '',
    modules: 'management',
  });
  const [dataDelete, setDataDelete] = useState({
    id: '',
    index: '',
  });

  const getData = async () => {
    try {
      globalState.setIsLoading(true);
      const result = await getSingleDocumentFirebase('companies', params?.companyId);
      setDetailCompany(result)
      const conditions = [
        { field: 'companyId', operator: '==', value: params?.companyId },
      ];
      const projects = await getCollectionFirebase('projects', conditions);
      const userIds = result?.users;
      const ownerIds = result?.owner;
      const usersData = await getUsersByIds(userIds);
      const ownerData = await getUsersByIds(ownerIds);
      globalState.setIsLoading(false);
      setOwner(ownerData)
      setUsers(usersData)
      setProjects(projects)
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const findUser = async () => {
    try {
      const conditions = [
        { field: 'email', operator: '==', value: emailUser },
      ];
      globalState.setIsLoading(true);
      const res = await getCollectionFirebase('users', conditions);
      globalState.setIsLoading(false);

      if (res.length > 0) {
        setUserData(res[0]);
      } else {
        return toast({
          title: 'User Not Found',
          description: 'The user with the provided email was not found.',
          status: 'warning',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while searching for the user.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const handleSave = async () => {
    if (userData === '') {
      return toast({
        title: 'Input Validation',
        description: 'Please fill in all fields',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    const dataUpdate = {
      'uid': userData.id,
      'companyId': params?.companyId,
    };

    try {
      const endpoint = dataCreate === 'User' ? '/company-userAdd' : '/company-ownerAdd';
      globalState.setIsLoading(true);
      await _axios.post(endpoint, dataUpdate);
      globalState.setIsLoading(false);
      setUserData('')
      setEmailUser('')
      getData()

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      setOpenModalAddUser(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const updateModule = async (arg, data) => {
    if (arg === 'delete') {
      const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
      if (!confirmDelete) {
        return
      }
    }

    if (arg === 'create') {

      if (detailCompany.module.includes(addOwner)) {
        return toast({
          title: 'Error',
          description: `${addOwner} already exists`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    }

    const dataUpdate = {
      collection: 'companies',
      doc: params?.companyId,
      field: 'module',
      values: [
        data ? data : addOwner
      ]
    }
    try {
      globalState.setIsLoading(true);
      const endpoint = arg === 'create' ? '/company-addArrayField' : '/company-removeFromArrayField';
      await _axios.post(endpoint, dataUpdate);
      globalState.setIsLoading(false);
      getData()

      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
      setOpenModalAddModule(false)
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const setOpenModal = (arg) => {
    setOpenModalAddUser(true)
    seDataCreate(arg)
  }

  const closeModal = () => {
    setOpenModalAddUser(false);
    setUserData('')
    setEmailUser('')
  };


  const deleteUser = async (id, type) => {
    const dataUpdate = {
      'uid': id,
      'companyId': params?.companyId,
    };

    try {
      const confirmDelete = window.confirm('If you delete this data you can\'t Undo');
      if (confirmDelete) {
        const endpoint = type === 'user' ? '/company-userRemove' : '/company-ownerRemove';
        globalState.setIsLoading(true);
        await _axios.post(endpoint, dataUpdate);
        globalState.setIsLoading(false);
        getData()

        toast({
          title: 'Data Saved',
          description: 'The data has been successfully saved.',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const deleteProject = async () => {
    try {
      globalState.setIsLoading(true);
      await deleteDocumentFirebase('projects', dataDelete.id);
      toast({
        title: 'Admin',
        description: 'Success delete Template',
        position: 'top-right',
        status: 'success',
      });
      onClose()
      getData()
      globalState.setIsLoading(false);
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Admin',
        description: 'Failed to delete Template',
        position: 'top-right',
        status: 'error',
      });
    }
  };

  const openConfirmationAlert = (id, index) => {
    onOpen();
    setDataDelete({
      id: id,
      index: index,
    });
  };

  const handleAddProject = async () => {
    globalState.setIsLoading(true);

    try {
      if (dataProject?.name === '' || dataProject?.description === '' || dataProject?.modules?.length === 0) {
        return toast({
          title: 'Deoapp CRM',
          description: 'Please fill the form',
          status: 'error',
          duration: 3000,
        });
      } else {
        const data = {
          ...dataProject,
          owner: [globalState?.uid],
          users: [globalState?.uid],
          modules: arrayUnion(dataProject?.modules)
        };

        const res = await addDocumentFirebase('projects', data, params?.companyId);

        const userData = {
          name: globalState?.name,
          email: globalState?.email,
          createdAt: new Date(),
          id: globalState?.uid,
        };

        await setDocumentFirebase(`projects/${res}/users`, globalState?.uid, userData);

        globalState.setCurrentProject(res);

        toast({
          title: 'Deoapp CRM',
          description: 'Project Created!',
          status: 'success',
          duration: 3000,
        });
        getData();
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } finally {
      // setIsLoading(false);
      globalState.setIsLoading(false);
    }
  };


  useEffect(() => {
    getData();
  }, []);

  return (
    <Stack p={[1, 1, 5]}>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'} textTransform={'capitalize'}>Detail Company {detailCompany?.name}</Heading>
          <Spacer />
        </HStack>

        <Divider />
        <Card borderRadius={'md'} mt={5}>
          <CardBody >
            <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
              <GridBox title={'Expired At'} value={detailCompany?.expired_at ? moment(detailCompany.expired_at?.seconds * 1000).format('LLLL') : 'Not Yet Used'} />
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={3} mt={2} borderBottom={'1px'} borderColor={'gray.300'}>
              <GridBox title={'Coupon'} value={detailCompany?.coupon ? detailCompany?.coupon : 'Not using a coupon '} />
            </Grid>
          </CardBody>
        </Card>
        <Grid templateColumns={'repeat(2, 1fr)'} mt={5} gap={5}>
          <Stack overflowX="auto">
            <Card >
              <CardBody>
                <HStack >
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      User
                    </Heading>
                    <Badge colorScheme='yellow'>
                      Total Users {users?.length}
                    </Badge>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    variant={'solid'}
                    title={'Add User'}
                    onClick={() => setOpenModal('User')}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  boxShadow={'md'}
                  maxHeight="400px"
                  overflowY="auto"
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr style={{ position: 'sticky', top: 0, zIndex: 1, }}>
                        <Th>Name</Th>
                        <Th>Phone Number</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {users?.map((x, i) => (
                        <Tr key={i} fontSize={'sm'} fontWeight={500}>
                          <Td>
                            {x?.name}
                            <Text fontSize="2xs">ID: {x?.id}</Text>
                          </Td>
                          <Td>
                            {x?.phoneNumber}
                          </Td>
                          <Td>
                            {x?.email}
                          </Td>
                          <Td>
                            <DynamicButton
                              size={'xs'}
                              action="delete"
                              variant={'solid'}
                              // title={'Add User'}
                              onClick={() => deleteUser(x.id, 'user')}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

            <Card borderRadius={'md'} >
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Project
                    </Heading>
                    <Badge colorScheme='yellow'>
                      Total Projects {projects?.length}
                    </Badge>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    title={'Create'}
                    variant={'solid'}
                    onClick={() => modalAddProject.onOpen()}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  boxShadow={'md'}
                  maxHeight="400px"
                  overflowY="auto"
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr style={{ position: 'sticky', top: 0, zIndex: 1, }}>
                        <Th>Name</Th>
                        <Th textAlign="center">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {projects?.map((x, i) => (
                        <Tr key={i} fontSize={'sm'} fontWeight={500}>
                          <Td>
                            {x?.name}
                            <Text fontSize="2xs">ID: {x?.id}</Text>
                          </Td>
                          <Td textAlign="center">
                            <HStack spacing={2} justifyContent="center">
                              <Link to={`/companies/${params?.companyId}/projects/${x?.id}`}>
                                <DynamicButton
                                  size={'xs'}
                                  action="read"
                                  variant={'solid'}
                                />
                              </Link>
                              <DynamicButton
                                size={'xs'}
                                action="delete"
                                variant={'solid'}
                                onClick={() => openConfirmationAlert(x.id, i)}

                              // onClick={() => deleteProject(x.id)}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          

          </Stack>
          <Stack overflowX="auto">
            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Module
                    </Heading>
                    <Badge colorScheme='yellow'>
                      Total Module {detailCompany?.module?.length}
                    </Badge>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    title={'Add module'}
                    variant={'solid'}
                    // onClick={() => setOpenModalAddUser(true)}
                    onClick={() => setOpenModalAddModule(true)}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  boxShadow={'md'}
                  maxHeight="400px"
                  overflowY="auto"
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th textAlign="center">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {detailCompany?.module?.map((x, i) => (
                        <Tr key={i} fontSize={'sm'} fontWeight={500}>
                          <Td>
                            <Text style={{ textTransform: 'capitalize' }}>
                              {x}
                            </Text>
                          </Td>
                          <Td textAlign="center">
                            <DynamicButton
                              size={'xs'}
                              variant={'solid'}
                              action="delete"
                              onClick={() => updateModule('delete', x)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
            <Card borderRadius={'md'}>
              <CardBody>
                <HStack mb={3}>
                  <Box>
                    <Heading
                      as='h2' size='md'
                    >
                      Owner
                    </Heading>
                    <Badge colorScheme='yellow'>
                      Total Owners {owner?.length}
                    </Badge>
                  </Box>
                  <Spacer />
                  <DynamicButton
                    size={'sm'}
                    action="create"
                    title={'Add Owner'}
                    variant={'solid'}
                    // onClick={() => setOpenModalAddUser(true)}
                    onClick={() => setOpenModal('Owner')}
                  />
                </HStack>
                <TableContainer
                  mt={5}
                  boxShadow={'md'}
                  maxHeight="400px"
                  overflowY="auto"
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Phone</Th>
                        <Th>Email</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {owner?.map((x, i) => (
                        <Tr key={i} fontSize={'sm'} fontWeight={500}>
                          <Td>
                            {x?.name}
                            <Text fontSize="3xs">ID: {x?.id}</Text>
                          </Td>
                          <Td>
                            {x?.phone}
                          </Td>
                          <Td>
                            {x?.email}
                          </Td>
                          <Td>
                            <DynamicButton
                              size={'xs'}
                              variant={'solid'}
                              action="delete"
                              onClick={() => deleteUser(x?.id, 'owner')}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>

          </Stack>

          <Stack overflowX="auto">
            <CompanyBilling companyId={params?.companyId} ownersCompany={owner} detailCompany={detailCompany} projectCompany={projects}/>
          </Stack>
        </Grid>

        <Modal isOpen={openModalAddUser} onClose={() => closeModal()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New {dataCreate} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Flex gap={3} direction={['column-reverse', 'row', 'row']}>
                  <Input type="text" name="company" value={emailUser} onChange={(e) => setEmailUser(e.target.value)} />

                  <Spacer />
                  <Stack spacing={2}>
                    <DynamicButton
                      size={'md'}
                      action="custom"
                      color={'green'}
                      title={'Search'}
                      onClick={() => findUser(true)}
                    />
                  </Stack>
                </Flex>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
                <DynamicButton
                  size={'sm'}
                  action="custom"
                  color={'red'}
                  icon={IoCloseSharp}
                  title={'Cancel'}
                  onClick={() => closeModal()}
                />
                {userData ? (
                  <DynamicButton
                    color={'green'}
                    size={'sm'}
                    action="custom"
                    title={'Submit'}
                    onClick={handleSave}
                  />
                ) : null}

              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={openModalAddModule} onClose={() => setOpenModalAddModule(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Module </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb="4">
                <FormLabel>Module :</FormLabel>
                <Flex gap={3} direction={['column-reverse', 'row', 'row']}>

                  <Select
                    value={addOwner}
                    onChange={(e) => setAddOwner(e.target.value)}
                    required
                  >
                    {moduleOption().map((option, index) => (
                      <option key={index} value={option?.value}>{option?.name}</option>
                    ))}
                  </Select>
                  <Spacer />
                  <Stack spacing={2}>
                    <DynamicButton
                      size={'md'}
                      action="custom"
                      color={'green'}
                      title={'Add'}
                      onClick={() => updateModule('create')}
                    />
                  </Stack>
                </Flex>
              </FormControl>
            </ModalBody>

            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={modalAddProject.isOpen}
          onClose={modalAddProject.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Project</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Fill the form correctly</Text>

              <FormControl>
                <FormLabel fontSize={14} my={3}>
                  Project Name
                  <Text as={'span'} color={'gray.400'} fontStyle={'italic'}>
                    {'  '}
                    {'('}your business name{')'}
                  </Text>
                </FormLabel>
                <Input
                  onChange={(e) =>
                    setDataProject({ ...dataProject, name: e.target.value })
                  }
                  placeholder={'Enter project/business name'}
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={14} my={3}>
                  Project Description
                </FormLabel>
                <Input
                  onChange={(e) =>
                    setDataProject({
                      ...dataProject,
                      description: e.target.value,
                    })
                  }
                  placeholder={'Enter project/business description'}
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel fontSize={14} my={3}>Module :</FormLabel>
                <Flex gap={3} direction={['column-reverse', 'row', 'row']}>

                  <Select
                    onChange={(e) =>
                      setDataProject({ ...dataProject, modules: e.target.value })
                    }
                    required
                  >
                    {moduleOption().map((option, index) => (
                      <option key={index} value={option.value}>{option.name}</option>
                    ))}
                  </Select>
                </Flex>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <DynamicButton
                size={'sm'}
                action="create"
                title={' Add New Project'}
                onClick={() => handleAddProject()}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>

        <AlertConfirmation
          isLoading={isLoading}
          isOpen={isOpen}
          onClose={onClose}
          title={'Delete Project'}
          onAction={deleteProject}
          description={'Do you want to delete this project ?'}
        />
      </Box>
    </Stack>
  );
}

export default CompanyView;
