import {
  Box, Button, Heading, HStack, Spacer, useDisclosure, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Skeleton, Image, InputGroup, Input, InputRightElement, AspectRatio, Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  deleteDocumentFirebase, getCollectionFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/zustand/Store';
import AlertConfirmation from '../../Components/Components/AlertConfirmation';
import ImageModal from '../../Components/Components/ImageModal';
import moment from 'moment'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import AppsCreate from './AppsCreate';
import { formatIDR } from '../../Utils/numberUtil';
import { Link } from 'react-router-dom';

function AppsPage() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [loadingStates, setLoadingStates] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popupImage, setPopupImage] = useState(false);
  const [image, setImage] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const [dataDelete, setDataDelete] = useState({
    id: '',
    index: '',
  });
  const [editData, setEditData] = useState(null);
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSort = (sort, desc, type) => {
    setSort({ sort: sort, desc: desc, type: type });
    getData(desc, null, type)
  };

  const getData = async (sort, search, arg) => {
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [
        // { field: 'type', operator: '==', value: 'landingPage' },
      ];
      if (search) {
        conditions.push({ field: 'title', operator: '==', value: search });
      }
      let sortBy = {}

      if (arg === 'createdAt') {
        sortBy = sort ? { field: 'createdAt', direction: 'desc' } : { field: 'createdAt', direction: 'asc' };
      } else {
        sortBy = { field: 'title', direction: 'asc' };
      }
      const limitValue = startIndex + itemsPerPage;

      const res = await getCollectionFirebase(
        'apps',
        conditions,
        sortBy,
        limitValue
      );
      setData(res);

      const totalContacts = await getCollectionFirebase(
        'apps',
        conditions
      );
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const onOpenModal = (data) => {
    setEditData(data);
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setEditData(null);
    setIsOpenModal(false);
  };

  const onSubmitModal = async () => {
    getData();
  };

  const openConfirmationAlert = (id, index) => {
    onOpen();
    setDataDelete({
      id: id,
      index: index,
    });
  };

  const deleteTemplateLp = async () => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dataDelete.index] = true;
      return newStates;
    });

    await deleteDocumentFirebase('apps', dataDelete.id);
    onClose();
    getData();
    toast({
      title: 'Admin',
      description: 'Success delete Template',
      position: 'top-right',
      status: 'success',
    });
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dataDelete.index] = false;
      return newStates;
    });
  };

  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage, getCollectionFirebase, globalState.currentCompany,]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Apps Data Page</Heading>
          <Spacer />
        </HStack>

        <Box p='5' borderRadius='md' shadow='base' mt={5}>
          <HStack mb={5}>
            <Spacer />
            <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type='text'
                placeholder='Search Name'
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getData('', searchValue, 'filter');
                  }
                }}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={() => getData('', searchValue, 'filter')}>
                  Search
                </Button>
              </InputRightElement>
            </InputGroup>

            {/* <DynamicButton
              size={'sm'}
              action='create'
              title={'Create'}
              onClick={() => onOpenModal(null)}
            /> */}
            <Link to={'/general/apps/create'}>
              <DynamicButton
                size={'sm'}
                action='create'
                title={'Create'}
              // onClick={() => onOpenModal(null)}
              />
            </Link>
          </HStack>

          <TableContainer
            // border={'1px'}
            // borderRadius={'sm'}
            // borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Video </Th>
                  <Th>Title </Th>
                  <Th>Price </Th>
                  <Th>Price Plan </Th>
                  <Th>Link Web </Th>
                  <Th cursor='pointer'
                    onClick={() => {
                      handleSort('avgScore', !sort.desc, 'createdAt');
                    }}
                  >Created At {' '}
                    {sort.sort === 'avgScore' ? (
                      sort?.desc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      <></>
                    )}</Th>
                  <Th textAlign='center'>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      {x?.video && (
                        <AspectRatio minW='160px' maxH='90px' ratio={1}>
                          <iframe
                            title='naruto'
                            src={x?.video}
                            allowFullScreen
                          />
                        </AspectRatio>
                      )}
                    </Td>
                    <Td> 
                      {x?.title && x?.title.length > 20 ? `${x?.title.slice(0, 20)}...` : x?.title}
                    </Td>
                    <Td> {x?.price ? formatIDR(x?.price) : ''} </Td>
                    <Td> {x?.price_plan} </Td>
                    <Td>
                      <Text color='blue.600'>
                        <a href={x?.linkRedirectAfterPay} target='_blank' rel='noopener noreferrer' >
                          {x?.linkRedirectAfterPay && x?.linkRedirectAfterPay.length > 20 ? `${x?.linkRedirectAfterPay.slice(0, 20)}...` : x?.linkRedirectAfterPay}
                        </a>
                      </Text>
                    </Td>

                    <Td>{moment(x?.createdAt?.seconds * 1000).format('LLLL')}</Td>
                    <Td>
                      <HStack spacing={2} justifyContent='center'>
                        <Link to={`/general/apps/${x.id}`}>
                          <DynamicButton
                            size='sm'
                            action='update'
                          />
                        </Link>
                        <DynamicButton
                          size='sm'
                          action='delete'
                          isLoading={loadingStates[i]}
                          onClick={() => openConfirmationAlert(x.id, i)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack alignItems={'center'} justifyContent='center' mt={3}>
            {currentPage < totalPages && (
              <Button
                colorScheme={'green'}
                fontSize='sm'
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Stack>
        </Box>
      </Box>

      <AppsCreate
        isOpen={isOpenModal}
        onClose={onCloseModal}
        editData={editData}
        resSave={onSubmitModal}
      />

      <ImageModal
        isOpen={popupImage}
        onClose={() => setPopupImage(false)}
        image={image}
      />

      <AlertConfirmation
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        title={'Delete Apps'}
        onAction={deleteTemplateLp}
        description={'Do you want to delete this Apps ?'}
      />
    </>
  );
}

export default AppsPage;
