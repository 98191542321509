/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Badge,
  Stack,
  Flex,
  useToast,
  Heading,
} from '@chakra-ui/react';
// import { formatFrice } from '../../../Utils/numberUtil';
import {
  getSingleDocumentFirebase,
} from '../../../Apis/firebaseApi';
import moment from 'moment';
import { formatFrice } from '../../../Utils/Helper';

const ModalDetailOrder = ({ isOpen, onClose, detailOrder }) => {
  const toast = useToast();
  const [dataOrder, setDataOrder] = useState({});

  const getDataBilling = async () => {
    if(detailOrder?.companyId === undefined) return 
    console.log(detailOrder?.id)

    try {
      const res = await getSingleDocumentFirebase('companies', detailOrder?.companyId)
      setDataOrder(res || null)
    } catch (error) {
      toast({
        title: 'Error!',
        description: error?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }


  useEffect(() => {
    getDataBilling()
  
    return () => {
      setDataOrder({})
    }
  }, [])
  


  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack>
            <Heading size='md'>Detail Order</Heading>
            <Text fontSize={12} fontWeight={500} color={'gray.500'}> #{detailOrder?.id}</Text>
          </Stack>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={1}>
            <Flex justifyContent='flex-end'>
              <Text color='gray.500'>
                {moment(detailOrder?.createdAt?.seconds * 1000).format('LLL')}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'} >
              <Text color='gray.500'>User Id:</Text>
              <Text fontWeight={'semibold'}>{detailOrder?.uid}</Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'} >
              <Text color='gray.500'>Name:</Text>
              <Text
                size={'md'}
                textTransform={'capitalize'}
                fontWeight={'semibold'}
              >
                {detailOrder?.contact_information?.name}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Email:</Text>
              <Text textTransform={'lowercase'} fontWeight={'semibold'}>
                {detailOrder?.contact_information?.email}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Phone Number:</Text>
              <Text fontWeight={'semibold'}>
                {detailOrder?.contact_information?.phone_number || '-'}
              </Text>
            </Flex>
  
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Last Updated:</Text>
              <Text fontWeight={'semibold'}>
                {moment(detailOrder?.lastUpdated?.seconds * 1000).format('LLL')}
              </Text>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Payment Status:</Text>
              <Badge
                colorScheme={
                  detailOrder?.transaction_status === 'pending' ? 'orange' : 'green'
                }
              >
                {detailOrder?.transaction_status
                  ? detailOrder?.transaction_status
                  : 'No Data'}
              </Badge>
            </Flex>
            <Flex justifyContent='space-between' alignItems={'center'}>
              <Text color='gray.500'>Payment Method:</Text>
              <Badge colorScheme={'green'}>
                {' '}
                {detailOrder?.type}
              </Badge>
            </Flex>
          
          </Stack>


          <Stack my={4} spacing={3}>
            <Text fontWeight={'semibold'}>Company in Billing</Text>
            <Stack>
              <Text>Billing Product Name : {detailOrder?.name || ''}</Text>
              <Text>Company ID : {dataOrder?.name || ''}</Text>
              <Text>Jumlah User : {dataOrder?.users?.length || 0}</Text>
              <Text>Duration : {detailOrder?.duration || ''}</Text>
            </Stack>
            <Stack spacing={1}>

              <Flex justifyContent='space-between' my={4}>
                <Text fontWeight={'semibold'}>Total</Text>
                <Text fontWeight={'semibold'}>Rp. {formatFrice(parseFloat(detailOrder?.gross_amount || 0))}</Text>
              </Flex>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDetailOrder;

