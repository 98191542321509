import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getCollectionFirebase, getSingleDocumentFirebase, setDocumentFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import { moduleOption } from '../../Utils/dataUtils';
import { useParams } from 'react-router-dom';

function AppsCreatePage() {
  const toast = useToast();
  const [variants, setVariants] = useState([]);
  const globalState = useUserStore();
  const [products, setProducts] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(false);
  const [inputData, setInputData] = useState(
    {
      publish: true,
      title: '',
      price: 0,
      price_plan: '',
      video: '',
      productId: '',
      variantId: '',
      linkRedirectAfterPay: '',
      module: 'management',
      description: '',
      equipment: [''],
      // "id": "testing Apps",
      // "title": "testing Apps",
      // "link": "https://www.youtube.com/embed/FKnEdKO02Y4?si=bRZEVJGvTOhg57Sh",
      // "price_plan": "Monthly",
      // "linkRedirectAfterPay": "https://business.deoapp.com/",
      // "productId": "awWcwu8dDGiNbGKrAEPg",
      // "publish": true,
      // "equipment": [
      //     "10 user",
      //     "20 critical hit",
      //     "+ 5 damage",
      //     "+10 waepon"
      // ],
      // "video": "https://www.youtube.com/embed/BD12sl5DwDI?si=8Ra9ltDxRTctrQXB",
      // "module": "chat",
      // "description": "bagus banget pokoknya ",
      // "variantId": "bnfXDRhXotakEmeADMvX",
      // "price": 299000
    }
  );

  const handlePromptChange = (index, value) => {
    const updatedPrompts = [...inputData.equipment];
    updatedPrompts[index] = value;
    setInputData({ ...inputData, equipment: updatedPrompts });
  };

  const addPrompt = () => {
    setInputData({
      ...inputData,
      equipment: [...inputData.equipment, ''],
    });
  };

  const removePrompt = (index) => {
    const updatedPrompts = [...inputData.equipment];
    updatedPrompts.splice(index, 1);
    setInputData({ ...inputData, equipment: updatedPrompts });
  };

  const handleSwitchChange = () => {
    setInputData({ ...inputData, publish: !inputData.publish });
  };


  const saveData = async () => {
    try {
      if (
        inputData.title === '' ||
                inputData.price === '' ||
                inputData.price_plan === '' ||
                inputData.description === '' ||
                inputData.video === ''
      ) {
        return toast({
          title: 'Input Validation',
          description: 'Please fill in all fields',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }


      // return console.log(inputData, "ffffff")

      // const isValidLink = isValidURL(inputData.link);
      // if (!isValidLink) {
      //     return toast({
      //         title: "Input Validation",
      //         description: "Please enter a valid accessible link",
      //         status: "error",
      //         position: "top-right",
      //         isClosable: true,
      //     });
      // }

      const isYouTubeEmbedLink = inputData.video.includes('youtube.com/embed');
      if (isYouTubeEmbedLink) {
        let url = inputData.video;
        if (inputData.video.includes('<iframe')) {
          const startIdx = inputData.video.indexOf('src="') + 5;
          const endIdx = inputData.video.indexOf('"', startIdx);
          url = inputData.video.substring(startIdx, endIdx);
        }
        inputData.video = url; // Memperbarui formData.video dengan URL yang berhasil dipotong
      } else {
        return toast({
          title: 'Input Validation',
          description: 'Please enter a valid YouTube video URL',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
      // return console.log(inputData, "ffffff")
      globalState.setIsLoading(true);

      inputData.createdAt = new Date();
      if (id) {
        await updateDocumentFirebase('apps', id, inputData);
      }else{
        await setDocumentFirebase(
          'apps',
          inputData.title,
          inputData,
        );
      }

      toast({
        title: 'Data Saved',
        description: 'Your data has been successfully saved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      window.location.href = '/general/apps';
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while saving data. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      globalState.setIsLoading(false);
    }
  };

  const getVariant = async (productId) => {
    const conditions = [{ field: 'productId', operator: '==', value: productId }];
    const sortBy = { field: 'name', direction: 'asc' };
    const getVariant = await getCollectionFirebase('product_variants', conditions, sortBy);
    const dataProduct = products.filter((e) => e.id === productId)
    setInputData((prevState) => ({
      ...prevState,
      module: dataProduct[0]?.modules[0]
    }));
    setVariants(getVariant)
  }

  const getDetailVariant = async (variantId) => {
    try {
      const getData = await getSingleDocumentFirebase('product_variants', variantId);
      setInputData((prevState) => ({
        ...prevState,
        price: getData.price_int
      }));
    } catch (error) {
      toast({
        title: 'Data Saved',
        description: error?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const { id } = useParams();

  const getData = async () => {
    try {
      if (id) {
        const getDataataApp = await getSingleDocumentFirebase('apps', id)
        setInputData(getDataataApp)
      }
      const conditions = [
        { field: 'companyId', operator: '==', value: '8NCG4Qw0xVbNR6JCcJw1' },
      ];
      const sortBy = { field: 'name', direction: 'asc' };
      const getProduct = await getCollectionFirebase('product', conditions, sortBy);
      const filteredProducts = getProduct.filter((product) => 
        product.projectId === 'hk1BcSSZMoWI9GBLJBGA' || product.projectId === '8NCG4Qw0xVbNR6JCcJw1'
      );
            
      // console.log(filteredProducts);
            
      setProducts(filteredProducts)
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box>
        <BackButtons />
        <HStack>
          <Box>
            <Heading>Create New Apps</Heading>
          </Box>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <Flex
            w={'100%'}
            gap={5}
            direction={['column', 'row', 'row']}
          >
            <Box
              w={['100%', '50%', '50%']}
              p={5}
              borderRadius="md"
              shadow={'base'}
            >
              <FormControl mb="4">
                <FormLabel>Publish</FormLabel>
                <Switch
                  size='md'
                  onChange={handleSwitchChange}
                  isChecked={inputData.publish}
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  placeholder='xxxx'
                  value={inputData.title}
                  onChange={(e) => setInputData({ ...inputData, title: e.target.value })}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel style={{ display: 'flex', alignItems: 'center' }}>Price <Text fontSize='xs' color='tomato'>(*IDR)</Text> : </FormLabel>
                <Flex>
                  <Input
                    type="number"
                    disabled
                    value={inputData.price}
                    onChange={(e) => setInputData({ ...inputData, price: e.target.value })}
                  />
                </Flex>
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Price Plan</FormLabel>
                <Input
                  type="text"
                  name="price_plan"
                  placeholder='Monthly / Account / Page / etc'
                  value={inputData.price_plan}
                  onChange={(e) => setInputData({ ...inputData, price_plan: e.target.value })}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Video (link Embed Youtube)</FormLabel>
                <Input
                  type="text"
                  name="video"
                  placeholder='https://www.youtube.com/embed'
                  value={inputData.video}
                  onChange={(e) => setInputData({ ...inputData, video: e.target.value })}
                  required
                />
              </FormControl>
            </Box>

            <Box
              p={5}
              w={['100%', '50%', '50%']}
              shadow={'base'}
            >
              <FormControl mb="4">
                <FormLabel>Product by CRM</FormLabel>
                <select
                  onChange={(e) => {
                    setInputData({ ...inputData, productId: e.target.value });
                    getVariant(e.target.value);
                    setSelectedDepartment(true)
                  }}
                  value={inputData.department}
                  style={{ width: '100%', padding: '0.5rem' }}
                >
                  <option value="">Select Product</option>
                  {products.map((product, index) => (
                    <option key={index} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Variant by CRM:</FormLabel>
                <select
                  onChange={(e) => {
                    getDetailVariant(e.target.value)
                    setInputData({ ...inputData, variantId: e.target.value })
                  }
                  }
                  value={inputData.variantId}
                  style={{ width: '100%', padding: '0.5rem' }}
                >
                  <option value="" disabled={!selectedDepartment}>
                    {selectedDepartment ? 'Select Variant' : 'Select Product First'}
                  </option>
                  {variants.map((variant, index) => (
                    <option key={index} value={variant.id}>
                      {variant.name}
                    </option>
                  ))}
                </select>
              </FormControl>


              <FormControl mb="4">
                <FormLabel>Link Redirect After Payment :</FormLabel>
                <Flex>
                  <Input
                    type="text"
                    value={inputData.linkRedirectAfterPay}
                    placeholder='https://xxxxx.com/xxxxx'
                    onChange={(e) => setInputData({ ...inputData, linkRedirectAfterPay: e.target.value })}
                  />
                </Flex>
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Module :</FormLabel>
                <Flex>
                  <Select
                    value={inputData.module}
                    disabled
                    //   onChange={(e) => handleChange(section, itemIndex, 'module', e.target.value, 'monthly')}
                    onChange={(e) => setInputData({ ...inputData, module: e.target.value })}

                    required
                  >
                    {moduleOption().map((option, index) => (
                      <option key={index} value={option.value}>{option.name}</option>
                    ))}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl mb="4">
                <FormLabel>Description :</FormLabel>
                <Flex>
                  <Input
                    type="text"
                    value={inputData.description}
                    placeholder='Write a description for this description landing page ... '
                    onChange={(e) => setInputData({ ...inputData, description: e.target.value })}
                  />
                </Flex>
              </FormControl>
            </Box>
          </Flex>

          <Stack>
            <FormControl mt="5">
              <FormLabel ml={2}>Equipment / Benefit User</FormLabel>
              <Flex justifyContent="flex-end" alignItems="center" mb={3}>
                <Button size="sm" colorScheme="green" onClick={addPrompt} mt={2}>
                                    +  Add
                </Button>
              </Flex>
              {inputData.equipment.map((prompt, index) => (
                <Flex key={index} align="center" mt={3}>
                  <Text mr={3}>{index + 1}.</Text>
                  <Input
                    type="text"
                    value={prompt}
                    onChange={(e) => handlePromptChange(index, e.target.value)}
                    required
                  />
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => removePrompt(index)}
                    ml={2}
                  >
                                        Remove
                  </Button>
                </Flex>
              ))}
            </FormControl>
          </Stack>

          <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={saveData}
            />
          </HStack>
        </Box>
      </Box>
    </>
  );
}

export default AppsCreatePage;
