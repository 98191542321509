import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import { db } from '../../Config/firebase';
import useUserStore from '../../Hooks/zustand/Store';

function NotificationPage() {
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const navigate = useNavigate()
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 8;
  const limitValue = startIndex + itemsPerPage;

  useEffect(() => {
    if (globalState?.currentCompany) {
      const q = query(
        collection(db, 'notification'),
        where('userId', 'array-contains', globalState?.uid),
        where('companyId', '==', globalState?.currentCompany),
        limit(limitValue),
        orderBy('createdAt', 'desc')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const qData = [];
        querySnapshot.forEach((doc) => {
          qData.push({ id: doc.id, ...doc.data() });
        });
        setData(qData);
      });
      return () => {
        unsubscribe();
        setData([]);
      };
    }
  }, [globalState?.currentCompany, startIndex]);

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const handleNavigate = (data) => {
    if(data?.collectionField === 'kanban'){
      navigate(`/kanban/view/${data.parentId}/${data.parentName}/${data.collectionId}`)
    }
  }

  return (
    <Stack p={[1, 1, 5]} spacing={10} minH="100vh">
      <Stack>
        <BackButtons />
        <Text fontWeight={'bold'} fontSize="3xl">
                    🚀 Welcome to Business Notification
        </Text>
        <Text>
                    Make your business more efficient with the help from Deoapp
                    Productivity.
        </Text>
        <Divider />
      </Stack>

      <Stack w="full">
        <Grid templateColumns={{ base: '1fr', md: '1fr' }} gap={3}>

          <Stack
            overflow={'auto'}
            h="600px"
            spacing={2}
            borderTopWidth={3}
            borderBottomWidth={3}
            borderColor="green.500"
            py={4}
            px={2}
            borderRadius="md"
            shadow="md"
          >
            <Stack>
              <Text fontSize={'md'} fontWeight={500} textTransform="capitalize">
                                Notification User
              </Text>
              <Stack gap={1}>
                {data.length > 0 ? (
                  data.map((x, index) => {
                    return (
                      <HStack
                        key={index}
                        shadow={'md'}
                        borderRadius={'md'}
                        p={4}
                        spacing={5}
                        cursor='pointer'
                        onClick={() => handleNavigate(x)}
                      >
                        <Stack>
                          <Avatar
                            cursor={'pointer'}
                            size="sm"
                            src={x?.image}
                            alt={x?.title}
                          >
                            <AvatarBadge boxSize="1.2em" bg="green.500" />
                          </Avatar>
                        </Stack>
                        <Stack spacing={0}>
                          <Text fontWeight={500} textTransform="capitalize">
                            {x.title}
                          </Text>
                          <Text fontSize={'sm'} color={'gray.600'}>
                            {x.action}{' '}
                            <b>{x?.to || ''}</b> at <b>{x?.body || ''}</b>{' '}
                          </Text>
                        </Stack>
                        <Spacer />
                        <Stack>
                          <Text
                            fontSize={'xx-small'}
                            textTransform="capitalize"
                            color={'gray.600'}
                          >
                            {moment(x.createdAt.seconds * 1000).fromNow()}
                          </Text>
                        </Stack>
                      </HStack>
                    );
                  }) ) : (
                  <Center>
                    <Text fontSize={'lg'} fontWeight={500} color='gray.600'>No have data</Text>
                  </Center>
                )}
              </Stack>
              <Stack alignItems={'center'} justifyContent="center" py={5}>
                {data?.length > 0 && (
                  <Box>
                    {data?.length > startIndex && (
                      <Button
                        colorScheme={'blue'}
                        onClick={handleLoadMore}
                        size="sm"
                      >
                                                Load More
                      </Button>
                    )}
                  </Box>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default NotificationPage;
