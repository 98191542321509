import React from 'react'
import MemberIndex from '../Pages/Member/MemberIndex';
import CouponPage from '../Pages/Coupon/CouponPage';

const MemberRouter = [
  {
    path: '/member',
    element: <MemberIndex />,
  },
  {
    path: '/member/coupon',
    element: <CouponPage />,
  },

];

export default MemberRouter;
