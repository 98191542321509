/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Input,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { IoCloseSharp } from 'react-icons/io5';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { addDocumentFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';
import { moduleOption } from '../../Utils/dataUtils';

const ConfigMenuCreate = ({
  isOpen,
  onClose,
  editData,
  resSave,
}) => {
  const toast = useToast();
  const globalState = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    video: '',
    title: '',
    module: 'management',
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        description: editData.description || '',
        video: editData.video || '',
        title: editData.title || '',
        module: editData.module || 'landingPage',
      });
    } else {
      setFormData({
        description: '',
        video: '',
        title: '',
        module: 'management',
      });
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onSubmit = async () => {
    try {
      if (
        formData.title === '' ||
                formData.video === '' ||
                formData.module === '' ||
                formData.description === ''
      ) {
        return toast({
          title: 'Input Validation',
          description: 'Please fill in all fields',
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }

      setIsLoading(true);
      globalState.setIsLoading(true);

      if (editData) {
        await updateDocumentFirebase('config_menu', editData.id, formData);
      } else {
        await addDocumentFirebase(
          'config_menu',
          formData,
          globalState.currentCompany
        );
      }
      globalState.setIsLoading(false);

      setIsLoading(false);
      setFormData({
        description: '',
        video: '',
        name: '',
        module: 'management',
      });
      resSave();
      onClose();
    } catch (error) {
      globalState.setIsLoading(false);
      console.error('Error submitting data:', error);
      toast({
        title: 'Admin',
        description: 'An error occurred while saving data',
        status: 'error',
      });
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Menu Decription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          <Box
            p={5}
            shadow={'base'}
          >
            <FormControl mb="4">
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Video Link (Youtube)</FormLabel>
              <Input
                type="text"
                name="video"
                value={formData.video}
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Section</FormLabel>
              <Select
                name="module"
                value={formData.module}
                onChange={handleChange}
                required
              >
                {moduleOption().map((option, index) => (
                  <option key={index} value={option.value}>{option.name}</option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <FormControl mt="5">
            <FormLabel ml={2}>HTML</FormLabel>
            <Textarea
              height={200}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </FormControl>

          {/* Buttons for cancel and submit */}
          <HStack mt="10" mb="5" display="flex" justifyContent="end" spacing={2}>
            <DynamicButton
              size={'sm'}
              action="custom"
              color={'red'}
              icon={IoCloseSharp}
              title={'Cancel'}
              onClick={onClose}
            />
            <DynamicButton
              color={'green'}
              size={'sm'}
              action="custom"
              title={'Submit'}
              onClick={onSubmit}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfigMenuCreate;
