/* eslint-disable no-alert */
import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { deleteDocumentFirebase } from '../../Apis/firebaseApi';
import useUserStore from '../../Hooks/zustand/Store';

export const MediaCard = ({
  dataHasil,
  handleLoadMore,
  handleOpenLink,
  usernameUrl,
}) => {
  const [data, setData] = useState(dataHasil);
  const globalState = useUserStore();
  const [loadingStates, setLoadingStates] = useState([]);
  const toast = useToast();

  useEffect(() => {
    setData(dataHasil);
  }, [dataHasil]);

  const handleDelete = async (id, index) => {
    globalState.setIsLoading(true);
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });

    const confirmDelete = window.confirm(
      'If you delete this Coupon, it cannot be restored. '
    );
    if (confirmDelete) {
      await deleteDocumentFirebase('content_strategies', id);
      toast({
        title: 'Admin',
        description: 'Success delete',
        status: 'success',
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
      globalState.setIsLoading(false);
    }
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <Box>
      <SimpleGrid
        // columns={4}
        columns={{ base: 1, md: 3, lg: 4, xl: 5 }}
        spacing={4}
        p={5}
        my={5}
        borderRadius={'md'}
        boxShadow="xl"
      >
        {data.map((x, i) => (
          <Stack
            key={i}
            p="3"
            borderRadius={'md'}
            shadow={'base'}
            border={'1px'}
            cursor={'pointer'}
            borderColor={'gray.50'}
          >
            <Box
              pos={'relative'}
              h={400}
              bg="blue"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <video
                controls
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              >
                <source src={x?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
            <Stack
              h={150}
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <a href={x?.video} target="_blank" rel="noopener noreferrer">
                <Text fontWeight="bold">Video Description</Text>
                <Text>{x?.description}</Text>
              </a>
            </Stack>
            <Flex
              justifyContent="flex-end"
              alignItems="flex-end"
              bottom={2}
              right={2}
            >
              <DynamicButton
                action={'delete'}
                isLoading={loadingStates[i]}
                size="sm"
                variant={'solid'}
                onClick={() => handleDelete(x.id, i)}
              />
            </Flex>
          </Stack>
        ))}
      </SimpleGrid>
      <Center my={5}>
        <Button colorScheme="blue" onClick={handleLoadMore}>
          Load More
        </Button>
      </Center>
    </Box>
  );
};

MediaCard.propTypes = {
  dataHasil: PropTypes.any.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleOpenLink: PropTypes.func.isRequired,
  usernameUrl: PropTypes.func.isRequired,
};
