/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  HStack,
  Skeleton,
} from '@chakra-ui/react';

const ImageModal = ({ isOpen, onClose, image }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Template Landing Page</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb="5" display="flex" justifyContent="center" spacing={2}>
            <Image
              src={image}
              objectFit="cover"
              width={'100%'}
              alt="Banner"
              fallback={<Skeleton />}
            />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
