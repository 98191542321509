import React from 'react';
import SopKpiIndex from '../Pages/SopKpi/SopKpiIndex';
import KpiTemplate from '../Pages/SopKpi/Kpi/KpiTemplate';
import KpiImport from '../Pages/SopKpi/Kpi/KpiImport';
import SopPage from '../Pages/SopKpi/Sop/SopPage';
import SopTemplateViewPage from '../Pages/SopKpi/Sop/SopTemplateViewPage';
import SopViewPage from '../Pages/SopKpi/Sop/SopViewPage';
import CategoriesPage from '../Pages/SopKpi/Categories/CategoriesPage';

const SopKpiRouter = [
  {
    path: '/sopkpi',
    element: <SopKpiIndex />,
  },
  {
    path: '/sopkpi/kpi-template',
    element: <KpiTemplate />,
  },
  {
    path: '/sopkpi/kpi-template/import',
    element: <KpiImport />,
  },
 
  {
    path: '/sopkpi/sop-template',
    element: <SopPage />,
  },
  {
    path: '/sop/templateview',
    element: <SopTemplateViewPage />,
  },
  {
    path: '/sop/list/:id',
    element: <SopViewPage />,
  },
  {
    path: '/sopkpi/categories-template',
    element: <CategoriesPage />,
  },
];

export default SopKpiRouter;
