/* eslint-disable react/prop-types */
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  HStack,
} from '@chakra-ui/react';
import DynamicButton from '../Buttons/DynamicButton';

const AlertConfirmation = ({
  isLoading,
  isOpen,
  onClose,
  onAction,
  title,
  description,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>
          <AlertDialogFooter>
            <HStack display="flex" justifyContent="end" spacing={2}>
              <DynamicButton
                size={'sm'}
                action="custom"
                title={'Cancel'}
                color={'red'}
                onClick={onClose}
              />
              <DynamicButton
                size={'sm'}
                action="custom"
                title={'Confirm'}
                color={'green'}
                isLoading={isLoading}
                onClick={onAction}
              />
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertConfirmation;
