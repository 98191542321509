import React from 'react'
import MarketingIndex from '../Pages/Marketing/MarketingIndex';
import AdsRequestPage from '../Pages/Marketing/AdsRequestPage';
import AdsRequestView from '../Pages/Marketing/AdsRequestView';
import CreatomatePage from '../Pages/TemplateCreatomate/CreatomatePage';
import TemplateLpPage from '../Pages/Marketing/TemplateLp/TemplateLpPage';
import ContentPage from '../Pages/Marketing/ContentPage';
import DomainsPage from '../Pages/Domains/DomainsPage';
import DomainsNewPage from '../Pages/Domains/DomainsNewPage';

const MarketingRouter = [
  {
    path: '/marketing',
    element: <MarketingIndex />,
  },
  {
    path: '/marketing/ads-request',
    element: <AdsRequestPage />,
  },
  {
    path: '/marketing/template-lp',
    element: <TemplateLpPage />,
  },
  {
    path: '/marketing/ads-request/:id',
    element: <AdsRequestView />,
  },
  {
    path: '/marketing/template-creatomate',
    element: <CreatomatePage />,
  },
  {
    path: '/marketing/Domain',
    element: <DomainsPage />,
  },
  {
    path: '/marketing/Domain/new',
    element: <DomainsNewPage />,
  },
  {
    path: '/marketing/content',
    element: <ContentPage />,
  },

];

export default MarketingRouter;
