import React from 'react'
import CompanyPage from '../Pages/Company/CompanyPage'
import CompanyIndex from '../Pages/Company/CompanyIndex';
import UsersPage from '../Pages/Users/UsersPage';
import CompanyView from '../Pages/Company/CompanyView';
import TemplatePage from '../Pages/TemplateCrud/TemplatePage';
import BillingPageV2 from '../Pages/Billing/BillingPageV2';
import BillingView from '../Pages/Billing/BillingView';
import IntegrationPage from '../Pages/General/Integration/IntegrationPage';
import ProjectViewsV2 from '../Pages/Projects/ProjectViewsV2';
import ConfigMenuPage from '../Pages/ConfigMenu/ConfigMenuPage';
import UserImport from '../Pages/Users/UserImport';
import LandingPageHome from '../Pages/LandingPage/LandingPageHome';
import LandingPageQA from '../Pages/LandingPage/LandingPageQA';
import LandingPageLink from '../Pages/LandingPage/LandingPageLink';
import LandingPageTestimoni from '../Pages/LandingPage/LandingPageTestimoni';
import LandingPagePricing from '../Pages/LandingPage/LandingPagePricing';
import AppsPage from '../Pages/Apps/AppsPage';
import AppsCreatePage from '../Pages/Apps/AppsCreatePage';
import OrdersPage from '../Pages/Orders/OrdersPage';
import UserLpPage from '../Pages/Users/UserLp/UserLpPage';
import LandingPageIntegration from '../Pages/LandingPage/LandingPageIntegration';
import LandingPagePricingV2 from '../Pages/LandingPage/LandingPagePricingV2';

const CompanyRouter = [
  {
    path: '/companies/index',
    element: <CompanyIndex />,
  },
  {
    path: '/companies/page',
    element: <CompanyPage />,
  },
  {
    path: '/companies/:companyId',
    element: <CompanyView />,
  },
  {
    path: '/companies/user',
    element: <UsersPage />,
  },
  {
    path: '/companies/billing',
    element: <BillingPageV2 />,
  },
  {
    path: '/companies/billing/:id',
    element: <BillingView />,
  },
  {
    path: '/companies/template-crud',
    element: <TemplatePage />,
  },
  {
    path: '/companies/integration',
    element: <IntegrationPage />,
  },
  {
    path: '/companies/:companyId/projects/:projectId',
    element: <ProjectViewsV2 />,
  },
  {
    path: '/companies/config-menu',
    element: <ConfigMenuPage />,
  },
  {
    path: '/companies/user/import',
    element: <UserImport />,
  },
  {
    path: '/general/home',
    element: <LandingPageHome />,
  },
  {
    path: '/general/pricingV2',
    element: <LandingPagePricingV2 />,
  },
  {
    path: '/general/integration',
    element: <LandingPageIntegration />,
  },
  {
    path: '/general/question-and-answer',
    element: <LandingPageQA />,
  },
  {
    path: '/general/link-embed',
    element: <LandingPageLink />,
  },
  {
    path: '/general/testimoni',
    element: <LandingPageTestimoni />,
  },
  {
    path: '/general/pricing',
    element: <LandingPagePricing />,
  },
  {
    path: '/general/apps',
    element: <AppsPage />,
  },
  {
    path: '/general/apps/create',
    element: <AppsCreatePage />,
  },
  {
    path: '/general/apps/:id',
    element: <AppsCreatePage />,
  },
  {
    path: '/general/user-lp',
    element: <UserLpPage />,
  },
  // {
  //   path: '/general/orders',
  //   element: <OrdersPage />,
  // },

];

export default CompanyRouter;
