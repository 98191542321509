/* eslint-disable react/prop-types */
import React from 'react';
import { Divider, Box, Text, Grid, ListItem } from '@chakra-ui/react';

function GridBox({ title, value }) {
  return (
    <>
      <Box>
        <Text as="b" ml={4}>{title}</Text>
      </Box>

      <Box>
        <Text textAlign="end">{value}</Text>
      </Box>
    </>
  );
}

export default GridBox;
