/* eslint-disable no-undef */
import {
  Box, Heading, HStack, Spacer, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Text, Alert, AlertIcon, AlertTitle, AlertDescription,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/zustand/Store';
import * as XLSX from 'xlsx'
import CardFile from '../../Components/CardFile';
import { checkEmailExistence, getCollectionFirebase } from '../../Apis/firebaseApi';
import axios from 'axios';

function UserImport() {
  const globalState = useUserStore();
  const [file, setFile] = useState(null)
  const [projectSelect, setProjectSelect] = useState('');
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [companySelect, setCompanySelect] = useState('');
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_FUNCTIONS_HOST + '/user-createBulkUser';
  const authToken = process.env.REACT_APP_FUNCTIONS_KEY;
  const toast = useToast()
  const [showAlert, setShowAlert] = useState(false);
  const [alreadyEmailExist, setAlreadyEmailExist] = useState([]);

  const onSelectFile = (arg) => {
    setFile(arg)
  }

  const handleCompanyChange = async (companyId) => {
    setCompanySelect(companyId);
    setProjectSelect(''); // Reset project when company changes
    if (companyId) {
      try {
        const conditions = [{ field: 'companyId', operator: '==', value: companyId }];
        const sortBy = { field: 'name', direction: 'asc' };
        const getProjects = await getCollectionFirebase('projects', conditions, sortBy);
        setProjects(getProjects);
      } catch (error) {
        console.log(`Error fetching projects: ${error}`);
      }
    } else {
      setProjects([]);
    }
  };

  const onImport = () => {
    setShowAlert(false)
    if (!file) {
      return toast({
        title: 'Error',
        description: 'No file selected',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  
    // Ensure the file type is xlsx
    if (file.name.split('.').pop() !== 'xlsx') {
      return toast({
        title: 'Error',
        description: 'Invalid file type. Please select an XLSX file.',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
  
      const existingEmails = [];
  
      for (const user of jsonData) {
        const email = user.email;
        const emailCheckResult = await checkEmailExistence(email);
        if (emailCheckResult.exists) {
          existingEmails.push(email);
        } else {
          console.log('Email is available:', email);
          setData((prevData) => [...prevData, user]);
        }
      }
  
      if (existingEmails.length > 0) {
        setShowAlert(true)
        setAlreadyEmailExist(existingEmails);
        return;
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  

  const getCompany = async (search) => {
    try {
      const conditions = [];
      const sortBy = { field: 'name', direction: 'asc' };
      const getCompany = await getCollectionFirebase('companies', conditions, sortBy);
      setCompanies(getCompany)
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const saveDocument = async () => {
    try {
      if (!data || data.length === 0) {
        return toast({
          title: 'Error',
          description: 'No Data Available',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      if (!companySelect) {
        return toast({
          title: 'Error',
          description: 'Company input cannot be empty.',
          position: 'top-right',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const newData = {
        companyId: companySelect,
        projectId: projectSelect,
        data: data.map((item) => ({ ...item }))
      };
      globalState.setIsLoading(true);
      await axios.post(apiUrl, newData, {
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json'
        }
      });
      globalState.setIsLoading(false);

      toast({
        title: 'Data saved to Firebase',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      window.location.href = '/companies/user';
    } catch (error) {
      console.error('Error saving data to Firebase:', error);
      toast({
        title: 'Error saving data to Firebase',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const downloadTemplate = async () => {
    try {
      const spreadsheetUrl = 'https://docs.google.com/spreadsheets/d/1SHnRBMYr8fUja8LIHvJtZ1JyXzcgYh7GFVHtIJ6ib5w/export?format=xlsx';
      const response = await axios.get(spreadsheetUrl, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading template:', error);
      // Handle error
    }
  };

  useEffect(() => {
    getCompany();
  }, [])
  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Create Bulk User</Heading>
          <Spacer />
        </HStack>

        <Box p="5" borderRadius="md" shadow="base" mt={5} textAlign="center">

          {showAlert && (

            <Alert
            // display={showAlert ? "flex" : "none"}
              status="error"
              h={['30px', '30px', '40px']}
              overflowX="auto"
            >
              <AlertIcon />
              <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
              Users Exist, 
              </AlertTitle>
              <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
                <Text>{alreadyEmailExist.join(', ')}</Text>
              </AlertDescription>
            </Alert>
          )}

          <CardFile onChange={onSelectFile} />

          <HStack mb={5} mt={5} justifyContent="center">
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Load Document'}
              onClick={() => onImport()}
            />
            <DynamicButton
              mt={5}
              mx={3}
              size={'sm'}
              color={'blue'}
              action="custom"
              title={'Download Template'}
              onClick={downloadTemplate}
            />


          </HStack>

          <Stack direction="row" alignItems="center" spacing={3} justifyContent="end" my={3}>
            <select
              onChange={(e) => handleCompanyChange(e.target.value)}
              value={companySelect}
              style={{ width: '25%', padding: '0.5rem' }}
            >
              <option value="" disabled={!companySelect}>
                {companySelect ? companySelect : 'Select Company'}
              </option>
              {companies.map((company, index) => (
                <option key={index} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => setProjectSelect(e.target.value)}
              value={projectSelect}
              style={{ width: '25%', padding: '0.5rem' }}
              disabled={!companySelect} // Disable if no company is selected
            >
              <option value="" disabled={!projectSelect}>
                {projectSelect ? projectSelect : 'Select Project'}
              </option>
              {projects.map((project, index) => (
                <option key={index} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>

            <DynamicButton
              size={'sm'}
              action="create"
              title={'Save'}
              onClick={() => saveDocument()}
            />
          </Stack>

          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Join Date</Th>
                  <Th>Date Of Birth</Th>
                  <Th>Religion</Th>
                  <Th>Phone</Th>
                  <Th>ID Number (KTP)</Th>
                  <Th>Tax Number</Th>
                  <Th>Martial</Th>
                  <Th>Dependants</Th>
                  <Th>Status</Th>
                  <Th>HR Permission</Th>
                  <Th>Bank Account</Th>
                  <Th>Bank Account Name</Th>
                  <Th>Bank Account Number</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((employee, index) => (
                  <Tr key={index}>
                    <Td>{employee.name}</Td>
                    <Td>{employee.email}</Td>
                    <Td>{new Date(employee.joinDate).toLocaleDateString()}</Td>
                    <Td>{new Date(employee.dob).toLocaleDateString()}</Td>
                    <Td>{employee.religion}</Td>
                    <Td>{employee.phone}</Td>
                    <Td>{employee.idNumber}</Td>
                    <Td>{employee.taxNumber}</Td>
                    <Td>{employee.martial}</Td>
                    <Td>{employee.dependants}</Td>
                    <Td>{employee.status}</Td>
                    <Td>{employee.hrPermission}</Td>
                    <Td>{employee.bankAccount}</Td>
                    <Td>{employee.bankAccountName}</Td>
                    <Td>{employee.bankAccountNumber}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

        </Box>
      </Box>

    </>
  );
}

export default UserImport;
