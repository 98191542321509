import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Flex, FormControl, FormLabel, Heading, HStack, Input, SimpleGrid, Spacer, Stack, Text, Textarea, useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getSingleDocumentFirebase, setDocumentFirebase, updateDocumentFirebase } from '../../Apis/firebaseApi';
import { uploadImage } from '../../Apis/firebaseFunctions';
import useUserStore from '../../Hooks/zustand/Store';
import ImageComponent from '../../Components/Image/ImageComponent';

function LandingPageTestimoni() {
  const toast = useToast();
  const globalState = useUserStore();
  const [formData, setFormData] = useState({});

  const handleChange = (section, itemIndex, field, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[section][itemIndex][field] = value;
      return updatedFormData;
    });
  };

  const removeTestimonial = (section, itemIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[section].splice(itemIndex, 1);
      return updatedFormData;
    });
  };

  const handleDeleteImage = (section, itemIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[section][itemIndex].img = '';
      return updatedFormData;
    });
  };

  const submitImage = async (section, itemIndex, file) => {
    try {
      globalState.setIsLoading(true);
      const res = (await uploadImage(file[0])).data;
      if (res.status) {
        setFormData((prevFormData) => {
          const updatedFormData = { ...prevFormData };
          updatedFormData[section][itemIndex].img = res.data;
          return updatedFormData;
        });
        globalState.setIsLoading(false);
        toast({
          title: 'Upload Image',
          description: 'Image saved',
          status: 'success',
          duration: 2000,
        });
      } else {
        globalState.setIsLoading(false);
        toast({
          title: 'Image Error',
          description: res.message,
          status: 'error',
        });
      }
    } catch (error) {
      globalState.setIsLoading(false);
      toast({
        title: 'Image Size Error',
        description: error.message,
        status: 'error',
      });
    }
  };

  const addTestimonial = (section) => {
    setFormData((prevFormData) => {
      const newItem = {
        id: prevFormData[section].length + 1,
        img: '',
        name: '',
        title: '',
        info: '',
      };
      return {
        ...prevFormData,
        [section]: [...prevFormData[section], newItem],
      };
    });
  };

  const handleSave = async () => {
    try {
      globalState.setIsLoading(true);
      const dataSend = {
        data: formData,
      };
      await setDocumentFirebase('landing_page', 'testimonials', dataSend);
      globalState.setIsLoading(false);
      toast({
        title: 'Data Saved',
        description: 'The data has been successfully saved.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving data:', error);
      globalState.setIsLoading(false);
      toast({
        title: 'Error',
        description: 'An error occurred while saving the data.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const res = await getSingleDocumentFirebase('landing_page', 'testimonials')
      setFormData(res.data)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Testimonial Landing Page</Heading>
          <Spacer />
        </HStack>

        <Accordion allowToggle mt={8}>
          {Object.entries(formData).map(([section, testimonials], index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    {section.charAt(0).toUpperCase() + section.slice(1)} Testimonials
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>


              <AccordionPanel pb={4}>
                <Flex justifyContent='flex-end' alignItems='center' mb={3}>
                  <DynamicButton
                    color='green'
                    onClick={() => addTestimonial(section)}
                    size='sm'
                    action='create'
                    title='Add Testimonial'
                  />

                </Flex>
                {testimonials.map((testimonial, itemIndex) => (
                  <Box p='5' borderRadius='md' shadow='base' mt={5} key={itemIndex}>
                    <Flex w='100%' gap={5} direction={['column', 'row', 'row']}>
                      <Box w={['100%', '50%', '50%']} p={5} borderRadius='md' mt={8}>
                        <Flex justifyContent='flex-end' alignItems='center' mb={3}>
                          <DynamicButton
                            size='md'
                            action='delete'
                            title='Testimonial'
                            onClick={() => removeTestimonial(section, itemIndex)}
                          />
                        </Flex>
                        <FormControl mb='4'>
                          <FormLabel>Name :</FormLabel>
                          <Flex>
                            <Input
                              type='text'
                              value={testimonial.name}
                              onChange={(e) => handleChange(section, itemIndex, 'name', e.target.value)}
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb='4'>
                          <FormLabel>Title :</FormLabel>
                          <Flex>
                            <Input
                              type='text'
                              value={testimonial.title}
                              onChange={(e) => handleChange(section, itemIndex, 'title', e.target.value)}
                            />
                          </Flex>
                        </FormControl>
                        <FormControl mb='4'>
                          <FormLabel>Info :</FormLabel>
                          <Flex>
                            <Textarea
                              value={testimonial.info}
                              onChange={(e) => handleChange(section, itemIndex, 'info', e.target.value)}
                              required
                            />
                          </Flex>
                        </FormControl>
                      </Box>

                      <Box p={5} w={['100%', '50%', '50%']}>
                        <Box flex='1'>
                          <Box p={5} borderRadius='md' shadow='base'>
                            <Stack spacing={3} align='center'>
                              <ImageComponent
                                image={testimonial.img} // Assuming testimonial.img is the URL of the testimonial.img
                                name={testimonial.name}
                                width='200px'
                              />
                              {testimonial.img ? (
                                <Button
                                  size='xs'
                                  colorScheme='red'
                                  onClick={() => handleDeleteImage(section, itemIndex)}
                                >
                                  Clear Image
                                </Button>
                              ) : (
                                <Box>
                                  <Input
                                    type='file'
                                    mb={4}
                                    onChange={(e) => submitImage(section, itemIndex, e.target.files)}
                                  />
                                </Box>
                              )}
                            </Stack>
                          </Box>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <HStack mt='10' mb='5' display='flex' justifyContent='end' spacing={2}>
          <DynamicButton
            color='green'
            size='sm'
            action='custom'
            title='Submit'
            onClick={handleSave}
          />
        </HStack>
      </Box>
    </>
  );
}

export default LandingPageTestimoni;
