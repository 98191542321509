import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  SimpleGrid,
  Spacer,
  useDisclosure,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
// import BackButtons from '../../Components/Buttons/BackButtons';
// import DynamicButton from '../../Components/Buttons/DynamicButton';
// import CardMyKanban from '../../Components/Card/CardMyKanban';
import { useNavigate } from 'react-router-dom';
// import { addDocumentFirebase } from '../../Api/firebaseApi';
// import useUserStore from '../../Hooks/Zustand/Store';
import { useEffect } from 'react';
// import { clientTypessense } from '../../Apis/Typesense';
import { FcTodoList } from 'react-icons/fc';
// import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
// import SopModalTemplate from './Component/SopModalTemplate';
import BackButtons from '../../../Components/Buttons/BackButtons';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import CardMyKanban from '../../../Components/Cards/CardMyKanban';
import { clientTypesense } from '../../../Apis/typeSense';
import { addDocumentFirebase } from '../../../Apis/firebaseApi';
import useUserStore from '../../../Hooks/zustand/Store';
import AlertArrayNull from '../../../Components/AlertDialog/AlertArrayNull';

function SopListPage() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newTitle, setNewTitle] = useState();
  const [data, setData] = useState([]);
  const globalState = useUserStore();
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  const [modalTemplate, setModalTemplate] = useState(false);

  const handleSave = async () => {
    if (!newTitle) return;
    setLoading(true);
    try {
      const res = await addDocumentFirebase(
        'sop',
        {
          title: newTitle,
          projectId: globalState.currentProject,
          isTemplate: true,
        },
        globalState.currentCompany
      );
      navigate('/sop/list/' + res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async (q) => {
    // globalState.setIsLoading(true);
    try {
      const searchParameters = {
        q: q ? q : '*',
        query_by: 'title',
        filter_by: `companyId: ${globalState.currentCompany}`,
        sort_by: '_text_match:desc',
        per_page: q ? 8 : itemsPerPage,
        page: q ? 1 : currentPage,
      };

      const x = await clientTypesense
        .collections('sop')
        .documents()
        .search(searchParameters);

      const newData = x?.hits?.map((y) => {
        return { ...y.document };
      });
      if (q) {
        setData([...newData]);
      } else {
        setData([...data, ...newData]);
      }

      setTotalPages(x.found);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      // globalState.setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [globalState.currentCompany, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleOpenModal = () => {
    setModalTemplate(true);
  };

  const handleCloseModal = () => {
    setModalTemplate(false);
  };

  return (
    <Stack p={[1, 1, 5]}>
      <HStack>
        <BackButtons />
        <Heading size={'md'}> SOP</Heading>
        <Spacer />
        <Input
          placeholder="Search SOP"
          type="text"
          w="-moz-min-content"
          onChange={(e) => getData(e.target.value)}
        />
        <DynamicButton
          title={'Add Sop'}
          action={'create'}
          variant={'solid'}
          onClick={onOpen}
        />

        {/* <DynamicButton
          title={'See Template'}
          action={'read'}
          variant={'solid'}
          onClick={handleOpenModal}
        /> */}
      </HStack>

      {data?.length > 0 ? (
        <SimpleGrid columns="4" mt="2" gap="2">
          {data?.map((x, i) => (
            <CardMyKanban
              key={i}
              Icon={FcTodoList}
              onNavigate={() => navigate(`/sop/list/${x.id}`)}
              title={x.title}
              userArray={x.userArray}
            />
          ))}
        </SimpleGrid>
      ) : (
        <AlertArrayNull titleData={'Sop'} action="Add Sop" />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add SOP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="text"
              placeholder="Title"
              onChange={(e) => setNewTitle(e.target.value)}
            />
            {/* <Lorem count={2} /> */}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              isLoading={loading}
              mr={3}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <SopModalTemplate
        isOpen={modalTemplate}
        onClose={handleCloseModal}
        globalStateProps={globalState}
      /> */}
    </Stack>
  );
}

export default SopListPage;
