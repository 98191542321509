import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Stack,
  FormControl,
  FormLabel,
  useToast,
  Switch,
  Skeleton,
  Image,
  Flex,
  Badge,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { getCollectionFirebase } from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import { Link } from 'react-router-dom';
import { formatDateFirestore } from '../../Utils/numberUtil';
import { TbReload } from 'react-icons/tb';
import DatePicker from '../../Components/DatePicker/DatePicker';
import moment from 'moment';
import useUserStore from '../../Hooks/zustand/Store';

function BannerManagementPage() {
  const globalState = useUserStore();
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const toast = useToast();
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [companySelect, setCompanySelect] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dates = useRef({
    startDate: moment().subtract(30, 'days').unix(),
    endDate: moment().unix(),
  });
  const { startDate, endDate } = dates.current;

  const onDateChange = (data) => {
    dates.current = {
      startDate: moment(data.startDate).unix(),
      endDate: moment(data.endDate).unix(),
    };
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Mengembalikan string kosong jika tanggal tidak ada
    }

    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };

    const formattedDate = new Date(dateString).toLocaleString('id-ID', options);

    return formattedDate;
  };

  const handleSearch = async () => {
    try {
      // console.log(dates, "ssss")
      // return
      const startIndex = (currentPage - 1) * itemsPerPage;
      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;
      const conditions =
        companySelect !== ''
          ? [{ field: 'companyId', operator: '==', value: companySelect }]
          : [];

      const res = await getCollectionFirebase(
        'marketing_meta',
        conditions,
        sortBy,
        limitValue
      );
      setData(res);

      const totalContacts = await getCollectionFirebase(
        'marketing_meta',
        conditions
      );
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getCompany = async (search) => {
    try {
      const conditions = [];
      const sortBy = { field: 'name', direction: 'asc' };
      const getCompany = await getCollectionFirebase(
        'companies',
        conditions,
        sortBy
      );
      setCompanies(getCompany);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const handleReloadFilter = () => {
    setCompanySelect('');
    handleSearch();
  };

  useEffect(() => {
    handleSearch();
    getCompany();
  }, [
    currentPage,
    itemsPerPage,
    getCollectionFirebase,
    globalState.currentCompany,
  ]);

  return (
    <>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Campaign</Heading>
          <Spacer />
        </HStack>

        <Box p={2} my={3} borderRadius={'md'} shadow={'base'}>
          <HStack>
            <Spacer />

            {/* <Button
              onClick={onOpen}
              bgColor={'transparent'}
              // shadow="md"
              variant="outline"
              borderColor="blue.600"
              color="blue.600"
            >
              <HStack>
                <FaRegCalendar />
                <Text>Filter date</Text>
              </HStack>
            </Button> */}
            <Text>Select Company : </Text>
            <select
              //  borderRadius={'md'} shadow={'base'}
              onChange={(e) => setCompanySelect(e.target.value)}
              value={companySelect}
              style={{ width: '25%', padding: '0.5rem' }}
            >
              <option value="">All Company</option>
              {companies.map((x, index) => (
                <option key={index} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>

            <DynamicButton
              title={'Filter'}
              action="custom"
              color={'blue'}
              onClick={() => {
                handleSearch();
              }}
            />
            <DynamicButton
              color={'blue'}
              action={'custom'}
              icon={TbReload}
              onClick={handleReloadFilter}
            />
          </HStack>
        </Box>

        <Box p="5" borderRadius="md" shadow="base" mt={5}>
          <TableContainer
            border={'1px'}
            borderRadius={'sm'}
            borderColor={'gray.100'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Campaign Name</Th>
                  <Th>Type Ads</Th>
                  <Th>Campaign Status</Th>
                  <Th>Status</Th>
                  <Th>Start Campaign</Th>
                  <Th>Created At</Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i}>
                    <Td>
                      {x?.campaignName}
                      <Text fontSize="2xs">ID: {x?.id}</Text>
                    </Td>
                    <Td>{x?.category}</Td>
                    <Td>
                      <Badge
                        colorScheme={
                          x?.isDraft !== undefined && !x?.isDraft
                            ? 'green'
                            : 'red'
                        }
                      >
                        {x?.isDraft !== undefined && !x?.isDraft
                          ? 'Publish'
                          : 'Draft'}
                      </Badge>
                    </Td>
                    <Td>{x?.status}</Td>
                    <Td>{formatDate(x?.budget?.durationStart)}</Td>
                    <Td>{formatDateFirestore(x.createdAt)}</Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <Link to={`/marketing/ads-request/${x.id}`}>
                          <DynamicButton
                            size="sm"
                            action="read"
                            // onClick={() => companyList(x)}
                          />
                        </Link>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack alignItems={'center'} justifyContent="center" mt={3}>
            {currentPage < totalPages && (
              <Button
                colorScheme={'green'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Stack>
        </Box>

        <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <DatePicker onDateChange={onDateChange} />
              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button colorScheme="red" mr={3} onClick={onClose}>
                  Close
                </Button>
                <DynamicButton
                  title={'Filter'}
                  action="read"
                  variant={'solid'}
                  onClick={() => handleSearch()}
                />
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

export default BannerManagementPage;
