import {
  Box, Button, Heading, HStack, Spacer, Table, Thead, Tr, Th, Tbody, Td, TableContainer, Stack, useToast, Text, useDisclosure, Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  getCollectionFirebase,
} from '../../Apis/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/zustand/Store';
import moment from 'moment'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { TbReload } from 'react-icons/tb';
import ModalDetailOrder from '../Orders/ComponentOrders/ModalDetailOrder';
import { formatFrice } from '../../Utils/Helper';

function BillingPageV2() {
  const globalState = useUserStore();
  const [data, setData] = useState([]);
  const toast = useToast();
  const [searchValue, setSearchValue] = useState('');
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [companySelect, setCompanySelect] = useState('');
  const [statusPaid, setStatusPaid] = useState('');
  const orderDetail = useDisclosure();
  const [detailOrder, setDetailOrder] = useState({});
  const [sort, setSort] = useState({ sort: 'avgScore', desc: true });

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSort = (sort, desc, type) => {
    setSort({ sort: sort, desc: desc, type: type });
    getData(desc, null, type)
  };

  const getData = async (sort) => {
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const conditions = [];
      if (searchValue !== '') {
        conditions.push({ field: 'name', operator: '==', value: searchValue });
      }
      if (companySelect !== '') {
        conditions.push({ field: 'companyId', operator: '==', value: companySelect });
      }
      if (statusPaid !== '') {
        conditions.push({ field: 'transaction_status', operator: '==', value: statusPaid });
      }

      const sortBy = sort ? { field: 'createdAt', direction: 'asc' } : { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      const res = await getCollectionFirebase(
        'billings',
        conditions,
        sortBy,
        limitValue
      );
      setData(res || []);

      const totalContacts = await getCollectionFirebase(
        'billings',
        conditions,
      );
      setTotalPages(Math.ceil(totalContacts.length / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const handleReloadFilter = () => {
    setCompanySelect('')
    setSearchValue('')
    setStatusPaid('')
    getData()
  }

  const handleOpenModalDetail = (detailBilling) => {
    setDetailOrder(detailBilling);
    orderDetail.onOpen();
  };


  useEffect(() => {
    getData();
  }, [currentPage, itemsPerPage, globalState.currentCompany,]);

  return (
    <Stack p={[1, 1, 5]}>
      <Box>
        <HStack>
          <BackButtons />
          <Heading size={'md'}>Billing Page</Heading>
          <Spacer />
        </HStack>


        <Box p={[1, 1, 5]} borderRadius="md" shadow="base" mt={5}>
          <HStack>
            <Spacer />
            <HStack>

              <Select
                onChange={(e) => setStatusPaid(e.target.value)}
                value={statusPaid}

              >
                <option value="">All</option>
                <option value="settlement">Paid</option>
                <option value="pending">Pending</option>
              </Select>

              <DynamicButton
                title={'Filter'}
                action="custom"
                color={'blue'}
                onClick={() => getData('', 'filter')}
                variant={'solid'}
              />
              <DynamicButton color={'blue'} action={'custom'} icon={TbReload} onClick={handleReloadFilter} variant={'solid'} />
            </HStack>
          </HStack>

          <TableContainer
            mt={3}
          >
            <Table variant="striped" fontSize={'sm'}>
              <Thead>
                <Tr>
                  <Th>Product </Th>
                  <Th>Email </Th>
                  <Th>Transaction</Th>
                  <Th>Method</Th>
                  <Th cursor="pointer"
                    onClick={() => {
                      handleSort('avgScore', !sort.desc, 'createdAt');
                    }}
                  >Date {' '}
                    {sort.sort === 'avgScore' ? (
                      sort?.desc ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )
                    ) : (
                      <></>
                    )}</Th>
                  <Th textAlign="center">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((x, i) => (
                  <Tr key={i} >
                    <Td>
                      <Stack >
                        <Text fontWeight={500} fontSize={'sm'} textTransform={'capitalize'}>{x?.name}</Text>
                        <Text fontSize='3xs'>ID: {x?.appId}</Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack>
                        <Text textTransform={'capitalize'} fontWeight={500}>{x?.username}</Text>
                        <Text fontSize='xs'>Email: {x?.contact_information?.email}</Text>
                        <Text fontSize='xs'>Phone: {x?.contact_information?.phone_number || '-'}</Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack>
                        <Text fontWeight={500}>IDR{formatFrice(parseFloat(x?.gross_amount || 0))}</Text>
                        <Text fontWeight={500} textTransform={'capitalize'} color={x?.paymentStatus === 'pending' ? 'yellow' : 'green'}>{x?.transaction_status}</Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack>
                        <Text fontWeight={500} textTransform={'capitalize'}>{x?.type}</Text>
                        <Text fontWeight={500} textTransform={'capitalize'}>{x?.bank}</Text>
                        <Text fontWeight={500} textTransform={'capitalize'}>{x?.payment_type}</Text>
                      </Stack>
                    </Td>
                    <Td>
                      <Stack>
                        <Text fontWeight={500}>Last Updated : {moment(x?.lastUpdated?.seconds * 1000).format('DD-MM-YYYY HH:mm')}</Text>
                        <Text fontSize={'xs'} fontWeight={500} color={'red'}>Expired : {moment(x?.expiredApp?.seconds * 1000).format('DD-MM-YYYY HH:mm')}</Text>
                        <Text fontSize={'xs'} fontWeight={500}>Duration : {x?.duration || 0}</Text>

                      </Stack>
                    </Td>
                    <Td>
                      <HStack spacing={2} justifyContent="center">
                        <DynamicButton
                          size="sm"
                          action="read"
                          variant={'solid'}
                          onClick={() => handleOpenModalDetail(x)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Stack alignItems={'center'} justifyContent="center" mt={3}>
            {currentPage < totalPages && (
              <Button
                colorScheme={'green'}
                fontSize="sm"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Stack>
          {detailOrder !== null && (
            <ModalDetailOrder
              isOpen={orderDetail.isOpen}
              onClose={orderDetail.onClose}
              detailOrder={detailOrder}
            />
          )}
         

        </Box>
      </Box>
    </Stack>
  );
}

export default BillingPageV2;
