import React from 'react'
import BusinessIndexPage from '../Pages/Business/BusinessIndexPage';
import DashboardPage from '../Pages/Business/Dashboard/DashboardPage';
import CouponPageV2 from '../Pages/Coupon/CouponPageV2';


const BusinessRouter = [
  {
    path: '/business',
    element: <BusinessIndexPage />,
  },
  {
    path: '/business/coupon',
    element: <CouponPageV2 />,
  },
  {
    path: '/business/dashboard',
    element: <DashboardPage />,
  }

];

export default BusinessRouter;
